/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

// think twice before changing it and pushing to repo....
const facebook = {
  appId: "1029336064093748",
  cookie: true,
  xfbml: true,
  version: "v2.11",
  scope: "email,public_profile"
};

export const facebookConfig = facebook;

const google = {
  client_id:
    "157036482814-3nufi1n9ekav7i95sbfsjditaivqmcbh.apps.googleusercontent.com",
  scope: "https://www.googleapis.com/auth/plus.login"
};

export const googleConfig = google;
