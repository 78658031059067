/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2020.
 */

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { formatError } from "./../../tools";

import emailValidator from "email-validator";
import PropTypes from "prop-types";
import Indicator from "./../../components/Indicator";
import { withRouter } from "react-router-dom";
import { newsletterSignUp } from "../../actions/newsletter";

const LeadSignup = (props) => {
  const [newsletterState, setNewsletterState] = useState({
    nameError: null,
    emailError: null,
  });

  const emailRef = props.signupEmailRef;//useRef("");
  const nameRef = useRef("");
  const phoneRef = useRef("");

  /**
   *
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const name = nameRef.current.value;
    const phone = phoneRef.current.value;

    let emailError = null;
    let nameError = null;

    if (!email || !emailValidator.validate(email)) {
      emailError = "Please provide your email address.";
    }

    if (!name || name.length < 2) {
      nameError = "What is your name?";
    }

    setNewsletterState({ emailError, nameError });

    if (!emailError && !nameError) {
      const data = {
        email,
        name,
        phone,
        tags: props.mailchimpTags,
      };

      await props.newsletterSignUp(data);

      window.ga(...props.GAParams);
    }
  };

  /**
   *
   * @param e
   */
  const onEmailBlur = (e) => {
    if (nameRef.current.value == "") {
      if (e.target.value.length) {
        const emailParts = e.target.value.split("@");
        const emailUserName = emailParts[0];

        const emailUserNameParts = emailUserName.split(/[\._-]/);

        nameRef.current.value = emailUserNameParts.join(" ");
      }
    }
  };

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const newsletter = props.newsletter;
  const formDisabled = newsletter.signUp;

  const submitButton = formDisabled ? (
    <div className="contact-submit">
      <Indicator size={32} />
    </div>
  ) : (
    <input
      disabled={formDisabled}
      onClick={onSubmit}
      aria-label="Download e-guide now"
      type="submit"
      value="Download Now"
      className=""
    />
  );

  let submitContent = submitButton;

  if (newsletter.signUpSuccess) {
    submitContent = (
      <div className="mail-sent-ok" role="alert">
        {props.submitSuccessMessage + emailRef.current.value + "."}
      </div>
    );
  } else if (newsletter.signUpError) {
    submitContent = (
      <div>
        {submitButton}
        <div className="email-sent-errors" role="alert">
          {formatError(
            newsletter.signUpError,
            "We could not send you the e-guide, please try again."
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="excerpt">
      <div
        role="form"
        className="wpcf7"
        id="wpcf7-f255-o1"
        lang="en-US"
        dir="ltr"
      >
        <div className="screen-reader-response" />
        <form
          action="/"
          method="post"
          className="wpcf7-form"
          noValidate="novalidate"
        >
          <p>
            <br />
            <span className="wpcf7-form-control-wrap your-email">
              <input
                ref={emailRef}
                type="email"
                name="contact-email"
                placeholder="Your Email (required)"
                id="contactEmail"
                disabled={formDisabled}
                aria-label="contact form, email address"
                size="40"
                className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                aria-required="true"
                aria-invalid="false"
                required
                onBlur={onEmailBlur}
              />
              {newsletterState.emailError ? (
                <span role="alert" className="not-valid-tip">
                  Please fill in the required field.
                </span>
              ) : null}
            </span>
            <br />
          </p>

          <p>
            <br />
            <span className="wpcf7-form-control-wrap your-name">
              <input
                ref={nameRef}
                type="text"
                name="contact-name"
                id="contactName"
                placeholder="Your Name (required)"
                disabled={formDisabled}
                aria-label="contact form, name"
                size="40"
                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required text-capitalize"
                aria-required="true"
                aria-invalid="false"
                required
              />
              {newsletterState.nameError ? (
                <span role="alert" className="not-valid-tip">
                  Please fill in the required field.
                </span>
              ) : null}
            </span>
          </p>
          <p>
            <br />
            <span className="wpcf7-form-control-wrap your-name">
              <input
                ref={phoneRef}
                type="text"
                name="contact-phone"
                id="contactPhone"
                placeholder="Phone number"
                disabled={formDisabled}
                aria-label="contact form, phone number"
                size="40"
                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required text-capitalize"
                aria-required="false"
                aria-invalid="false"
                required
              />
            </span>
          </p>

          {submitContent}

          <div className="wpcf7-response-output wpcf7-display-none" />
        </form>
      </div>
    </div>
  );
};

LeadSignup.propTypes = {
  mailchimpTags: PropTypes.array.isRequired,
  GAParams: PropTypes.array.isRequired,
  submitSuccessMessage: PropTypes.string.isRequired,
  submitErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  newsletter: state.newsletter,
});

const mapDispatchToProps = (dispatch) => ({
  newsletterSignUp: async (data) => await dispatch(newsletterSignUp(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeadSignup));
