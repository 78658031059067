const initialState = {
  model: null,
  edit: false,
  editSuccess: false,
  editError: null,

  imageUpload: false,
  imageUploadSuccess: false,
  imageUploadError: null,

  changeBody: null
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "POST_FETCH":
      return {
        ...initialState
      };

    case "NEW_POST_SUBMIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null
      };

    case "NEW_POST_SUBMIT_FULFILLED":
      return {
        model: action.payload,
        edit: false,
        editSuccess: true
      };

    case "NEW_POST_SUBMIT_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload
      };

    case "NEW_POST_IMAGE_UPLOAD":
      return {
        ...state,
        imageUpload: true,
        imageUploadSuccess: false,
        imageUploadError: null
      };

    case "NEW_POST_IMAGE_UPLOAD_FULFILLED":
      return {
        ...state,
        imageUpload: false,
        imageUploadSuccess: true,
        changeBody: {
          text:
            action.changeData.front +
            "![" +
            action.payload.filename +
            "](" +
            action.payload.thumb_md_path +
            ")" +
            action.changeData.back,
          cursorPosition:
            action.changeData.cursorPosition +
            action.payload.filename.length +
            5 +
            action.payload.thumb_md_path.length
        }
      };

    case "NEW_POST_IMAGE_UPLOAD_REJECTED":
      return {
        ...state,
        imageUpload: false,
        imageUploadError: action.payload
      };

    default:
      return state;
  }

}
