/**
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {clearStructuredHead, jsonToHead} from "./Schema";
import {getOrigin} from "../SeoBlock";

/**
 *
 * @param org
 * @param socials
 * @returns {{legalName: string, foundingDate: string, address: {'@type': string}, contactPoint: [{'@type': string}], '@type': string, name: *, logo: {'@type': string, url: string}, alternateName: *, '@context': string, url: string, sameAs: *}}
 */
export const createOrganizationSchema = (org, socials) => {

  return {
    "@context": "http://schema.org",
    "@type": "Organization",
    legalName: org.fullName,
    alternateName: org.name,
    name: org.name,
    url: "https://www.thebeaverhead.com",
    logo: {
      "@type": "ImageObject",
      url: getOrigin() + org.logoUrl,
    },
    address: {
      "@type": "PostalAddress",
      ...org.address,
    },
    contactPoint: [
      {
        "@type": "ContactPoint",
        ...org.contactPoint,
      },
    ],
    sameAs: socials.map((i) => i.url),
    foundingDate: "2014-04-08",
  };
};


const Organization = (props) => {
  useEffect(() => {

    if (props.clear) {
      clearStructuredHead();
    }

    jsonToHead(createOrganizationSchema(props.org, props.socials));
  }, []);

  return null;
};


Organization.propTypes = {
  org: PropTypes.object.isRequired,
  socials: PropTypes.array.isRequired,
};


export default Organization;