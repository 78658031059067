/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/11/2020.
 */

import React, {useState} from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";

const HowDoesItWork = (props) => {
  const [state, setState] = useState({ currentItemIndex: 0 });

  const bodyContent = props.body ? (
    <div className="container list-grid">
      <div className="grid">
        {props.body.map((i, idx) => {
          return (
            <React.Fragment key={"sb-" + props.id + "-" + idx}>
              <div className={"span-1"}></div>
              <div className="layers-masonry-column span-10 span column ">
                <p dangerouslySetInnerHTML={{ __html: i }} />
              </div>
              <div className={"span-1"}></div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  ) : null;

  const onClickItem = (idx) => () => {
    setState({...state, currentItemIndex: idx});
  }

  const items = props.items.map((i, idx) => {
    return (
      <div
        key={'hdiw-' + idx}
        className={classnames(
          "tbh-timeline--item-container",
          state.currentItemIndex == idx ? "active" : ""
        )}
      >
        <div className={"tbh-timeline--item"}
             onClick={onClickItem(idx)}
             onMouseOver={onClickItem(idx)}
        >
          <div className={"tbh-timeline--item-preview"}>
            <i className={classnames("fa", i.icon)} />
          </div>
        </div>
        <div className={"tbh-timeline--item-description-container"}>
          <h1>{i.title}</h1>
          <p>{i.description}</p>
        </div>
      </div>
    );
  });

  const itemsContent =
    props.items && props.items.length ? (
      <div className="tbh-timeline--items-container">
        <div className="tbh-timeline--items">{items}</div>
      </div>
    ) : null;

  return (
    <div
      className={classnames(
        "widget layers-content-widget content-vertical-massive ",
        { bgdark: props.bgDark }
      )}
    >
      <div className="container clearfix">
        <div className="section-title clearfix medium text-center ">
          <h3
            className="heading"
            dangerouslySetInnerHTML={{ __html: "How we will work with you" }}
          ></h3>
          {/*<div className="excerpt">
            <p>
              <em dangerouslySetInnerHTML={{ __html: props.lead }} />
            </p>
          </div>*/}
        </div>
      </div>

      <div className={"tbh-timeline"}>
        <div className="container clearfix">
          <div>{itemsContent}</div>
        </div>
      </div>
    </div>
  );
};

HowDoesItWork.propTypes = {
  bgDark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  items: PropTypes.array,
  body: PropTypes.array,
  callout: PropTypes.object,
  id: PropTypes.string.isRequired,
};

HowDoesItWork.defaultProps = {
  items: [],
  bgDark: false,
};

export default HowDoesItWork;
