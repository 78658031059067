/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { objectToURLQuery } from "../helpers/tools";

/**
 *
 * @returns {Function}
 */
export const fetchPosts = data => {
  return async dispatch => {


    const parameters = objectToURLQuery(data);

    dispatch({ type: "POSTS_FETCH" });

    await axios
      .get(
        AxiosConfig.getEndpointAddress() +
          "/api/posts/latest.json?" +
          parameters,
        AxiosConfig
      )
      .then(response => {
        dispatch({
          type: "POSTS_FETCH_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "POSTS_FETCH_REJECTED",
          payload: error
        });
      });
  };
};

/**
 *
 * @returns {Function}
 */
export function cleanupPostsCollection() {
  return function(dispatch) {
    dispatch({ type: "POSTS_CLEANUP_COLLECTION" });
  };
}
