/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React, { useEffect, useState } from "react";

import PostContainer from "./PostContainer";
import InfiniteScroll from "react-infinite-scroller";
import Indicator from "./Indicator";
import PropTypes from "prop-types";

const PostsContainer = (props) => {
  const [showMore, setShowMore] = useState(false);
  const postsCollection = props.posts.collection;

  const [imagesLoaded, setImagesLoaded] = useState(0);

  const totalFeaturedImages = props.posts.collection.length
    ? props.posts.collection
        .map((i) => (i.images.length ? 1 : 0))
        .reduce((sum, x) => sum + x)
    : 0;


  /**
   *
   */
  const onClickLoadMore = () => {
    setShowMore(true);
  };

  /**
   *
   */
  const onImageLoad = () => {
    setImagesLoaded(imagesLoaded + 1);

    if (imagesLoaded + 1 == totalFeaturedImages) {
      new window.Isotope("#layers-widget-portfolio-widget-section-portfolio", {
        itemSelector: ".portfolio-list",
      });
    }
  };

  const posts = postsCollection.map((item, index) => {
    return (
      <PostContainer
        key={index}
        post={{ model: item }}
        onPreviewImageLoad={onImageLoad}
      />
    );
  });

  const loaderContainer = props.posts.fetch ? (
    <div className="text-center">
      <p className="last-questions-block__loader">Loading posts...</p>
      <Indicator size={32} />
    </div>
  ) : null;

  const fewPostsWrap =
    postsCollection.length > 0 && postsCollection.length < 5 ? (
      <div className="row container masonry-layout">{posts}</div>
    ) : null;

  const manyPostsWrap =
    postsCollection.length > 0 && postsCollection.length >= 5 ? (
      <InfiniteScroll
        className="row container masonry-layout"
        pageStart={1}
        loader={loaderContainer}
        hasMore={showMore && props.posts.hasMore && !props.posts.fetch}
        loadMore={props.loadMore}
        threshold={1000}
      >
        {posts}
      </InfiniteScroll>
    ) : null;

  const noPostsWrap =
    postsCollection.length === 0 && !props.posts.fetch ? (
      <p className="posts__no-posts-error alert">
        Unfortunately, we couldn't find any posts matching your criteria.
      </p>
    ) : null;

  const postsWrap = (
    <div>
      {fewPostsWrap}
      {manyPostsWrap}
      {noPostsWrap}
    </div>
  );

  const showMoreBtn =
    !showMore &&
    !props.posts.fetch &&
    props.posts.hasMore &&
    postsCollection.length >= 5 ? (
      <div className="text-center">
        <button
          className="btn default-button"
          type="button"
          onClick={onClickLoadMore}
        >
          Load more
        </button>
      </div>
    ) : null;

  return (
    <>
      <section
        className="widget row content-vertical-massive futr-portfolio-widget black-background "
        id="layers-widget-portfolio-widget-section-portfolio"
      >
        <div className="">{postsWrap}</div>
      </section>
      {loaderContainer}
      {showMoreBtn}
    </>
  );
};

PostContainer.propTypes = {
  posts: PropTypes.object,
  loadMore: PropTypes.func,
};

export default PostsContainer;
