/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2020.
 */

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Timestamp from "./../components/Timestamp";
import Indicator from "./../components/Indicator";
import { updateCaretPositionAfterPhotoUpload } from "./../tools";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./../components/CodeBlock";
import LinkBlock from "./../components/LinkBlock";
import ImageBlock from "./../components/ImageBlock";

import { clearErrors } from "../actions/app";
import { deletePost, fetchPost, uploadPostImage } from "../actions/post";
import { deleteComment } from "../actions/comment";
import PageTemplate from "./components/PageTemplate";
import AxiosConfig from "../AxiosConfig";
import NewComment from "./Post/components/NewComment";
import Comment from "./Post/components/Comment";
import NewsletterSignup from "../components/NewsletterSignup";
import SeoBlock from "./components/SeoBlock";
import Article from "./components/Schemas/Article";
import Website from "./components/Schemas/Website";
import { cleanupPostsCollection } from "../actions/posts";
import PostContainer from "../components/PostContainer";
import RestrictedBlock from "./Post/components/RestrictedBlock";
import { history } from "../configureStore";
import PostArticle from "./components/Schemas/PostArticle";
import Gallery from "../components/Gallery";
import moment from "moment";

class PostPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
    };

  }

  /**
   *
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);

    this.props.cleanupPostsCollection();
  }

  /**
   *
   */
  componentDidMount() {
    this.props.fetchPost(this.props.match.params.postSlug);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  /**
   *
   *
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.postSlug != this.props.match.params.postSlug) {
      return this.props.fetchPost(this.props.match.params.postSlug);
    }

    if (this.props.post.changeBody) {
      updateCaretPositionAfterPhotoUpload.call(
        this,
        "#editPostContent",
        this.props.post.changeBody
      );
    }
  }

  /**
   *
   */
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   *
   */
  onLogin = () => {
    history.push("/login");
  };

  /**
   *
   */
  onDeletePost = () => {
    const postState = this.props.post;
    const postModel = this.props.post.model;

    if (!postState.deleting) {
      if (
        window.confirm(
          'Do you really want to delete post "' + postModel.title + '"?'
        )
      ) {
        this.props.deletePost(postModel.slug);

        if (this.props.post.deleteSuccess) {
          this.props.history.push("/");
        }
      }
    }
  };

  /**
   *
   * @param uuid
   */
  onCommentSubmit(uuid) {
    const data = {
      body: document.querySelector("#editCommentText").value,
    };

    this.props.commentActions.updateComment(uuid, data);
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { ORG, SOCIALS } = this.props.staticContent.model;

    const postModel = this.props.post.model;
    const postState = this.props.post;
    const user = this.props.user.model;
    const isOwner =
      user &&
      postModel &&
      (user.role === "admin" || user.slug === postModel.user.slug);
    const canVote =
      user &&
      postModel &&
      user.slug !== postModel.user.slug &&
      !postModel.user_vote &&
      !postState.voteLoading;

    const contentLoaded = postModel && !postState.edit && !postState.fetch;
    const isRestricted =
      contentLoaded && postModel.is_restricted * 1 == 1 && !user;

    const body = contentLoaded
      ? isRestricted
        ? postModel.restricted_body
        : postModel.body
      : "";

    const tagsObj =
      postModel && postModel.tags.length ? (
        postModel.tags.map((tag, index) => {
          return (
            <React.Fragment key={tag.slug}>
              <Link  to={"/blog/" + tag.slug}>
                {tag.label}
              </Link>
              {index !== postModel.tags.length - 1 ? ", " : null}
            </React.Fragment>
          );
        })
      ) : (
        <span className="tag">Too lazy to use tags</span>
      );

    const editControls =
      isOwner && !postState.voteLoading ? (
        <div className="post__edit-controls-actions">
          <span>
            <Link to={"/edit-post/" + postModel.slug}>
              <i className="fa fa-pencil" /> Edit
            </Link>
          </span>{" "}
          <span>
            <i className="fa fa-trash" onClick={this.onDeletePost}></i>
            Delete
          </span>
        </div>
      ) : null;
/*
    const voteControls = canVote ? (
      <div className="post__edit-controls-voting">
        <i
          className="material-icons voting-plus"
          onClick={() => this.props.postActions.votePost(postModel.slug, 1)}
        >
          add
        </i>
        <i
          className="material-icons voting-minus"
          onClick={() => this.props.postActions.votePost(postModel.slug, -1)}
        >
          remove
        </i>
      </div>
    ) : null;*/

    const editControlsWrap =
      !postState.edit && !postState.fetch ? (
        <div className="post__edit-controls">
          {/*{voteControls}*/}
          {editControls}
        </div>
      ) : null;

    const errorWrap =
      postState.deleteError ||
      postState.editError ||
      postState.imageUploadError ? (
        <div className="post__error-wrap col-xs-12">
          <span className="post__error alert">
            Some error has occurred. Please try again.
          </span>
        </div>
      ) : null;

/*

    const commentsObj =
      postModel && postModel.comments && !postState.edit && !postState.fetch
        ? postModel.comments.map((item, index) => {
            return (
              <Comment
                key={index}
                comment={item}
                deleteComment={this.props.deleteComment}
                user={user}
                onCommentSubmit={this.onCommentSubmit.bind(this)}
              />
            );
          })
        : null;
*/

    const fetchLoadingIndicator = postState.fetch ? (
      <div className="post__fetch-indicator">
        <div className={"text-center"}>
          <p>Loading post...</p>
          <Indicator size={40} />
        </div>
      </div>
    ) : null;

    const fetchError = postState.fetchError ? (
      <div className="post__fetch-error">
        <p className="alert">
          Some error has occured while loading the post. Please check if you're
          online.
        </p>
      </div>
   ) : null;
    /*
       const newsletterSignup =
         postModel &&
         !this.props.user.model &&
         !this.props.app.newsletterSignedUp ? (
           <React.Fragment>
             <div className={"col-xs-12"}>
               <NewsletterSignup tags={["fks-newsletter", "fks-article-page"]} />
             </div>
             <div className={"col-xs-12"}>
               <hr className="post__content-bottom-separator" />
             </div>
           </React.Fragment>
         ) : null;*/

    //console.log(postModel ? postModel.images : []);

    const previewImage = postModel && postModel.images.length ? postModel.images : null;

    const seoBlock = postModel ? (
      <React.Fragment>
        <SeoBlock
          title={postModel.title + " - The Beaverhead"}
          description={postModel.meta_description}
          keywords={postModel.meta_keywords.split(",")}
          image={previewImage ? previewImage[0].thumb_xs_path : null}
        />
        <PostArticle
          article={{
            ...postModel,
            platform: "The Beaverhead",
          }}
          org={ORG}
          slugPrefix={"/post"}
          socials={SOCIALS}
        />
        <Website
          org={ORG}
          socials={SOCIALS}
          publish_date={postModel.publish_date}
          url={"/post/" + postModel.slug}
          name={postModel.title + " - Founders Kitchen Sink"}
        />
      </React.Fragment>
    ) : null;

    const relatedPosts =
      postModel && postModel.related ? (
        <div className={"col-xs-12 col-md-12"}>
          <h4>Related Topics</h4>
          <div className={"post__content-related-posts"}>
            {postModel.related.map((i, idx) => {
              return <PostContainer key={"rp-" + idx} post={{ model: i }} />;
            })}
          </div>
          <hr className="post__content-bottom-separator" />
        </div>
      ) : null;

    const articleBody = postModel ? (
      <React.Fragment>
        <header className="section-title large">
          <h1 className="heading">{postModel.title}</h1>
        </header>
        {/*{featuredImage}*/}
        <div className="story portfolio">
          {/*<p className="lead">
            <i>{postModel.abstract}</i>
          </p>
*/}
          <ReactMarkdown
            children={body}
            className="markdown-content"
            components={{
              code: CodeBlock,
              link: LinkBlock,
              image: ImageBlock,
            }}
          />
        </div>
      </React.Fragment>
    ) : null;

    const authorAvatar = postModel ? (
      <img
        src={
          AxiosConfig.getEndpointAddress() + "/avatars/" + postModel.user.avatar
        }
        alt={postModel.user.name}
      />
    ) : null;

    const metaBlock = postModel ? (
      <div className="project-sidebar static">
        <div className="project-side-inner">
          <div className={"post-meta"}>
            <div className="client-desc">
              {editControlsWrap}
              <i className="fa fa-calendar"></i> &nbsp;
              {moment(postModel.publish_date * 1000).format("D MMM 'YY")}
              <br />
              <i className="fa fa-hashtag" />
              &nbsp;{tagsObj}
              <br />
              <br />
              <i className="fa fa-user-circle" />
              {" " + postModel.user.name}
              {authorAvatar}
              <p className="font-">{postModel.user.bio}</p>
            </div>
            {authorAvatar}
          </div>
        </div>
      </div>
    ) : null;

    return (
      <PageTemplate>
        {seoBlock}
        <section className="clearfix with-sidebar futr_portfolio type-futr_portfolio status-publish has-post-thumbnail hentry futr-portfolio-categories-identity container">
          {fetchLoadingIndicator}
          {fetchError}
          <div className="row">
            <article>
              {articleBody}
              <div id="comments" className="push-top-large" />
            </article>

            {metaBlock}
          </div>
        </section>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (store) => ({
  app: store.app,
  staticContent: store.staticContent,
  user: store.user,
  post: store.post,
  newComment: store.newComment,
  comments: store.comments,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrors()),
  fetchPost: async (slug) => await dispatch(fetchPost(slug)),
  uploadPostImage: async (file, resource, changeData) =>
    await dispatch(uploadPostImage(file, resource, changeData)),
  deleteComment: async (id) => await dispatch(deleteComment(id)),
  cleanupPostsCollection: () => dispatch(cleanupPostsCollection()),
  deletePost: async (slug) => await dispatch(deletePost(slug)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PostPage));
