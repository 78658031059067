/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/02/2019.
 */

import React from "react";
import { Link } from "react-router-dom";
import AppMenuItem from "./AppMenu/AppMenuItem";
import AppMenuDropdown from "./AppMenu/AppMenuDropdown";
import AppNavigation from "./AppNavigation";

const MobileMenu = (props) => {
  return (
    <div className="wrapper invert off-canvas-right" id="off-canvas-right">
      <a
        className="close-canvas"
        data-toggle="#off-canvas-right"
        data-toggle-class="open"
      >
        <i className="l-close" />
        Close{" "}
      </a>
      <div className="content nav-mobile clearfix">
        <AppNavigation kind={"vertical"}/>
      </div>
    </div>
  );
};

export default MobileMenu;
