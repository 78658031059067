/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/06/2018.
 * oczg -
 */



const initialState = {

  path: '/'
};

export default function reducer(
  state = {
    ...initialState
  },
  action) {
  switch (action.type) {

    case '@@router/LOCATION_CHANGE':
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', action.payload.location.pathname]);
      _hsq.push(['trackPageView']);
      return {
        ...state,
        path: action.payload.location.pathname
      };

    default:
      return state;

  }

  return state;
}