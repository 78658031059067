/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/08/2017.
 */


import React from "react";

import moment from 'moment';


class Timestamp extends React.Component {


  render() {

    const time = moment.unix(this.props.time);

    let returnObject = (
      <time className="" dateTime="MMMM D, YYYY">
        {time.format('MMMM D, YYYY')}
      </time>
    );

    if (this.props.showHours) {
      returnObject = (
        <time className="" dateTime="YYYY-MM-DD hh:mm">
          {time.format('YYYY-MM-DD HH:mm')}
        </time>
      );
    }

    if (this.props.postDay) {
      returnObject = (
        <time className="" dateTime="D">
          {time.format('D')}
        </time>
      );
    }

    if (this.props.postMonthYear) {
      returnObject = (
        <time className="" dateTime="MMM 'YY">
          {time.format("MMM 'YY")}
        </time>
      );
    }

    if (this.props.commentDate) {
      returnObject = (
        <time className="">
          Posted at {time.format("h:mma")}, {time.format("Do MMMM YYYY")}
        </time>
      );
    }

    return returnObject;
  }
}

export default Timestamp;