/**
 *
 * @param json
 */
export const jsonToHead = (json) => {
  let el = document.createElement("script");
  el.type = "application/ld+json";
  el.text = JSON.stringify(json);
  el.className = "structured";

  document.querySelector("head").appendChild(el);
};

/**
 *
 */
export const clearStructuredHead = () => {
  const data = document.getElementsByClassName("structured");

  for (let i = data.length - 1; i >= 0; --i) {
    data[i].remove();
  }
};
