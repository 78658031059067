/**
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { clearStructuredHead, jsonToHead } from "./Schema";
import { createOrganizationSchema } from "./Organization";

const FullOrganization = (props) => {
  useEffect(() => {
    const organization = createOrganizationSchema(props.org, props.socials);

    const founders = props.founders.map((i) => {
      return {
        "@type": "Person",
        name: i.name,
        jobTitle: i.position,
        knowsAbout: i.description,
        image: {
          "@type": "ImageObject",
          name: i.name + ", " + i.position,
          contentUrl: i.avatar,
        },
      };
    });

    organization.founders = founders;

    if (props.clear) {
      clearStructuredHead();
    }
    jsonToHead(organization);
  }, []);

  return null;
};

FullOrganization.propTypes = {
  org: PropTypes.object.isRequired,
  socials: PropTypes.array.isRequired,
  founders: PropTypes.array.isRequired,
};

export default FullOrganization;