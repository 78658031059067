
/**
 *
 * @param name
 * @returns {string}
 */
export const getInitials = name => {
  if (!name || !name.length) {
    return "UU";
  }
  let initials = name.charAt(0).toUpperCase();

  const tokenized = name.split(" ");

  if (tokenized.length === 1) {
    initials += name.charAt(1).toUpperCase();
  } else {
    initials += tokenized[1].charAt(0).toUpperCase();
  }

  return initials;
};

/**
 *
 * @param params
 */
export function objectToURLQuery(params = {}) {
  return Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");
}




/**
 *
 * @param contentId
 * @param data
 *
 */
export function updateCaretPositionAfterPhotoUpload(contentId, data) {
  const txtarea = document.querySelector(contentId);

/*
  let br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
    "ff" : (document.selection ? "ie" : false ) );

*/
  let scrollPos = txtarea.scrollTop;

  txtarea.value = data.text;
  let strPos = data.cursorPosition;

  txtarea.selectionStart = strPos;
  txtarea.selectionEnd = strPos;
  txtarea.focus();

  txtarea.scrollTop = scrollPos;
  txtarea.blur();
  txtarea.focus();
}

/***
 * Written by Uno Kim (http://djkeh.github.io)
 */
export let TabManager = {
  tabKey: 9, // This number means tab key ascii input.
  enableTab: function (textBox, keyEvent) {
    if (this.isTabKeyInput(keyEvent)) {
      // Put tab key into the current cursor(caret) position.
      this.insertTab(textBox);

      // Block(invalidate) actual tab key input by returning key event handler to false.
      this.blockKeyEvent(keyEvent);
    }
  },
  isTabKeyInput: function (keyEvent) {
    return keyEvent.keyCode === this.tabKey;
  },
  insertTab: function (textBox) {
    let pos = this.getCaretPosition(textBox);
    let preText = textBox.value.substring(0, pos);
    let postText = textBox.value.substring(pos, textBox.value.length);

    textBox.value = preText + "\t" + postText; // input tab key

    this.setCaretPosition(textBox, pos + 1);
  },
  setCaretPosition: function (item, pos) {
    // Firefox, Chrome, IE9~ Support
    if (item.setSelectionRange) {
      item.focus();
      item.setSelectionRange(pos, pos);
    }
    // ~IE9 Support
    else if (item.createTextRange) {
      let range = item.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  },
  getCaretPosition: function (item) {
    let caretPosition = 0;

    // Firefox, Chrome, IE9~ Support
    if (item.selectionStart || item.selectionStart === '0') {
      caretPosition = item.selectionStart;
    }
    // ~IE9 Support
    else if (document.selection) {
      item.focus();
      let sel = document.selection.createRange();
      sel.moveStart('character', -item.value.length);
      caretPosition = sel.text.length;
    }

    return caretPosition;
  },
  blockKeyEvent: function (keyEvent) {
    if (keyEvent.preventDefault) {
      keyEvent.preventDefault();
    }
    else {
      keyEvent.returnValue = false;
    }
  }
};