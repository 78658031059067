import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import contactForm from "./contactform";
import navigation from "./navigation";

import app from "./app";
import user from "./user";
import users from "./users";
import profile from "./profile";
import newPost from "./newPost";
import post from "./post";
import posts from "./posts";
import comment from "./comment";
import comments from "./comments";
import newsletter from "./newsletter";
import staticContent from "./staticContent";

export default history =>
  combineReducers({
    router: connectRouter(history),
    contactForm,
    navigation,
    app,
    user,
    users,
    profile,
    newPost,
    post,
    posts,
    comment,
    comments,
    newsletter,
    staticContent
  });
