/**
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { clearStructuredHead, jsonToHead } from "./Schema";
import { createOrganizationSchema } from "./Organization";

/**
 *
 * @param org
 * @param socials
 * @param serviceDesc
 * @param services
 * @returns {{serviceType: *, provider: *, '@type': string, description: *, '@context': string, hasOfferCatalog: {itemListElement: [{itemListElement: *, '@type': string, name: string, description: string}], '@type': string, name: string}, url: string}}
 */
const creteServiceSchema = (org, socials, serviceDesc, services) => {
  const offers = services.map((i) => {
    return {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: i.title,
        description: i.description,
      },
    };
  });

  return {
    "@context": "http://schema.org/",
    "@type": "Service",
    serviceType: serviceDesc.title,
    provider: createOrganizationSchema(org, socials),
    description: serviceDesc.description,
    url: window.location.href,
    hasOfferCatalog: {
      "@type": "OfferCatalog",
      name: serviceDesc.title + " Services",
      itemListElement: [
        {
          "@type": "OfferCatalog",
          name: "Software Development",
          description:
            "Are you looking for a custom website or a bespoke mobile app? Whatever you need, our team of developers is ready to build it for you. If you still don't know what kind of web presence suits your business, we can help work out which option works best for you. We have the following software development expertise:",
          itemListElement: offers,
        },
      ],
    },
  };
};

const Services = (props) => {
  useEffect(() => {
    if (props.clear) {
      clearStructuredHead();
    }

    jsonToHead(
      creteServiceSchema(
        props.org,
        props.socials,
        props.servicesDescriptions,
        props.services
      )
    );
  }, []);

  return null;
};

Services.propTypes = {
  org: PropTypes.object.isRequired,
  socials: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  servicesDescriptions: PropTypes.object.isRequired,
};

export default Services;
