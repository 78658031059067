const initialState = {
  signUp: false,
  signUpError: null,
  signUpSuccess: false
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "NEWSLETTER_SIGNUP":
      return {
        signUp: true,
        signUpError: null,
        signUpSuccess: false

      };

    case "NEWSLETTER_SIGNUP_REJECTED":
      return {
        ...state,
        signUp: false,
        signUpError: action.payload
      };

    case "NEWSLETTER_SIGNUP_FULFILLED":
      return {
        signUp: false,
        signUpSuccess: true
      };

    default:
      return state;
  }
}
