/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/02/2020.
 */

import React from "react";
import Indicator from "./Indicator";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { newsletterSignUp } from "../actions/newsletter";
import PropTypes from "prop-types";

class NewsletterSignup extends React.Component {
  static defaultProps = {
    header: "Never miss a thing",
    description:
      "Join our SaaS building team and get more insights to succeed with your online business"
  };

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.enterHandler = this.enterHandler.bind(this);

    this.showForm = !this.props.app.newsletterSignedUp;

    this.state = {
      newsletterEmail: ""
    };
  }

  /**
   *
   */
  componentDidMount() {
    document.addEventListener("keydown", this.enterHandler);
  }

  /**
   *
   */
  componentWillUnmount() {
    document.removeEventListener("keydown", this.enterHandler);
  }

  /**
   *
   * @param e
   */
  enterHandler(e) {
    e = e || window.event;

    let isEnter = false;

    if ("key" in e) {
      isEnter = e.key === "Enter";
    } else {
      isEnter = e.keyCode === 13;
    }

    if (
      isEnter &&
      (document.activeElement.tagName === "BODY" ||
        document.activeElement.id === "newsletterInput")
    ) {
      this.onSubmiNewslettert();
    }
  }

  /**
   *
   */
  onSubmiNewslettert = async () => {
    const data = {
      email: this.state.newsletterEmail,
      tags: this.props.tags
    };

    await this.props.newsletterSignUp(data);

    if (this.props.newsletter.signUpSuccess) {
      window.gtag("event", "conversion", {
        send_to: "AW-856624364/SAkqCMOwuscBEOyZvJgD"
      });
    }
  };

  /**
   *
   * @param e
   */
  oChangeNewsletterEmail = e => {
    this.setState({ newsletterEmail: e.target.value });
  };

  /**
   *
   */
  render() {
    if (!this.showForm || this.props.user.model) {
      return null;
    }
    const newsletter = this.props.newsletter;

    const signUpInput =
      !newsletter.signUp && !newsletter.signUpSuccess ? (
        <input
          className="default-input"
          type="email"
          placeholder="Your email"
          id="newsletterInput"
          value={this.state.newsletterEmail}
          disabled={newsletter.signUp}
          onChange={this.oChangeNewsletterEmail}
        />
      ) : null;

    const signUpIndicator = newsletter.signUp ? (
      <div className="welcome__newsletter-indicator">
        <Indicator size={24} />
      </div>
    ) : null;

    const signUpError = newsletter.signUpError ? (
      <div className="welcome__newsletter-error">
        <p className="alert">Couldn't sign you up. Please try again.</p>
      </div>
    ) : null;

    const signUpSuccess = newsletter.signUpSuccess ? (
      <div className="welcome__newsletter-success">
        <p className="success">
          You've successfully subscribed. You will hear from us shortly, please
          check your mailbox.
        </p>
      </div>
    ) : null;

    const signUpButton =
      !newsletter.signUpSuccess && !newsletter.signUp ? (
        <button
          type="button"
          className="btn default-button"
          disabled={newsletter.signUp}
          onClick={this.onSubmiNewslettert}
        >
          Join
        </button>
      ) : null;
    return (
      <section className="welcome newsletter-block">
        <div className="">
          <div className={"  welcome__input-row"}>
            <h3>Never miss a thing</h3>
            <div className="welcome__subtitle">
              <p>
                Join our SaaS building team and get more insights to succeed
                with your online business.
              </p>
            </div>
            {signUpInput}
            {signUpButton}
            {signUpIndicator}
            {signUpError}
            {signUpSuccess}
          </div>
        </div>
      </section>
    );
  }
}

NewsletterSignup.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array.isRequired
};

const mapStateToProps = store => ({
  app: store.app,
  user: store.user,
  newsletter: store.newsletter
});

const mapDispatchToProps = dispatch => ({
  newsletterSignUp: async data => await dispatch(newsletterSignUp(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewsletterSignup));
