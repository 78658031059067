/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/12/2019.
 */

import React from "react";

import classnames from "classnames";
import Section from "../Services/Section";
import AboutParagraphBlock from "./AboutParagraphBlock";
import AboutGrid3 from "./AboutGrid3";
import AboutGrid2 from "./AboutGrid2";

const AboutBlock = (props) => {
  const rows = props.blocks.body.map((i, idx) => {

    if (i.paragraphs) {
      return <AboutParagraphBlock block={i} key={"apb-" + idx}/>
    }
    else if (i.grid_3) {
      return <AboutGrid3 block={i} key={"ag3-" + idx}/>
    }
    else if (i.grid_2) {
      return <AboutGrid2 block={i} key={"ag2-" + idx}/>
    }

  });

  return (
    <div
      className={classnames(
        "widget layers-content-widget content-vertical-massive story",
        { bgdark: false }
      )}
    >
      <div className="container list-grid">
        <Section lead={null} title={props.blocks.header} bgDark={false} />
        {rows}
      </div>
    </div>
  );
};

export default AboutBlock;
