/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as userActions from "./actions/user";
import { history } from "./configureStore";

/*
import HomePage from "./pages/HomePage";
import SoftwareDevelopmentPage from "./pages/SoftwareDevelopmentPage";
import SystemIntegrationsPage from "./pages/SystemIntegrationsPage";
import BusinessAppsPage from "./pages/BusinessAppsPage";
import AboutPage from "./pages/AboutPage";
import TeamPage from "./pages/TeamPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ContactPage from "./pages/ContactPage";
import PortfolioPage from "./pages/PortfolioPage";
import AppMenu from "./pages/components/AppMenu";

import LoadingScreen from "./components/LoadingScreen";
import NotFoundPage from "./pages/NotFoundPage";
import TestimonialsPage from "./pages/TestimonialsPage";
import CustomCRMPage from "./pages/CustomCRMPage";
*/

import { fetchStore } from "./actions/staticContent";
import MainRoutesComponent from "./MainRoutesComponent";
import ModalRoutesComponent from "./ModalRoutesComponent";
import LoadingScreen from "./components/LoadingScreen";
import { clearErrors } from "./actions/app";
import AxiosConfig from "./AxiosConfig";

function set_cookie(name, value, exp_y, exp_m, exp_d, path, domain, secure) {
  var cookie_string = name + "=" + escape(value);

  if (exp_y) {
    var expires = new Date(exp_y, exp_m, exp_d);
    cookie_string += "; expires=" + expires.toGMTString();
  }

  if (path) cookie_string += "; path=" + escape(path);

  if (domain) cookie_string += "; domain=" + escape(domain);

  if (secure) cookie_string += "; secure";

  document.cookie = cookie_string;
}

const RouterClass = (props) => {
  useEffect(() => {
    if (props.user.token) {
      AxiosConfig.setAuthToken(props.user.token);
    }

    props.fetchStore();

    const deleteAllCookies = () => {
      var cookies = document.cookie.split(";");

      for (const cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        set_cookie(name, "", 1970, 1, 1, "/", `.${window.location.hostname}`);
      }
    };

    if (
      props.app.cookieAccepted === true ||
      props.app.cookieAccepted === false
    ) {
      deleteAllCookies();
    }
  }, []);

  const modalLinks = ["/login", "/signup", "/edit-post"];

  const prevModalLocation = [
    { "/login": "/" },
    { "/signup": "/" },
    { "/edit-post": "/" },
  ];

  if (!props.staticContent.fetchSuccess) {
    return <LoadingScreen />;
  }

  return (
    <ConnectedRouter history={history}>
      <MainRoutesComponent modalLinks={modalLinks} />
      <ModalRoutesComponent
        modalLinks={modalLinks}
        prevModalLocation={prevModalLocation}
      />
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
  router: state.router,
  staticContent: state.staticContent,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrors()),
  checkSession: (isLogged) => dispatch(userActions.checkSession(isLogged)),
  logout: () => dispatch(userActions.logout()),
  fetchStore: async () => await dispatch(fetchStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterClass);
