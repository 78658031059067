import React from "react";


export default class ImageUploadedTick extends React.Component {

  render() {

    return (
      <div className="image-uploaded-tick__container">
        <div className="trigger drawn" />
        <svg version="1.1"
             id="tick"
             x="0px"
             y="0px"
             viewBox="0 0 37 37"
        >
          <path
            className="circ tick-path"
            d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
          />
          <polyline
            className="tick tick-path"
            points="11.6,20 15.9,24.2 26.4,13.8"
          />
        </svg>
      </div>
    );

  }
}