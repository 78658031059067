/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/02/2019.
 */


import React from "react";
import PropTypes from 'prop-types';

export default class LoadingScreen extends React.Component {

  render() {

    return (
      <div className="fakeloader">
        <div className="fl">

          <img src='/img/logo-tbh-white.svg'/>

        </div>
        <div className="loaderouter">
          <div className="preloadmiddle">
            <div className="preloadinner"></div>
          </div>
        </div>
      </div>
    );
  }
}
