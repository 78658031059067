import React from 'react';

export const HP_PORTFOLIO_FILTERS = [
  {
    label: "All",
    option: "",
    defaultActive: true
  },
  {
    label: "Mobile",
    option: ".mobile",
    defaultActive: false
  },
  {
    label: "Web",
    option: ".web",
    defaultActive: false
  },
  {
    label: "Coming Soon",
    option: ".incoming",
    defaultActive: false
  }
];

export const HP_PORTFOLIO = [
  {
    kind: ["web"],
    title: "Fitpic",
    thumbnail: "fitpic",
    description: "fitpic",
    slug: "fitpic"
  },
  {
    kind: ["web", "incoming"],
    title: "CCB Events",
    thumbnail: "ccb",
    description: "ccb",
    slug: "ccb"
  }
];

export const HP_SERVICES = [
  {
    title: "BUSINESS DEVELOPMENT",
    icon: "fa-handshake-o",
    description:
      "Turn your passion into a thriving business. With us, you will be able to consolidate your business model, explore business opportunities and define the scope of your project. We can help you avoid unnecessary costs and mistakes."
  },
  {
    title: "SOFTWARE DEVELOPMENT",
    icon: "fa-code",
    description:
      "Custom website or bespoke mobile app? Whatever you need, our team of developers can help you build it."
  },
  {
    title: "EMERGENCY HELP",
    icon: "fa-fire-extinguisher",
    description:
      "Problems with your MVP? Servers that just won't work? Whatever the emergency our live chat and email support services are here to help."
  }
];

export const HP_PROJECTS_FILTERS = [
  {
    label: "All",
    option: "",
    defaultActive: true
  },
  {
    label: "Mobile",
    option: ".mobile",
    defaultActive: false
  },
  {
    label: "Web",
    option: ".web",
    defaultActive: false
  },
  {
    label: "Coming Soon",
    option: ".incoming",
    defaultActive: false
  }
];

export const HP_PROJECTS = [
  {
    kind: ["web"],
    title: "Fitpic",
    thumbnail: "fitpic",
    description: "fitpic",
    slug: "fitpic"
  },
  {
    kind: ["web", "incoming"],
    title: "CCB Events",
    thumbnail: "ccb",
    description: "ccb",
    slug: "ccb"
  }
];

export const HP_CLIENTS = [
  {
    name: "Lab31",
    logo: "logo-lab31.png",
    url: "https://lab31.pl"
  },
  {
    name: "Squeeze Technology",
    logo: "logo-squeezetech.png",
    url: "http://www.squeezetech.com"
  },
  {
    name: "Placepark",
    logo: "logo-placepark.png",
    url: "https://placepark.com"
  },
  {
    name: "Fitpic",
    logo: "logo-fitpic.png",
    url: "https://fitpic.pl"
  }
];

export const SERVICES_BD_DESC = {
  title: "Business development",
  lead: "We have extensive experience in building businesses from scratch. We have particular expertise in the following areas:"
};

export const SERVICES_BD_ITEMS = [
  {
    title: "Business Model Review",
    icon: "fa-area-chart",
    description:
      "In our experience start-ups often find it difficult to translate great ideas into a working business model. Sometimes our clients have yet to even produce a business model. Do you have a million-dollar idea but no idea where to start? We can help you build a business model canvas and find the right market for your start-up."
  },
  {
    title: "Project Management",
    icon: "fa-calendar-check-o",
    description:
      "Project management can often slip down your list of priorities when you're trying to grow your business. But without it businesses often fail. Our project management support could make the difference between success and failure. We have substantial experience leading projects to term on time and on budget, all while maintaining the highest standards. Let us manage your project, allowing you to focus on expansion."
  },
  {
    title: "Project Scope Definition",
    icon: "fa-edit",
    description:
      "Business people are highly creative; however, sometimes an excess of innovative and new ideas may hamper the rate of progress. Rationalizing and prioritizing the features in a project is key to its success. This will ensure that the final product is more responsive to consumer needs and costs will not overrun. Let us help you to frame the scope of your project."
  },
  {
    title: "Project Documentation Control",
    icon: "fa-tasks",
    description:
      "When you set out on a journey you need a map to stop you from getting lost. Successfully monitored project documentation is that map, securing the delivery of cost-controlled and well-structured projects on time. The Functional Requirements Document is the key element in this process. It enables you to plan the necessary steps in a project, choose the right tools, and determine the resources required to complete it. Need help assessing the cost of your project or producing documentation? We can make this happen."
  },
  {
    title: "Market Research",
    icon: "fa-globe",
    description:
      "If you are going to build a successful product, you need to know the consumer's needs and understand what your competition have to offer. We can help you build a picture of the marketplace and minimize the risk of failure."
  }
];


export const SERVICES_SD_DESC = {
  title: "Software development",
  lead: "Are you looking for a custom website or a bespoke mobile app? Whatever you need, our team of developers is ready to build it for you. If you still don't know what kind of web presence suits your business, we can help work out which option works best for you. We have the following software development expertise:"
};

export const SERVICES_SD_ITEMS = [
  {
    title: "Prototypes",
    icon: "fa-paper-plane",
    description:
      "New concepts need to be tested. Rather than find out too late that your product doesn't work, we can help you save time and money by building a prototype and testing it against the Value Proposition Canvas. This process allows you to answer two key questions: 1. How feasible the idea actually is. 2. How likely the idea is to grab the attention of potential users. We can help you build a successful prototype, finding the environment and tools that will make it work. We are happy to sign an NDA where required."
  },
  {
    title: "Minimum Viable Product (MVP)",
    icon: "fa-compass",
    description:
      "Success in business is underpinned by three fundamentals: Product, Marketing, and Sales. Let us take care of securing your Minimum Viable Product so that you can focus on the other core elements of your business. With our expertise in preparing business models and managing project documentation, we can deliver your MVP in no time."
  },
  {
    title: "Implementation",
    icon: "fa-file-code-o",
    description:
      "Our team of developers is always ready for a new challenge, and will use the latest technologies to implement your product. We guarantee the highest standards in internal testing and verification. In this way we guarantee a stable product with the latest innovations at its heart. If you want imaginative solutions to your problems we are the right people for the job, and have just the ‘black magic' needed to get it done."
  },
  {
    title: "Apps Maintenance",
    icon: "fa-server",
    description:
      "The implementation of a fresh service may be relatively easy, but maintenance is often less so. The period after a project is launched is often one where additional features or urgent support are most needed. We are there to help you through this period."
  },
  {
    title: "DevOps",
    icon: "fa-connectdevelop",
    description:
      "The approach to programming has changed significantly over the last ten years. Nowadays the software development process requires the ability to quickly modify configurations while maintaining the technology stack and its dependencies. We use the Continuous Delivery and Integration model when introducing modifications in order to keep software updates stable and issue-free."
  },
  {
    title: "Cloud Services",
    icon: "fa-cloud",
    description:
      "We host applications on our own servers as well as third party cloud services such as Amazon AWS. In both cases, we help you choose the right tools, whether your application requires a significant data storage or has high I/O consumption, we can support\u00A0it."
  }
];


export const SERVICES_TECH_DESC = {
  title: "Technologies",
  lead: "Over the years, we've mastered the following technologies:"
};


export const SERVICES_TECH_ITEMS = [
  {
    title: "Programming languages",
    icon: "null",
    description:
      "PHP, JavaScript (ES5, ES6), C, C++"
  },
  {
    title: "Frameworks",
    icon: null,
    description:
      "React, React Native, CakePHP, LoopBack, Backbone.js"
  },
  {
    title: "Platforms",
    icon: null,
    description:
      "Web, iOS, Android, Linux, Docker"
  },
  {
    title: "Integration",
    icon: null,
    description:
      "Self-hosted and third party API integration with REST, AJAX, JSON, SOAP, WebSocket"
  },
  {
    title: "Frontend",
    icon: null,
    description:
      "SPA, PWA, AMP, HTML5, CSS3, LESS, SASS, jQuery, bootstrap "
  },
  {
    title: "Data",
    icon: null,
    description:
      "MySQL, MongoDB, Firebase "
  },


];

export const MGMT = [
  {
    name: "Piotr Poźniak",
    position: "CEO",
    description:
      "I am coding for over 15 years. There is nothing better from creating something new on the piece of a paper and making it happen (besides of the cup of a coffee, of course).",
    avatar: "/img/mgmt-piotr.jpg"
  },
  {
    name: "Paulina Rzepiel",
    position: "COO",
    description:
      "I work in business for over 10 years. Growth is the underlying passion that drives my entrepreneurial spirit.",
    avatar: "/img/mgmt-paulina.jpg"
  }
];

export const STORY = [
  {
    video: {
      sources: [
        {
          src: "/img/about_norway.mp4",
          type: "video/mp4"
        },
        {
          src: "/img/about_norway.webm",
          type: "video/webm"
        },
        {
          src: "/img/about_norway.ogg",
          type: "video/ogg"
        }
      ],
      placeholder:
        "Your browser does not support the video tag, bummer! Here was a nice shoot of Norway raindeers..."
    },

    caption: "A midnight sun bike ride in Nordkapp, recorded by Piotr",
    chapter:
      "In 2013, Piotr took a break from his role as a senior developer in the aviation industry and travelled across Europe in search of inspiration, from the Greek island of Crete to the northern tip of Norway. On the German leg of his tour, in the village of Bruchenbrücken near Frankfurt am Main, he finally had the idea of creating 'Remo'. The goal was a platform built on mobile software and tools that would make remote IT support available for anyone."
  },
  {
    image: {
      src: "/img/about_tbh_mgmt.jpg"
    },
    caption: "Heads of The Beaverhead, photo taken by Adam Zajkowski (Hi Adam!)",
    chapter:
      "Piotr shared this idea with his friends Paulina and Dorota, and they jumped at the opportunity to make it a reality. In April 2014 they started The Beaverhead, a company with the goal of providing continuous development founded on the latest technologies, all while respecting the natural environment. The Beaverhead is at the forefront of providing the tools necessary to sustain the entrepreneurial ecosystem."
  },
  {
    image: {
      src: "/img/about_lab.jpg"
    },
    caption: "Mobiles in the Remo lab, photo taken by Piotr",
    chapter:
      "The technical side of the concept has posed a number of challenges. The most pressing issue was setting up facilities; a lab with mobile devices that could be controlled by Remo. It took the team at The Beaverhead two years to get this fully integrated operation up and running. In order to minimise operational costs they based themselves in their hometown of Zielona Gora in Poland."
  },
  {
    image: {
      src: "/img/about_team.jpg"
    },
    caption: "A modest office party, goproed by Krzysztof",
    chapter:
      "As they developed the Remo platform, The Beaverhead team began to support clients with technical issues that were hampering their ability to build and grow their own businesses. Putting their experience in software development to use, they offered innovative solutions that helped exciting new start-ups get off the ground. Over time the team has grown and changed; however, The Beaverhead continue to support their clients build their companies with eco-friendly software."
  }
];


export const SOCIALS = [
  {
    url: 'https://github.com/thebeaverhead',
    icon: 'fa-github'
  },
  {
    url: 'https://www.linkedin.com/company/the-beaverhead',
    icon: 'fa-linkedin'
  },

  {
    url: 'https://soundcloud.com/thebeaverhead',
    icon: 'fa-soundcloud'
  },

];


export const PORTFOLIO = [
  {
    name: "Placepark",
    title: "Location based social platform",
    slug: 'placepark',
    client: 'Placepark',
    kind: ["web"],
    lead: "Create a place where people can find a motivation, without a makeup. Consolidate traffic from many sources into one social platform.",
    link: "https://placepark.com",
    tags: ["web app", "social platform", "locations"],
    technologies: ["JavaScript", "ReactJS", "PHP", "MySQL"],
    platform: "WebApp",
    year: "2016",
    logo: "/img/logo-placepark.png",
    featured: {
      src: "/img/n/content/projects/placepark/featured.jpg",
      description: ""
    },
    images: [
      {
        src: "",
        description: ""
      }
    ],
    testimonial: "Thanks to The Beaverhead we were able to bring our vision to reality. They led us by the hand through technical complexities and helped in every single step from mockups to going live. We are also very satisfied how the project was managed - the communication was fast and productive, everything was delivered on time. We are happy that we have such a great partner at our side and we can't wait to receive another bit of code from them :) - Wojciech Kościesza, Owner",
    p: [
      "How alienated do you feel when you arrive in a new place you don't know? How to get to know this place? Where to go to feel the local vibes? How to find out where you can do something you need to do? Facebook can help but is limited because of its one drawback - you need to know people who live in or visited a given location. However, in many cases, such as when you move to a new city, you do not have a friends' network there.",
      "During his professional career, Wojtek found himself in a similar situation many times. Every time he moved to a new city, he faced the same problem of no friends and no information about local events and places worth knowing. That's why he designed an interface for a social platform, which will focus primarily on the place and community that is connected with this place, and not on the individuals themselves.",
      "We could say that the task was easier, because the entire UI mockup, as well as the UX, were very well prepared, so implementing the whole project was a pleasure.",
      "Since the launch of the platform, we have been adding new functionalities and improving existing ones. Because the whole project is based on our Continuous Delivery, the introduction of subsequent changes is efficient, and users are not exposed to downtime and interruption in the service.\n",
    ]
  },
  {
    name: "Fitpic",
    title: "Share your passion",
    slug: 'fitpic',
    client: 'fitpic',
    kind: ["web", "social"],
    lead: "Create a place where people can find a motivation, without a makeup. Consolidate traffic from many sources into one social platform.",
    link: "https://fitpic.pl",
    tags: ["web app", "social platform", "fitness", "wellness"],
    technologies: ["JavaScript", "ReactJS", "PHP", "MySQL"],
    platform: "WebApp",
    year: "2016",
    logo: "/img/logo-fitpic.png",
    featured: {
      src: "/img/n/content/projects/fitpic/featured.jpg",
      description: ""
    },
    images: [
      {
        src: "",
        description: ""
      }
    ],
    testimonial: "tany tany",
    p: [
      "Meet Agnieszka, a law graduate. However, law is not her main occupation. Like many women, Agnieszka was not happy with her figure. This seemingly insignificant problem turned out to be so serious for her that she decided to take charge of her life and change it for the better. With hard work and self-determination Agnieszka has achieved what many women dream about- a fabulous figure achieved without fad diets and torturous exercise regimes. Thanks to her innate empathy, she decided to quit her job and started sharing her knowledge with others through her blog, where she provided recipes for healthy meals - an indispensable element on the way to achieve a dream figure.",
      "During the several years Agnieszka spent working on herself, she helped many women, and achieved more than 50 thousand Instagram followers. Instagram, however, is not the best place to look for motivation. Filters and a huge number of photo-shopped pictures give a false image of how real life and real hard work that gets results look like.",
      "Our goal was to create a platform for people who want to change their lives through physical activity and proper diet, where they can share their results and get motivated with photos and content from other users. The content on the platform should be authentic, not subject to any filters, and show the \"naked\" truth. Simply - not create a false image of reality.",
      "We created a portal that allows users to efficiently view and add photos, grouped into well-defined categories so that all content is placed in the correct places. No mess, because order is the first step to improving your life.",
      "Agnieszka directed traffic from her channels to Fitpic.pl, which brought her over 2,000 registered users in less than a month. These users generate approximately 30,000 image views per day. The most important is that they are engaged users who really work on improving their lives, and fitpic is one of the key elements in their transformation."
    ]
  },
];


export const PROJECTS = [
  {
    name: "Placepark",
    title: "Location based social platform",
    slug: 'placepark',
    client: 'Placepark',
    kind: ["web"],
    lead: "Create a place where people can find a motivation, without a makeup. Consolidate traffic from many sources into one social platform.",
    link: "https://placepark.com",
    tags: ["web app", "social platform", "locations"],
    technologies: ["JavaScript", "ReactJS", "PHP", "MySQL"],
    platform: "WebApp",
    year: "2016",
    logo: "/img/logo-placepark.png",
    featured: {
      src: "/img/n/content/projects/placepark/featured.jpg",
      description: ""
    },
    images: [
      {
        src: "",
        description: ""
      }
    ],
    testimonial: "Thanks to The Beaverhead we were able to bring our vision to reality. They led us by the hand through technical complexities and helped in every single step from mockups to going live. We are also very satisfied how the project was managed - the communication was fast and productive, everything was delivered on time. We are happy that we have such a great partner at our side and we can't wait to receive another bit of code from them :) - Wojciech Kościesza, Owner",
    p: [
      "How alienated do you feel when you arrive in a new place you don't know? How to get to know this place? Where to go to feel the local vibes? How to find out where you can do something you need to do? Facebook can help but is limited because of its one drawback - you need to know people who live in or visited a given location. However, in many cases, such as when you move to a new city, you do not have a friends' network there.",
      "During his professional career, Wojtek found himself in a similar situation many times. Every time he moved to a new city, he faced the same problem of no friends and no information about local events and places worth knowing. That's why he designed an interface for a social platform, which will focus primarily on the place and community that is connected with this place, and not on the individuals themselves.",
      "We could say that the task was easier, because the entire UI mockup, as well as the UX, were very well prepared, so implementing the whole project was a pleasure.",
      "Since the launch of the platform, we have been adding new functionalities and improving existing ones. Because the whole project is based on our Continuous Delivery, the introduction of subsequent changes is efficient, and users are not exposed to downtime and interruption in the service.\n",
    ]
  },
  {
    name: "Fitpic",
    title: "Share your passion",
    slug: 'fitpic',
    client: 'fitpic',
    kind: ["web"],
    lead: "Create a place where people can find a motivation, without a makeup. Consolidate traffic from many sources into one social platform.",
    link: "https://fitpic.pl",
    tags: ["web app", "social platform", "fitness", "wellness"],
    technologies: ["JavaScript", "ReactJS", "PHP", "MySQL"],
    platform: "WebApp",
    year: "2016",
    logo: "/img/logo-fitpic.png",
    featured: {
      src: "/img/n/content/projects/fitpic/featured.jpg",
      description: ""
    },
    images: [
      {
        src: "",
        description: ""
      }
    ],
    testimonial: "tany tany",
    p: [
      "Meet Agnieszka, a law graduate. However, law is not her main occupation. Like many women, Agnieszka was not happy with her figure. This seemingly insignificant problem turned out to be so serious for her that she decided to take charge of her life and change it for the better. With hard work and self-determination Agnieszka has achieved what many women dream about- a fabulous figure achieved without fad diets and torturous exercise regimes. Thanks to her innate empathy, she decided to quit her job and started sharing her knowledge with others through her blog, where she provided recipes for healthy meals - an indispensable element on the way to achieve a dream figure.",
      "During the several years Agnieszka spent working on herself, she helped many women, and achieved more than 50 thousand Instagram followers. Instagram, however, is not the best place to look for motivation. Filters and a huge number of photo-shopped pictures give a false image of how real life and real hard work that gets results look like.",
      "Our goal was to create a platform for people who want to change their lives through physical activity and proper diet, where they can share their results and get motivated with photos and content from other users. The content on the platform should be authentic, not subject to any filters, and show the \"naked\" truth. Simply - not create a false image of reality.",
      "We created a portal that allows users to efficiently view and add photos, grouped into well-defined categories so that all content is placed in the correct places. No mess, because order is the first step to improving your life.",
      "Agnieszka directed traffic from her channels to Fitpic.pl, which brought her over 2,000 registered users in less than a month. These users generate approximately 30,000 image views per day. The most important is that they are engaged users who really work on improving their lives, and fitpic is one of the key elements in their transformation."
    ]
  },
];


export const CONTACT = [
  {
    label: 'Tel',
    value: '+44 0 898989898',
    title: 'Telephone number',
    url: "tel:+44 00032"
  }
];