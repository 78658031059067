/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2020.
 */

import React from "react";
import AppMenuItem from "./AppMenu/AppMenuItem";
import AppMenuDropdown from "./AppMenu/AppMenuDropdown";
import classnames from "classnames";
import PropTypes from "prop-types";

const AppNavigation = (props) => {
  return (
    <nav className={classnames("nav", "nav-" + props.kind)}>
      <ul id="menu-mainmenu-top" className="menu">
        <AppMenuItem url={"/"} name={"Home"} />
        <AppMenuDropdown name={"Portfolio"} url={"/portfolio"}>
          <AppMenuItem
            url={"/portfolio/solutions-we-have-built"}
            name={"Solutions we have built"}
          />
          <AppMenuItem url={"/portfolio/clients"} name={"Clients"} />
          <AppMenuItem url={"/portfolio/testimonials"} name={"Testimonials"} />
        </AppMenuDropdown>
          <AppMenuItem
            url={"/services"}
            name={"Services"}
          />

        {/*<AppMenuDropdown name={"Services"} url={"/services"}>
          <AppMenuItem
            url={"/services/how-we-can-help-you"}
            name={"How we can help you"}
          />
          <AppMenuItem url={"/services/what-we-do"} name={"What we do"} />
          <AppMenuItem
            url={"/services/custom-software"}
            name={"Custom software"}
          />
          <AppMenuItem
            url={"/services/system-integrations"}
            name={"System integrations"}
          />
          <AppMenuItem url={"/services/technologies"} name={"Technologies"} />
        </AppMenuDropdown>*/}

        {/*<AppMenuDropdown name={"About us"} url={"/about"}>
          <AppMenuItem url={"/about"} name={"About"} />
          <AppMenuItem url={"/our-story"} name={"Our story"} />
          <AppMenuItem url={"/team"} name={"The team"} />
        </AppMenuDropdown>*/}

        <AppMenuDropdown name={"Resources"} url={"/blog"}>
          {/*<AppMenuItem
            url={
              "/e-guides/6+plus+1+ways+to+optimise+your+business+operation+spreadsheets"
            }
            name={"E-guides"}
          />*/}
          {/*<AppMenuItem
            url={"/case-studies/unique-tracking-solution-for-big-lazy-panda"}
            name={"Case Studies"}
          />*/}
          {/*<AppMenuItem
            url={"/tools/pdf-bank-statement-data-extractor"}
            name={"Tools"}
          />*/}
          <AppMenuItem url={"/blog"} name={"Blog"} />
        </AppMenuDropdown>
        <AppMenuItem url={"/contact"} name={"Contact us"} />
      </ul>

      <a
        href="#"
        className="responsive-nav"
        data-toggle="#off-canvas-right"
        data-toggle-class="open"
      >
        <span className="l-menu" />
      </a>
    </nav>
  );
};

AppNavigation.propTypes = {
  kind: PropTypes.oneOf(["horizontal", "vertical"]),
};
export default AppNavigation;
