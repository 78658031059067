const initialState = {
  delete: false,
  deleteSuccess: false,
  deleteError: null,

  editing: false,

  edit: false,
  editSuccess: false,
  editError: null,

  vote: false,
  voteSuccess: false,
  voteError: null,

  imageUpload: false,
  imageUploadSuccess: false,
  imageUploadError: null,

  changeBody: null
};

export default function reducer(
  state = {
    collection: null
  },
  action
) {
  let newCollection = null;

  switch (action.type) {
    // case "NEW_COMMENT_FOCUS":
    // case "POST_EDIT_START":
    // case "POST_EDIT_CANCEL":
    // case "POST_EDIT":
    // case "POST_VOTE":
    // case "ANSWER_DELETE":
    // case "ANSWER_EDIT_START":
    // case "ANSWER_EDIT_CANCEL":
    // case "ANSWER_EDIT":
    // case "ACCEPT_ANSWER":
    // case "ANSWER_VOTE":
    // case "ANSWER_ADD":
    //   return {
    //     ...state,
    //     collection: state.collection.map((item) => {
    //       return {
    //         model: {
    //           ...item.model,
    //           changeBody: null
    //         },
    //         postState: initialState
    //       }
    //     })
    //   };

    case "POST_FETCH":
      return {
        ...state,
        collection: null
      };

    case "COMMENT_DELETE":
      return {
        ...state,
        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              deleting: true
            };
          }
          return item;
        })
      };

    case "COMMENT_DELETE_REJECTED":
      return {
        ...state,
        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              deleteError: action.payload
            };
          }
          return item;
        })
      };

    case "COMMENT_EDIT_START":
      return {
        ...state,
        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item = {
              ...item,
              postState: {
                ...initialState,
                editing: true
              }
            };
          } else {
            item.postState = initialState;
          }
          return item;
        })
      };

    case "COMMENT_EDIT_CANCEL":
      return {
        ...state,
        collection: state.collection.map(item => {
          item.postState = initialState;

          return item;
        })
      };

    case "COMMENT_EDIT":
      return {
        ...state,
        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...item.postState,
              editLoading: true,
              editing: true
            };
          }
          return item;
        })
      };

    case "COMMENT_EDIT_FULFILLED":
      newCollection = [];

      newCollection = newCollection.concat(
        action.payload.comments.map(item => {
          let newPostState = {
            ...initialState
          };

          if (item.uuid === action.uuid) {
            newPostState.editSuccess = true;
          }

          return {
            model: {
              ...item
            },
            postState: newPostState
          };
        })
      );

      return {
        ...state,
        collection: newCollection
      };

    case "COMMENT_EDIT_REJECTED":
      return {
        ...state,
        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              editing: true,
              editError: action.payload
            };
          }
          return item;
        })
      };

    //
    case "COMMENT_IMAGE_UPLOAD":
      return {
        ...state,

        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              editing: true,
              imageUploadLoading: true
            };
          }
          return item;
        })
      };

    case "COMMENT_IMAGE_UPLOAD_FULFILLED":
      return {
        ...state,

        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              editing: true,
              imageUploadSuccess: true,
              changeBody: {
                text:
                  action.changeData.front +
                  "![" +
                  action.payload.filename +
                  "](" +
                  action.payload.thumb_md_path +
                  ")" +
                  action.changeData.back,
                cursorPosition:
                  action.changeData.cursorPosition +
                  action.payload.filename.length +
                  5 +
                  action.payload.thumb_md_path.length
              }
            };
          }
          return item;
        })
      };

    case "COMMENT_IMAGE_UPLOAD_REJECTED":
      return {
        ...state,
        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              editing: true,
              imageUploadError: action.payload
            };
          }
          return item;
        })
      };

    case "COMMENT_DELETE_FULFILLED":
    case "NEW_COMMENT_ADD_FULFILLED":
    case "POST_FETCH_FULFILLED":
    case "COMMENT_VOTE_FULFILLED":
      newCollection = [];

      newCollection = newCollection.concat(
        action.payload.comments.map(item => {
          return {
            model: {
              ...item,
              parent_uuid: action.payload.uuid
            },
            postState: initialState
          };
        })
      );

      return {
        ...state,
        collection: newCollection
      };

    case "COMMENT_VOTE":
      return {
        ...state,

        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              voteLoading: true
            };
          }
          return item;
        })
      };

    case "COMMENT_VOTE_REJECTED":
      return {
        ...state,

        collection: state.collection.map(item => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              votingError: action.payload
            };
          }
          return item;
        })
      };

    // case "COMMENT_VOTE_FULFILLED":
    //
    //   return {
    //     ...state,
    //     collection: action.payload.comments.map((item) => {
    //       return {
    //         model: item,
    //         postState: {
    //           ...initialState,
    //           votingSuccess: true
    //         }
    //       }
    //     })
    //   }

    default:
      return state;
  }
}
