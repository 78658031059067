/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/10/2020.
 */

import React from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";
import Section from "./Portfolio/Section";
import SeoBlock from "./components/SeoBlock";
import { withRouter } from "react-router-dom";
import Portfolio from "./Portfolio/Portfolio";
import ClientsCarousel from "../components/ClientsCarousel";
import Quote from "./Portfolio/Quote";

const PortfolioIndexPage = (props) => {
  const { PORTFOLIO, PORTFOLIO_PAGE, HP_CLIENTS } = props.staticContent.model;

  const currentSection = window.location.pathname
    .substr(1)
    .replace("portfolio/", "");
  const portfolioIndex = currentSection == "portfolio" ? "/" : currentSection;

  const seo = PORTFOLIO_PAGE.sections[portfolioIndex]
    ? PORTFOLIO_PAGE.sections[portfolioIndex].SEO
    : null;

  const seoBlock = seo ? (
    <SeoBlock
      title={seo.page_title}
      description={seo.description}
      keywords={seo.keywords}
    />
  ) : null;

  const quotes = PORTFOLIO_PAGE.testimonials.map((i, idx) => {
    return <Quote key={"ct-" + idx} cite={i.cite} signature={i.signature} />;
  });

  return (
    <PageTemplate>
      {seoBlock}

      <Section
        title={PORTFOLIO_PAGE.sections["/"].title}
        lead={PORTFOLIO_PAGE.sections["/"].lead}
        items={[]}
        body={null}
        bgDark={true}
        callout={null}
        id={"/"}
      />

      <Portfolio
        id="section-portfolio/solutions-we-have-built"
        title={PORTFOLIO_PAGE.sections["solutions-we-have-built"].title}
        subtitle={PORTFOLIO_PAGE.sections["solutions-we-have-built"].lead}
        projects={PORTFOLIO}
        slugPrefix="/portfolio/"
      />

      <Section
        title={PORTFOLIO_PAGE.sections["clients"].title}
        lead={PORTFOLIO_PAGE.sections["clients"].lead}
        items={[]}
        body={null}
        bgDark={true}
        callout={null}
        id={"clients"}
      >
        <ClientsCarousel clients={HP_CLIENTS} />
      </Section>

      <Section
        title={PORTFOLIO_PAGE.sections["testimonials"].title}
        lead={PORTFOLIO_PAGE.sections["testimonials"].lead}
        items={[]}
        body={null}
        bgDark={false}
        callout={null}
        id={"testimonials"}
      >
        <section className="clearfix futr_portfolio  container">
          <div className="row">
            <div className={"offset-left-2  span-8"}>
              <article>
                <div className="story portfolio">{quotes}</div>
              </article>
            </div>
          </div>
        </section>
        {props.app.cookieAccepted === 1 ? (
          <div className="container clearfix">
            <div className="section-title clearfix medium text-center ">
              <div className="layers-masonry-column span-8 offset-left-2 column ">
                <div className="section-title clearfix medium text-center ">
                  <p>&nbsp;</p>
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/sWdo5JqRFMk"
                    allowfullscreen=""
                    scrolling="no"
                    allow="encrypted-media; accelerometer; gyroscope; picture-in-picture"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Section>

      {props.app.cookieAccepted === 1 ? (null
        /*<Section
          title={PORTFOLIO_PAGE.sections["clutch"].title}
          lead={PORTFOLIO_PAGE.sections["clutch"].lead}
          items={[]}
          body={null}
          bgDark={false}
          callout={null}
          id={"clutch"}
        >
          <div className="section-title clearfix medium text-center">
            <h3 className="heading" />
            <div className="row clutch-block">
              <div
                className="clutch-widget"
                data-url="https://widget.clutch.co"
                data-widget-type="4"
                data-expandifr="true"
                data-height="auto"
                data-snippets="true"
                data-clutchcompany-id="725750"
              />
            </div>
          </div>
        </Section>*/
      ) : null}
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  staticContent: state.staticContent,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortfolioIndexPage));
