const initialState = {
  model: null,
  add: false,
  addSuccess: false,
  addError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  imageUpload: false,
  imageUploadSuccess: false,
  imageUploadError: null,

  changeBody: null
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "COMMENT_ADD":
      return {
        ...state,

        add: true,
        addSuccess: false,
        addError: null
      };

    case "COMMENT_ADD_REJECTED":
      return {
        ...state,
        add: false,
        addError: action.payload
      };

    case "COMMENT_ADD_FULFILLED":
      return {
        ...state,

        add: false,
        addSuccess: true
      };

    case "COMMENT_IMAGE_UPLOAD":
      return {
        ...state,
        imageUpload: true,
        imageUploadSuccess: false,
        imageUploadError: null,
        changeBody: null
      };

    case "COMMENT_IMAGE_UPLOAD_FULFILLED":
      return {
        ...state,

        changeBody: {
          text:
            action.changeData.front +
            "![" +
            action.payload.filename +
            "](" +
            action.payload.thumb_md_path +
            ")" +
            action.changeData.back,
          cursorPosition:
            action.changeData.cursorPosition +
            action.payload.filename.length +
            5 +
            action.payload.thumb_md_path.length
        },
        imageUpload: false,
        imageUploadSuccess: true
      };

    case "COMMENT_IMAGE_UPLOAD_REJECTED":
      return {
        ...state,
        imageUpload: false,
        imageUploadError: action.payload,
        postState: {
          ...initialState
        }
      };

    case "COMMENT_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null
      };

    case "COMMENT_EDIT_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true
      };

    case "COMMENT_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload
      };

    default:
      return state;
  }
}
