/**
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {clearStructuredHead, jsonToHead} from './Schema';
import {getOrigin} from '../SeoBlock';
import {createOrganizationSchema} from './Organization';

/**
 *
 * @param org
 * @param socials
 * @returns {{legalName: *, foundingDate: string, address: {'@type': string}, contactPoint: {'@type': string}[], '@type': string, name: *, logo: *, alternateName: *, '@context': string, url: string, sameAs: *}}
 */
const createWebsiteSchema = (name, url, publish_date, org, socials) => {
  return {
    "@context": "http://schema.org",
    "@type": "WebPage",
    inLanguage: "en-GB",
    name: name,
    url: getOrigin() + url,
    datePublished: moment(publish_date * 1000).format("YYYY-MM-DD"),
    isPartOf: {
      "@type": "WebSite",
      url: getOrigin(),
      name: "The Beaverhead",
      publisher: createOrganizationSchema(org, socials),
    },
  };
};

const Website = (props) => {
  useEffect(() => {
    if (props.clear) {
      clearStructuredHead();
    }

    jsonToHead(
      createWebsiteSchema(
        props.name,
        props.url,
        props.publish_date,
        props.org,
        props.socials
      )
    );
  }, []);

  return null;
};

Website.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  publish_date: PropTypes.number.isRequired,
  org: PropTypes.object.isRequired,
  socials: PropTypes.array.isRequired,
};

export default Website;
