/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2019.
 */

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";
import { formatError } from "./../tools";

import emailValidator from "email-validator";

import Indicator from "./../components/Indicator";
import Organization from "./components/Schemas/Organization";

import SeoBlock from "./components/SeoBlock";
import { withRouter } from "react-router-dom";
import { contactFormSubmit } from "../actions/contactform";

const ContactPage = (props) => {
  const [newsletterState, setNewsletterState] = useState({
    nameError: null,
    emailError: null,
    consentError: null,
    messageError: null,
  });

  /**
   *
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    const email = document.querySelector("#contactEmail").value;
    const name = document.querySelector("#contactName").value;
    const message = document.querySelector("#contactMessage").value;
    const tel = document.querySelector("#contactTel").value;
    const consent = document.querySelector("#contactConsent").checked;

    let emailError = null;
    let nameError = null;
    let messageError = null;
    let consentError = null;

    if (!email || !emailValidator.validate(email)) {
      emailError = "Please provide your email address.";
    }

    if (!name || name.length < 2) {
      nameError = "What is your name?";
    }

    if (!message || message.length < 2) {
      messageError = "We'd like to learn more.";
    }

    if (!consent) {
      consentError = "We'd like to learn more.";
    }

    setNewsletterState({ emailError, nameError, messageError, consentError });

    if (!emailError && !nameError && !messageError) {
      const data = {
        email,
        name,
        message,
        tel,
      };

      await props.contactFormSubmit(data);
    }
  };

  /**
   *
   * @param e
   */
  const onEmailBlur = (e) => {
    if (nameRef.current.value == "") {
      if (e.target.value.length) {
        const emailParts = e.target.value.split("@");
        const emailUserName = emailParts[0];

        const emailUserNameParts = emailUserName.split(/[\._-]/);

        nameRef.current.value = emailUserNameParts.join(" ");
      }
    }
  };

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const { CONTACT, ORG, SOCIALS } = props.staticContent.model;
  const contactForm = props.contactForm;
  const formDisabled = contactForm.submitLoading;

  const submitButton = formDisabled ? (
    <div className="contact-submit">
      <Indicator size={32} />
    </div>
  ) : (
    <input
      disabled={formDisabled}
      onClick={onSubmit}
      aria-label="Send message to The Beaverhead"
      type="submit"
      value="Send"
      className=""
    />
  );

  let submitContent = submitButton;

  if (contactForm.submitSuccess) {
    submitContent = (
      <div className="mail-sent-ok" role="alert">
        Your message was sent successfully. We will reach you shortly.
      </div>
    );
  } else if (contactForm.submitError) {
    submitContent = (
      <div>
        {submitButton}
        <div className="email-sent-errors" role="alert">
          {formatError(
            contactForm.submitError,
            "Could Not send your message. Please try again."
          )}
        </div>
      </div>
    );
  }

  const emailRef = useRef(null);
  const nameRef = useRef(null);

  return (
    <PageTemplate>
      <Organization socials={SOCIALS} org={ORG} />

      <SeoBlock
        title="Contact | The Beaverhead"
        description="Contact us directly with embedded chat, contact for or call our mobile."
        keywords={[
          "api integration",
          "london software house",
          "pwa",
          "spa",
          "amp",
        ]}
      />

      <div
        className="widget layers-slider-widget contact row slide swiper-container   full-width slider-layout-fullwidth not-full-screen swiper-container-horizontal swiper-container-fade loaded"
        style={{
          backgroundImage: "url('/n/content/cover_2.png')",
        }}
      >
        <div className="swiper-wrapper">
          <div className="swiper-slide invert has-image image-top text-left swiper-slide-duplicate swiper-centered-content">
            <div className="overlay content" />
          </div>
        </div>
      </div>

      <div
        id="layers-widget-column-25"
        className="widget layers-content-widget content-vertical-massive   "
      >
        <div className="container list-grid">
          <div className="grid">
            <div
              id="layers-widget-column-25-154"
              className="layers-masonry-column layers-widget-column-154 span-9  first  column "
            >
              <div className="media image-top large">
                <div className="media-body text-left">
                  <h5 className="heading">How can we help you?</h5>
                  <div className="span-7">
                    <p>
                      {/*Could your operations use some streamlining? Does your
                      team have to do a lot of repetitive tasks? Or do you have
                      an idea about something that can really help your business
                      grow but you are not sure how to approach it?
                      <br />
                      <br />*/}
                      Drop us a message below and we’ll be in touch to
                      discuss how we can help your business!
                      <br />
                    </p>
                    <p>&nbsp;</p>
                  </div>
                  <div className="excerpt">
                    <div role="form" className="wpcf7" lang="en-US" dir="ltr">
                      <div className="screen-reader-response" />
                      <form
                        action="/"
                        method="post"
                        className="wpcf7-form"
                        noValidate="novalidate"
                      >
                        <p>
                          <br />
                          <span className="wpcf7-form-control-wrap your-email">
                            <input
                              ref={emailRef}
                              type="email"
                              name="contact-email"
                              placeholder="Your Email (required)"
                              id="contactEmail"
                              disabled={formDisabled}
                              aria-label="contact form, email address"
                              size="40"
                              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                              aria-required="true"
                              aria-invalid="false"
                              required
                              onBlur={onEmailBlur}
                            />
                            {newsletterState.emailError ? (
                              <span role="alert" className="not-valid-tip">
                                Please fill in the required field.
                              </span>
                            ) : null}
                          </span>
                        </p>

                        <p>
                          <br />
                          <span className="wpcf7-form-control-wrap your-name">
                            <input
                              ref={nameRef}
                              type="text"
                              name="contact-name"
                              id="contactName"
                              placeholder="Your Name (required)"
                              disabled={formDisabled}
                              aria-label="contact form, name"
                              size="40"
                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required text-capitalize"
                              aria-required="true"
                              aria-invalid="false"
                              required
                            />
                            {newsletterState.nameError ? (
                              <span role="alert" className="not-valid-tip">
                                Please fill in the required field.
                              </span>
                            ) : null}
                          </span>
                        </p>

                        <p>
                          <br />
                          <span className="wpcf7-form-control-wrap your-name">
                            <input
                              type="tel"
                              name="contact-tel"
                              id="contactTel"
                              placeholder="Phone number"
                              disabled={formDisabled}
                              aria-label="contact form, telephone number"
                              size="40"
                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                            />
                            {newsletterState.nameError ? (
                              <span role="alert" className="not-valid-tip">
                                Please fill in the required field.
                              </span>
                            ) : null}
                          </span>
                        </p>

                        <p>
                          <br />
                          <span className="wpcf7-form-control-wrap your-message">
                            <textarea
                              name="contact-message"
                              id="contactMessage"
                              placeholder="Your Message (required)"
                              disabled={formDisabled}
                              aria-label="contact form, your message"
                              cols="40"
                              rows="10"
                              className="wpcf7-form-control wpcf7-textarea"
                              aria-invalid="false"
                            />
                            {newsletterState.messageError ? (
                              <span role="alert" className="not-valid-tip">
                                Please fill in the required field.
                              </span>
                            ) : null}
                          </span>
                        </p>

                        <p>
                          <br />
                          <span className="wpcf7-form-control-wrap your-message">
                            <input
                              type="checkbox"
                              name="contact-message"
                              id="contactConsent"
                              disabled={formDisabled}
                              aria-label="contact form, your message"
                              className="wpcf7-form-control wpcf7-textarea"
                              aria-invalid="false"
                            />
                            <label htmlFor="contactConsent">
                              The Beaverhead will use the information you
                              provide on this form to be in touch with you and
                              to provide updates and marketing.
                            </label>
                            {newsletterState.consentError ? (
                              <span role="alert" className="not-valid-tip">
                                We cannot process your data without your
                                permission.
                              </span>
                            ) : null}
                          </span>
                        </p>
                        <p></p>
                        {submitContent}
                        <div className="wpcf7-response-output wpcf7-display-none" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="layers-widget-column-25-981"
              className="layers-masonry-column layers-widget-column-981 span-3  last  column  has-image"
            >
              {/*<div className="media image-top medium">
                <div className="media-image ">
                  <img
                    src="/n/content/contact/meet.jpg"
                    className="attachment-layers-landscape-medium size-layers-landscape-medium"
                    alt=""
                  />
                </div>

                <div className="media-body text-left">
                  <h5 className="heading">Meet with us!</h5>
                  <div className="excerpt">
                    <p>Are you in London? Let's meet!</p>
                  </div>
                </div>
              </div>

              <p>
                <br />
                <br />
                <br />
              </p>*/}
              {/*<div className="media image-top medium">
                <div className="media-image ">
                  <img
                    src="/n/content/contact/contact.jpg"
                    className="attachment-layers-landscape-medium size-layers-landscape-medium"
                    alt=""
                  />
                </div>

                <div className="media-body text-left">
                  <h5 className="heading">Contact us</h5>
                  <div className="excerpt">
                    {CONTACT.map((i, idx) => {
                      return (
                        <p key={"cft-" + idx}>
                          {i.label}:{" "}
                          <a href={i.url} title={i.title}>
                            {i.value}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  staticContent: state.staticContent,
  contactForm: state.contactForm,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({
  contactFormSubmit: async (data) => await dispatch(contactFormSubmit(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactPage));
