
/**
 *
 * @param error
 * @param defaultMessage
 * @returns {*}
 */
export function formatError(error, defaultMessage = "Error has occurred") {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && Array.isArray(data.error)) {
      let error = "";

      data.error.map(i => {
        if (i.messages && i.messages.length) {
          error += i.messages.join(", ") + ".\n";
        }
      });

      return error;
    }
    if (data.message) {
      return error.response.data.message;
    }
  } else if (error && error.message) {
    return error.message;
  }

  return defaultMessage;
}


/**
 *
 * @param json
 */
export function addCanonicalToHead() {
  /*
  <link rel="canonical" href="https://example.com/dresses/green-dresses" />
  */

  let el = document.createElement('link');
  el.rel = 'canonical';
  el.href = window.location.href;
  el.className = 'canonical';

  const canonicalUrls = document.getElementsByClassName('canonical');
  for (let i = canonicalUrls.length-1; i >= 0; --i) {
    canonicalUrls[i].remove();
  }

  document.querySelector('head').appendChild(el);

}



/**
 *
 * @param json
 */
export function addWebsiteStructuredData(title, slug) {

  let el = document.createElement('script');
  el.type = 'application/ld+json';
  el.text = JSON.stringify({
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": title + " - The Beaverhead",
    "url": "https://thebeaverhead.com/" + slug
  });

  el.className = "structured"
  document.querySelector('head').appendChild(el);

}


/**
 *
 * @param contentId
 * @param data
 *
 */
export function updateCaretPositionAfterPhotoUpload(contentId, data) {
  const txtarea = document.querySelector(contentId);
  /*

    let br =
      txtarea.selectionStart || txtarea.selectionStart == "0"
        ? "ff"
        : document.selection
        ? "ie"
        : false;
  */

  let scrollPos = txtarea.scrollTop;

  txtarea.value = data.text;
  let strPos = data.cursorPosition;

  txtarea.selectionStart = strPos;
  txtarea.selectionEnd = strPos;
  txtarea.focus();

  txtarea.scrollTop = scrollPos;
  txtarea.blur();
  txtarea.focus();
}

/***
 * Written by Uno Kim (http://djkeh.github.io)
 */
export let TabManager = {
  tabKey: 9, // This number means tab key ascii input.
  enableTab: function(textBox, keyEvent) {
    if (this.isTabKeyInput(keyEvent)) {
      // Put tab key into the current cursor(caret) position.
      this.insertTab(textBox);

      // Block(invalidate) actual tab key input by returning key event handler to false.
      this.blockKeyEvent(keyEvent);
    }
  },
  isTabKeyInput: function(keyEvent) {
    return keyEvent.keyCode === this.tabKey;
  },
  insertTab: function(textBox) {
    let pos = this.getCaretPosition(textBox);
    let preText = textBox.value.substring(0, pos);
    let postText = textBox.value.substring(pos, textBox.value.length);

    textBox.value = preText + "\t" + postText; // input tab key

    this.setCaretPosition(textBox, pos + 1);
  },
  setCaretPosition: function(item, pos) {
    // Firefox, Chrome, IE9~ Support
    if (item.setSelectionRange) {
      item.focus();
      item.setSelectionRange(pos, pos);
    }
    // ~IE9 Support
    else if (item.createTextRange) {
      let range = item.createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
  },
  getCaretPosition: function(item) {
    let caretPosition = 0;

    // Firefox, Chrome, IE9~ Support
    if (item.selectionStart || item.selectionStart === "0") {
      caretPosition = item.selectionStart;
    }
    // ~IE9 Support
    else if (document.selection) {
      item.focus();
      let sel = document.selection.createRange();
      sel.moveStart("character", -item.value.length);
      caretPosition = sel.text.length;
    }

    return caretPosition;
  },
  blockKeyEvent: function(keyEvent) {
    if (keyEvent.preventDefault) {
      keyEvent.preventDefault();
    } else {
      keyEvent.returnValue = false;
    }
  }
};


