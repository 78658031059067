/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";
import PageTemplate from "./components/PageTemplate";

import Portfolio from "./Home/Portfolio";
import FullOrganization from "./components/Schemas/FullOrganization";
import SeoBlock from "./components/SeoBlock";
import HeaderTransitions from "./Home/HeaderTransitions";
import ClientsCarousel from "../components/ClientsCarousel";
import Section from "./Services/Section";
import LeadSignup from "./components/LeadSignup";
import HowDoesItWork from "./Home/HowDoesItWork";
import classnames from "classnames";
import { history } from "../configureStore";
import Proof from "./Home/Proof";

const HomePage = (props) => {
  const {
    PORTFOLIO,
    HP_PROCESS,
    HP_CLIENTS,
    HP_SERVICES,
    ORG,
    MGMT,
    SOCIALS,
    HOME,
    SERVICES,
  } = props.staticContent.model;

  const services = HP_SERVICES.map((i, idx) => {
    return (
      <div
        key={"so-" + idx}
        className="layers-masonry-column layers-widget-column-367 span-4 first column "
      >
        <div className="media image-top medium">
          <div className="media-body text-center">
            <h5 className="heading ">
              <i className={"fa fa- fa-2x " + i.icon} />
              {i.title}{" "}
            </h5>
            <div className="excerpt">
              <p>{i.description}</p>
              <Link to={i.slug} className={"button"}>
                {i.btnTitle}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  });

  /*  const clients = HP_CLIENTS.map((i, idx) => {
    return (
      <div
        key={"hp-cl-" + idx}
        className="widget-post-carousel"
      >
        <div className="media image-top medium media-logotype">
          <div className="media-image media-image">
            <Link to={i.url} title={i.name}>
              <img
                src={i.logo}
                className="attachment-medium size-medium logotype"
                alt={i.name}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  });*/

  /**
   *
   */
  const onCTAClick = (e) => {
    e.preventDefault();
    history.push("/contact");

    return false;
  };

  const badges =
    HOME.badges && HOME.badges.length
      ? HOME.badges.map((i, idx) => {
          const badge = <img key={"hp-badge-" + idx} src={i.src} alt={i.alt} />;
          return i.url ? <Link to={i.url}>{badge}</Link> : badge;
        })
      : null;

  const aboutBlock = HOME.about ? (
    <div
      className="widget layers-content-widget content-vertical-massive"
      style={{
        backgroundImage: 'url("' + HOME.about.image.src + '")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="container list-grid">
        <div className="grid">
          <div className="layers-masonry-column span-6 column ">
            <div className="media image-top medium">
              <div className="media-image "></div>
            </div>
          </div>

          <div className="layers-masonry-column span-5 column has-image bgTransparent">
            <div className="media image-top large">
              <div className="media-body text-left">
                <h2>{HOME.about.title}</h2>
                {HOME.about.paragraphs.map((i, idx) => {
                  return <p key={"hpap-" + idx}>{i}</p>;
                })}
                <Link to={HOME.about.button.url} className={"button"}>
                  {HOME.about.button.label}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <PageTemplate className={"homepage"}>
      <FullOrganization
        org={ORG}
        socials={SOCIALS}
        founders={MGMT}
        clear={true}
      />

      <SeoBlock
        title="Boosting business performance through custom solutions | The Beaverhead"
        description="Put your company processes under control. Reduce risk and repetitive tasks."
      />

      <div
        id="section-"
        className="widget layers-slider-widget row  loaded"
        style={{
          backgroundImage: "url('/n/content/cover_1.png')",
        }}
      >
        <div className="invert has-image image-top text-left full-width">
          <div className="overlay content">
            <div className="container clearfix">
              <div className="copy-container main">
                <HeaderTransitions headers={ORG.homePage.headers} />
                <Section
                  /*title={SERVICES["how-we-can-help-you"].title}*/
                  lead={SERVICES["how-we-can-help-you"].lead}
                  items={SERVICES["how-we-can-help-you"].items.map((i) => {
                    return { ...i, description: "" };
                  })}
                  body={SERVICES["how-we-can-help-you"].body}
                  bgTransparent={true}
                  callout={{
                    button: { url: "/services", label: "Read more..." },
                  }}
                  id={"how-we-can-help-you"}
                />
                <div className={"badges"}>{badges}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Proof data={HOME.PROOF} />

      <div className="widget layers-content-widget content-vertical-massive bgdark ">
        <div className="container clearfix">
          <div className="section-title medium text-center clearfix">
            <h3 className="heading">We craft solutions</h3>
          </div>
        </div>
        <div className="container list-grid">
          <div className="grid">{services}</div>
        </div>
      </div>

      {props.app.cookieAccepted === 1 ? (
        <div
          className={classnames(
            "widget layers-content-widget content-vertical-massive ",
            { bgdark: false }
          )}
        >
          <div className="container clearfix">
            <div className="section-title clearfix medium text-center ">
              <h3 className="heading">What our clients say about us</h3>
              <div className="layers-masonry-column span-8 offset-left-2 column ">
                <div className="section-title clearfix medium text-center ">
                  <p>&nbsp;</p>
                  <div className={"yt-container"}>
                    <iframe
                      src="https://www.youtube.com/embed/sWdo5JqRFMk?controls=1"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>

                <button
                  aria-label="Send message to The Beaverhead"
                  type="button"
                  className="button"
                  value="Contact us!"
                  onClick={onCTAClick}
                >
                  Contact us!
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <HowDoesItWork bgDark={false} items={HP_PROCESS} />

      {/*
      <Portfolio
        id="section-products"
        title="Products"
        subtitle="Projects made from our initiative"
        projects={PROJECTS}
        slugPrefix="/products/"
      />*/}

      {/*

      <div
        className="widget layers-content-widget content-vertical-massive  bgdark "
        id={"section-clients"}
      >
        <div className="container clearfix">
          <div className="section-title clearfix medium text-center ">
            <h3 className="heading">Our clients </h3>
            <div className="excerpt">
              <p>They excel now</p>
            </div>
          </div>
        </div>
        <div className="container list-grid">
          <ClientsCarousel clients={HP_CLIENTS} />
        </div>
      </div>*/}
      <Portfolio
        id="section-portfolio"
        title="Portfolio"
        subtitle="Our clients projects"
        projects={PORTFOLIO}
        slugPrefix="/portfolio/"
      />

      {aboutBlock}
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  staticContent: state.staticContent,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomePage));
