/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/06/2019.
 */

import React from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";
import Section from "./Services/Section";
import SeoBlock from "./components/SeoBlock";
import FullOrganization from "./components/Schemas/FullOrganization";
import AboutBlock from "./About/AboutBlock";
import StoryRow from "./components/StoryRow";
import AboutParagraph from "./About/AboutParagraph";

const AboutPage = (props) => {
  const {
    ORG,
    SOCIALS,
    MGMT,
    ABOUT,
    STORY,
    TEAM_DESC,
  } = props.staticContent.model;
  return (
    <PageTemplate>
      <FullOrganization org={ORG} socials={SOCIALS} founders={MGMT} />

      <SeoBlock
        title="About The Beaverhead"
        description="The Beaverhead creates custom-made software for business. We harness reliable technologies to drive well-organized projects."
        keywords={[
          "custom made software",
          "software agency",
          "technologies",
          "mobile apps",
          "web apps",
          "start up",
        ]}
      />

      <AboutBlock blocks={ABOUT} />

      <div className="widget layers-content-widget content-vertical-massive  bgdark story">
        <div id="section-our-story" className="container list-grid">
          <Section lead={null} title={"Our Story"} bgDark={true} id={"our-story"}/>
          <div className="grid">
            <StoryRow title={""}>
              {STORY.chapters.map((i, idx) => {
                return (
                  <p key={"ausc-" + idx}>
                    <em>{i}</em>
                  </p>
                );
              })}
            </StoryRow>

            <StoryRow title={""}>
              {STORY.media.map((i, idx) => {
                if (i.video) {
                  return (
                    <React.Fragment key={"srv-" + idx}>
                      <video controls>
                        {i.video.sources.map((j, jdx) => (
                          <source key={"srv-" + j.src}
                            src={j.src} type={j.type} />
                        ))}
                        {i.video.placeholder}
                      </video>
                      <span className="picture-desc">{i.caption}</span>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={"srve-" + idx}>
                      <img

                        src={i.image.src}
                        alt={i.caption}
                        title={i.caption}
                      />
                      <span className="picture-desc">{i.caption}</span>
                    </React.Fragment>
                  );
                }
              })}
              {/**/}
            </StoryRow>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive story  ">
        <div id="section-team" className="container clearfix">
          <Section lead={null} title={"The team"} />
        </div>

        <div className="container clearfix">
          <AboutParagraph body={[TEAM_DESC]} />
        </div>

        <div className="container list-grid">
          <div className="grid">
            {MGMT.map((i, idx) => {
              return (
                <div
                  key={"tmc-" + idx}
                  className="layers-masonry-column  span-4  first  column  has-image"
                >
                  <div className="media image-top medium">
                    <div className=" ">
                      <img
                        width="500"
                        height="500"
                        src={i.avatar}
                        className="attachment-full size-full"
                        alt={i.name}
                      />
                    </div>

                    <div className="media-body text-left">
                      <h5 className="heading">{i.name}</h5>
                      <div className="excerpt">
                        <p>{i.position}</p>
                        <p>{i.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  staticContent: state.staticContent,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
