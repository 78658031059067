/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/02/2019.
 */

import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";

const Section = (props) => {
  const bodyContent = props.body ? (
    <div className="container list-grid">
      <div className="grid">
        {props.body.map((i, idx) => {
          return (
            <React.Fragment key={"sb-" + props.id + "-" + idx}>
              <div className={"span-1"}></div>
              <div className="layers-masonry-column span-10 span column ">
                <p dangerouslySetInnerHTML={{ __html: i }} />
              </div>
              <div className={"span-1"}></div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  ) : null;

  let calloutContent = null;

  if (props.callout) {
    if (props.callout.paragraph) {
      calloutContent = (
        <div className="grid">
          <div className={"span-6"}></div>
          <div className="layers-masonry-column span-6 span column ">
            <blockquote className={"services"}>
              <em>{props.callout.paragraph}</em>
            </blockquote>
          </div>
          <div className={"span-1"}></div>
        </div>
      );
    } else if (props.callout.cite) {
      calloutContent = (
        <div className="grid">
          <div className={"span-6"}></div>
          <div className="layers-masonry-column span-6 span column ">
            <blockquote className={"services"}>
              <em>"{props.callout.cite.quote}"</em>
              <span> - {props.callout.cite.signature}</span>
            </blockquote>
          </div>
          <div className={"span-1"}></div>
        </div>
      );
    } else if (props.callout.button) {
      calloutContent = (
        <div className="grid ">
          <div className="layers-masonry-column span-12 span column text-center">
            <Link to={props.callout.button.url} className={"button"}>
              {props.callout.button.label}
            </Link>
          </div>
        </div>
      );
    }
  }
  const callout = props.callout ? (
    <div className="container list-grid">{calloutContent}</div>
  ) : null;

  const items = props.items.map((i, idx) => {
    return (
      <div
        key={props.id + "-si-" + idx}
        className="layers-masonry-column span-4 column "
      >
        <div className="media image-top medium">
          <div className="media-body text-center">
            <h5 className="heading">
              <i className={"fa " + i.icon + " fa-2x"} /> <br /> {i.title}
            </h5>
            <div className="excerpt">
              <p>{i.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const itemsContent =
    props.items && props.items.length ? (
      <div className="container list-grid">
        <div className="grid">{items}</div>
      </div>
    ) : null;

  const heading =
    props.title || props.lead ? (
      <div className="container clearfix" id={"section-services/" + props.id}>
        <div className="section-title clearfix medium text-center ">
          <h3
            className="heading"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></h3>
          <div className="excerpt">
            <p>
              <em dangerouslySetInnerHTML={{ __html: props.lead }} />
            </p>
          </div>
        </div>
      </div>
    ) : null;

  return (
    <div
      className={classnames(
        "widget layers-content-widget content-vertical-massive ",
        { bgdark: props.bgDark, bgTransparent: props.bgTransparent }
      )}
    >
      {heading}
      {bodyContent}
      {itemsContent}
      {callout}
    </div>
  );
};

Section.propTypes = {
  bgDark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  items: PropTypes.array,
  body: PropTypes.array,
  callout: PropTypes.object,
  id: PropTypes.string.isRequired,
};

Section.defaultProps = {
  items: [],
  bgDark: false,
};

export default Section;
