/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/10/2020.
 */

import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import StoryRow from "../components/StoryRow";

const AboutGrid2 = (props) => {
  return (
    <div className="grid">
      <StoryRow title={props.block.title} size={12} />
      {props.block.grid_2.map((i, idx) => {
        return (
          <div
            key={"ag2c-" + idx}
            className={classnames("layers-masonry-column column span-6 grid2")}
          >
            <div className="media image-top medium">
              <div className="media-body text-left">
                <h4>{i.title}</h4>
                <div className="excerpt">{i.description}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

AboutGrid2.propTypes = {
  block: PropTypes.object.isRequired,
};

export default AboutGrid2;
