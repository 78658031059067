/**
 * Created by piotr.pozniak@thebeaverhead.com on 29/01/2020.
 */

import React from "react";

import { updateCaretPositionAfterPhotoUpload } from "../../../tools";
import CommentForm from "./CommentForm";
import { withRouter } from "react-router-dom";

class NewComment extends React.Component {
  /**
   *
   */
  onNewCommentSubmit = async () => {
    const data = {
      parent_slug: this.props.post.model.slug,
      body: document.querySelector("#newCommentText").value
    };

    await this.props.addComment(data);

    if (
      this.props.comment.addSuccess &&
      document.querySelector("#newCommentText")
    ) {
      document.querySelector("#newCommentText").value = "";
      document
        .getElementsByClassName("comments-container")[0]
        .children[0].scrollIntoView();
    }
  };

  /**
   *
   * @param uuid
   */
  onCommentSubmit(uuid) {
    const data = {
      body: document.querySelector("#editCommentText").value
    };

    this.props.commentActions.updateComment(uuid, data);
  }

  /**
   *
   * @param image
   * @param resource
   * @param changeData
   * @param uuid
   * @returns {Promise<null|*>}
   */
  onUploadImage = async (image, resource, changeData, uuid) => {
    await this.props.uploadCommentImage(image, resource, changeData, uuid);

    if (this.props.comment.changeBody) {
      updateCaretPositionAfterPhotoUpload.call(
        this,
        "#newCommentText",
        this.props.comment.changeBody
      );
    }
  };


  /**
   *
   * @param e
   */
  openLoginModal = e => {
    e.target.blur();
    this.props.history.push('/login')
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const postModel = this.props.post.model;

    const user = this.props.user.model;

    if (!postModel) {
      return null;
    }

    if (!user) {
      return (
        <div className="post__newComment--inactive col-xs-12">
          <h4>Your comment is most welcome</h4>
          <textarea
            placeholder="Leave your comment here. Share your ideas, experience and knowledge."
            onFocus={this.openLoginModal}
          />
        </div>
      );
    }

    return (
      <div className="post__newComment col-xs-12">
        <h4>Your comments are most welcome</h4>

        <CommentForm />
      </div>
    );
  }
}

export default withRouter(NewComment);
