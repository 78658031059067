/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/02/2018.
 * tbh -
 */

const initialState = {
  submitError: null,
  submitLoading: false,
  submitSuccess: false,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "CONTACTFORM_SUBMIT":
      return {
        ...initialState,
        submitLoading: true,
        submitSuccess: false,
        submitError: null,
      };

    case "CONTACTFORM_SUBMIT_REJECTED":
      return {
        ...state,
        submitLoading: false,
        submitError: action.payload,
      };

    case "CONTACTFORM_SUBMIT_FULFILLED":
      return {
        submitLoading: false,
        submitSuccess: true,
      };

    default:
      return state;
  }

  return state;
}
