const initialState = {
  collection: [],

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  hasMore: true
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {

  switch (action.type) {
    case "POSTS_FETCH":
      return {
        ...state,

        fetch: true,
        fetchSuccess: false,
        fetchError: null
      };

    case "POSTS_FETCH_REJECTED":
      return {
        ...state,
        hasMore: false,
        fetch: false,
        fetchError: action.payload
      };

    case "POSTS_FETCH_FULFILLED":

      return {
        ...state,

        collection: state.collection.concat(action.payload.posts),
        fetch: false,
        fetchSuccess: true
      };

    case "POSTS_CLEANUP_COLLECTION":
      return {
        ...initialState
      };

    default:
      return state;
  }


}
