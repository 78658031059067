/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2019.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";



/**
 *
 * @returns {Function}
 */
export const clearErrors = () => {
  return dispatch => {
    dispatch({ type: "CLEAR_ERRORS" });
  };
};


/**
 *
 * @returns {Function}
 */
export const acceptCookies = () => {
  return dispatch => {
    dispatch({ type: "APP_SET_COOKIE_ACCEPTED" });
  };
};
