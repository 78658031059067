/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2019.
 */

import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import PageTemplate from "./components/PageTemplate";
import { Link, withRouter } from "react-router-dom";
import Article from "./components/Schemas/Article";
import SeoBlock from "./components/SeoBlock";
//import Gallery from "../components/Gallery";
import Gallery from "react-grid-gallery";

class PortfolioPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNavigationArrows: true,
    };
  }

  showNavigationArrows = (show) => () => {
    this.setState({ showNavigationArrows: show });
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const {
      ORG,
      SOCIALS,
      PORTFOLIO,
      PROJECTS,
    } = this.props.staticContent.model;
    const slug = this.props.match.params.slug;

    const articles = PORTFOLIO.concat(PROJECTS);
    const articleIndex = articles.findIndex((i) => i.slug == slug);
    const article = articles[articleIndex];

    const nextArticle = articles[(articleIndex + 1) % articles.length];
    const prevArticle =
      articles[
        articleIndex == 0
          ? articles.length - 1
          : (articleIndex - 1) % articles.length
      ];

    const testimonial = article.testimonial ? (
      <blockquote>
        <p>{article.testimonial}</p>
      </blockquote>
    ) : null;

    const client = article.client ? (
      <div className="client-name">
        <span>Client:</span> {article.client}
      </div>
    ) : null;

    const featuredImage = article.featured ? (
      <img
        src={article.featured.src}
        className="attachment-full size-full wp-post-image"
        alt={article.featured.title}
      />
    ) : null;

    const articleSchema =
      article.client && article.slug.length ? (
        <Article
          org={ORG}
          socials={SOCIALS}
          slugPrefix={this.props.path}
          article={article}
          clear={true}
          lastModified={this.props.staticContent.lastModified}
        />
      ) : null;

    const articleImages = (article.images || []).map((i) => {
      return {
        src: i.src,
        thumbnail: i.src,
        thumbnailWidth: 400,
        thumbnailHeight: 400,
      };
    });

    const link = article.link ? (
      <div className="client-desc">
        <span>Link:</span>{" "}
        <a
          href={article.link}
          target="_blank"
          title={article.name}
          alt={article.name}
        >
          {article.link}
        </a>
      </div>
    ) : null;

    const path = window.location.pathname.split("/")[1];

    const navigationArrows = this.state.showNavigationArrows ? (
      <div className="futr-single-nav">
        <div className="single-previous arrows left">
          <Link to={"/" + path + "/" + prevArticle.slug}>
            {" "}
            <i className="fa fa-angle-left" />{" "}
          </Link>
        </div>

        <div className="single-next arrows right">
          <Link to={"/" + path + "/" + nextArticle.slug}>
            {" "}
            <i className="fa fa-angle-right" />
          </Link>
        </div>
      </div>
    ) : null;

    return (
      <PageTemplate>
        {articleSchema}
        <SeoBlock
          title={article.seoName}
          description={article.metaDescription}
          keywords={article.tags.concat(article.technologies)}
          image={article.featured ? article.featured.src : null}
        />

        <section className="clearfix with-sidebar futr_portfolio type-futr_portfolio status-publish has-post-thumbnail hentry futr-portfolio-categories-identity container">
          <div className="row">
            <article>
              <header className="section-title large">
                <h1 className="heading">{article.title}</h1>
              </header>

              {featuredImage}

              <div className="story portfolio">
                <p className="lead">
                  <i>{article.lead}</i>
                </p>
                <div className={"gallery-wrapper"}>
                  <Gallery
                    images={articleImages.splice(0, 1)}
                    enableImageSelection={false}
                    showImageCount={false}
                    backdropClosesModal={true}
                    lightboxWidth={1600}
                    lightboxWillOpen={this.showNavigationArrows(false)}
                    lightboxWillClose={this.showNavigationArrows(true)}
                  />
                </div>

                {article.p && article.p[0] ? (
                  <p dangerouslySetInnerHTML={{ __html: article.p[0] }} />
                ) : null}

                {testimonial}

                {article.p.map((i, idx) => {
                  if (!idx) {
                    return null;
                  }

                  return (
                    <p
                      key={"opi-" + i}
                      dangerouslySetInnerHTML={{ __html: i }}
                    />
                  );
                })}

                <div className={"gallery-wrapper"}>
                  <Gallery
                    images={articleImages}
                    enableImageSelection={false}
                    showImageCount={false}
                    backdropClosesModal={true}
                    lightboxWidth={1600}
                    lightboxWillOpen={this.showNavigationArrows(false)}
                    lightboxWillClose={this.showNavigationArrows(true)}
                  />
                </div>
                {/*<Gallery
                  images={articleImages.slice(1)}
                  enableImageSelection={false}
                  showImageCount={false}
                />*/}
              </div>

              <div id="comments" className="push-top-large" />

              {navigationArrows}
            </article>

            <div className="project-sidebar static">
              <div className="project-side-inner">
                <div className={"portfolio-meta"}>
                  <div className="client-logo">
                    {article.logo ? <img src={article.logo} /> : null}
                  </div>

                  <div>
                    {client}

                    {link}
                    <div className="client-desc">
                      <span>Technologies:</span>{" "}
                      {article.technologies.join(", ")}
                    </div>
                    <div className="client-desc">
                      <span>Platform:</span> {article.platform.join(", ")}
                    </div>
                    <div className="client-desc">
                      {" "}
                      <span>Year:</span> {article.year}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  staticContent: state.staticContent,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortfolioPage));
