/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/01/2019.
 */

import React from "react";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import Indicator from "./../components/Indicator";
import ImageUploadedTick from "./../components/ImageUploadedTick";
import { fetchUser } from "../actions/profile";
import {
  adminDeleteUser,
  deleteUser,
  updateUser,
  uploadAvatar
} from "../actions/user";
import PageTemplate from "./components/PageTemplate";
import AxiosConfig from "../AxiosConfig";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false
    };
  }

  /**
   *
   */
  componentDidMount() {
    this.props.fetchUser(this.props.match.params.userSlug);
  }

  /**
   *
   * @returns {{nickname: (*|string)}}
   */
  onSubmit = async () => {
    const data = {
      name: document.querySelector("#editUserName").value,
      email: document.querySelector("#editUserEmail").value,
      bio: document.querySelector("#editUserBio").value
    };

    await this.props.updateUser(this.props.profile.model.slug, data);

    if (this.props.profile.editSuccess) {
      if (this.props.match.params.userSlug !== this.props.profile.model.slug) {
        this.props.history.replace("/profile/" + this.props.profile.model.slug);
      }
      this.setState({ editMode: false });
    }
  };

  /**
   *
   * @param e
   */
  onClickDelete = async e => {
    const profile = this.props.profile;
    const user = this.props.user.model;
    const isAdmin = user && user.role === "admin";

    let deleteConfirmMessage = "Do you really want to delete your account?";
    let deleteFunction = this.props.deleteUser;
    let deleteParam = "slug";

    if (profile.delete) {
      return null;
    }

    if (isAdmin && profile.email) {
      deleteConfirmMessage =
        "Do you really want to delete the account of user " +
        profile.name +
        "?";
      deleteFunction = this.props.adminDeleteUser;
      deleteParam = "email";
    }

    if (window.confirm(deleteConfirmMessage)) {
      await deleteFunction(profile.model[deleteParam]);
    }
  };

  /**
   *
   * @param e
   * @returns {Promise<void>}
   */
  onUploadAvatar = async e => {
    if (e.target.files[0]) {
      await this.props.uploadAvatar(
        this.props.user.model.slug,
        e.target.files[0]
      );
    }
  };

  /**
   *
   * @param turnOn
   * @returns {function(...[*]=)}
   */
  switchEditMode = turnOn => () => {
    this.setState({ editMode: turnOn });
  };

  /**
   *
   * @returns {*}
   */
  onClickChangeAvatar = () => document.querySelector("#avatar-input").click();

  render() {
    const userSlug = this.props.match.params.userSlug;
    const user = this.props.user.model;
    const profile = this.props.profile;
    const profileModel = this.props.profile.model;
    const isOwner = user && (user.role === "admin" || user.slug === userSlug);

    const anchorDisabled =
      profile.edit || profile.delete || profile.avatarUpload;

    const imageUploadedTick = profile.avatarUploadSuccess ? (
      <ImageUploadedTick />
    ) : null;

    const deleteEmailSent = profile.deleteLinkSent ? (
      <span className="alert ">Check your inbox to find a deletion link</span>
    ) : null;

    const currentImage =
      !profile.avatarUpload && profileModel ? (
        <img
          className={profile.avatarUploadSuccess ? "present-image" : ""}
          src={
            AxiosConfig.getEndpointAddress() + "/avatars/" + profileModel.avatar
          }
          alt={profileModel.name}
        />
      ) : (
        <div className="profile__avatar-indicator">
          <Indicator size={40} />
        </div>
      );

    /*
    const changePassword = isOwner ? (
      <Link to="/password-recovery" className={anchorDisabled ? "hidden" : ""}>
        Change password
      </Link>
    ) : null;
*/

    const changeAvatar = isOwner ? (
      <div>
        <span
          className={anchorDisabled ? "hidden" : "anchor"}
          onClick={this.onClickChangeAvatar}
        >
          Change avatar
        </span>
        <input
          id="avatar-input"
          type="file"
          className="hidden"
          accept="image/*"
          onChange={this.onUploadAvatar}
        />
      </div>
    ) : null;

    const avatarBlock = profileModel ? (
      <div className="col-xs-12 col-sm-2">
        <div className="profile__avatar-block row">
          <div className="profile__avatar-image">{currentImage}</div>
          {imageUploadedTick}
          <div className="profile__avatar-actions">
            {/*{changePassword}*/}
            {changeAvatar}
          </div>
        </div>
      </div>
    ) : null;

    const editControls =
      profileModel && isOwner && !profile.avatarUpload ? (
        <div className="profile__edit-controls">
          <i className="material-icons" onClick={this.switchEditMode(true)}>
            mode_edit
          </i>
          <i className="material-icons" onClick={this.onClickDelete}>
            delete
          </i>
        </div>
      ) : null;

    const name = profileModel ? (
      <div className="profile__content-heading">
        <span className="profile__content-username">{profileModel.name}</span>
        {editControls}
        {deleteEmailSent}
      </div>
    ) : null;

    const nameWrap =
      isOwner && this.state.editMode ? (
        <div className="profile__content-heading">
          <label htmlFor="editUserName">User name visible to others</label>
          <input
            className="form-control default-input"
            id="editUserName"
            type="text"
            autoFocus={true}
            placeholder="Your user name"
            defaultValue={profileModel.name}
          />
        </div>
      ) : (
        name
      );

    const email =
      profileModel && isOwner ? (
        <div className="profile__content-row">
          <i className="material-icons">email</i>
          <span>{profileModel.email}</span>
        </div>
      ) : null;

    const emailWrap =
      isOwner && this.state.editMode ? (
        <div className="profileModel__content-row">
          <label htmlFor="editUserEmail">
            Your email (invisible to others)
          </label>
          <input
            className="form-control default-input"
            id="editUserEmail"
            type="email"
            placeholder="Email address"
            defaultValue={profileModel.email}
          />
        </div>
      ) : (
        email
      );

    const bio = profileModel ? (
      <div className="profile__content-row">
        <i className="material-icons">info</i>
        <span>
          {profileModel.bio
            ? profileModel.bio
            : "User hasn't written anything about him yet..."}
        </span>
      </div>
    ) : null;

    const bioWrap =
      isOwner && this.state.editMode ? (
        <div className="profile__content-row">
          <label htmlFor="editUserBio">Introduce yourself to others ;)</label>
          <textarea
            className="form-control default-textarea"
            id="editUserBio"
            placeholder="What's your favorite programming language? How do you spend your free time? What's your best time on 50m freestyle?"
            defaultValue={profileModel.bio}
            onKeyDown={keyEvent => {
              // let textBox = document.getElementById('editUserBio');
              // TabManager.enableTab(textBox, keyEvent);
            }}
          />
        </div>
      ) : (
        bio
      );

    const editActionButtons =
      profileModel && this.state.editMode ? (
        <div className="profile__edit-buttons">
          <button
            type="button"
            className="btn btn-success"
            onClick={this.onSubmit}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.switchEditMode(false)}
          >
            Cancel
          </button>
        </div>
      ) : null;

    const errorWrap =
      profile.fetchError ||
      profile.editError ||
      profile.deleteError ||
      profile.avatarUploadError ? (
        <div className="profile__error-wrap">
          <span className="alert">
            Some error has occurred. Please try again.
          </span>
        </div>
      ) : null;

    const contentBlock =
      !profile.edit && !profile.delete && !profile.fetch ? (
        <div className="col-xs-12 col-sm-10 col-md-9 col-lg-8">
          <div className="profile__content-block">
            {nameWrap}
            {emailWrap}
            {bioWrap}
            {editActionButtons}
            {errorWrap}
          </div>
        </div>
      ) : (
        <div className="profile__content-loading-indicator">
          <Indicator size={40} />
        </div>
      );

    return (
      <PageTemplate>
        <div className="profile container">
          <div className="row">
            {avatarBlock}
            {contentBlock}
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = store => ({
  user: store.user,
  profile: store.profile
});

const mapDispatchToProps = dispatch => ({
  fetchUser: slug => {
    dispatch(fetchUser(slug));
  },
  uploadAvatar: async (slug, image) => {
    await dispatch(uploadAvatar(slug, image));
  },
  updateUser: async (slug, data) => {
    await dispatch(updateUser(slug, data));
  },
  deleteUser: async slug => {
    await dispatch(deleteUser(slug));
  },
  adminDeleteUser: async slug => {
    await dispatch(adminDeleteUser(slug));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfilePage));
