/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/02/2018.
 * tbh -
 */

const initialState = {
  model: null,
  fetchError: null,
  fetch: false,
  fetchSuccess: false,

};

export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {
    case "APP_FETCH_STORE":
      return {
        fetch: true,
        fetchSuccess: false,
        fetchError: null
      };

    case "APP_FETCH_STORE_REJECTED":
      return {
        ...state,
        state: {
          ...initialState,
          fetch: false,
          fetchError: action.payload
        }
      };

    case "APP_FETCH_STORE_FULFILLED":
      return {
        model: action.payload.data,
        lastModified: action.payload.lastModified,
        fetch: false,
        fetchSuccess: true,
      };

    default:
      return state;

  }

  return state;
}
