import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const addComment = data => {
  return async dispatch => {
    dispatch({
      type: "COMMENT_ADD",
      parentSlug: data.parent_slug
    });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/comments.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "COMMENT_ADD_FULFILLED",
          payload: response.data,
          parentSlug: data.parent_slug
        });
      })
      .catch(function(error) {
        dispatch({
          type: "COMMENT_ADD_REJECTED",
          payload: error,
          parentSlug: data.parent_slug
        });
      });
  };
};

/**
 *
 * @param file
 * @param resource
 * @param changeData
 * @param uuid
 * @returns {Function}
 */
export const uploadCommentImage = (file, resource, changeData, uuid) => {
  return async dispatch => {
    let data = new FormData();
    data.append("file", file);
    data.append("resource", resource);

    if (uuid) {
      data.append("uuid", uuid);
    }

    dispatch({
      type: "COMMENT_IMAGE_UPLOAD",
      uuid
    });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/images.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "COMMENT_IMAGE_UPLOAD_FULFILLED",
          payload: response.data,
          changeData: changeData,
          uuid: uuid
        });
      })
      .catch(function(error) {
        dispatch({
          type: "COMMENT_IMAGE_UPLOAD_REJECTED",
          payload: error,
          uuid: uuid
        });
      });
  };
};

/**
 *
 * @param uuid
 * @param data
 * @returns {Function}
 */
export const updateComment = (uuid, data) => {
  return async dispatch => {
    dispatch({
      type: "COMMENT_EDIT",
      uuid: uuid
    });

    await axios
      .put(
        AxiosConfig.getEndpointAddress() + "/api/comments/" + uuid + ".json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch(
          {
            type: "COMMENT_EDIT_FULFILLED",
            payload: response.data,
            uuid: uuid
          }
        );
      })
      .catch(function(error) {
        dispatch({
          type: "COMMENT_EDIT_REJECTED",
          payload: error,
          uuid: uuid
        });
      });
  };
};

/**
 *
 * @param uuid
 * @returns {function(...[*]=)}
 */
export const deleteComment = uuid => {
  return async dispatch => {
    dispatch({
      type: "COMMENT_DELETE",
      uuid: uuid
    });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() + "/api/comments/" + uuid + ".json",
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "COMMENT_DELETE_FULFILLED",
          payload: response.data,
          uuid: uuid
        });
      })
      .catch(function(error) {
        dispatch({
          type: "COMMENT_DELETE_REJECTED",
          payload: error,
          uuid: uuid
        });
      });
  };
};

/**
 *
 * @param uuid
 * @param type
 * @returns {Function}
 */
export function voteComment(uuid, type) {
  return function(dispatch) {
    dispatch({
      type: "COMMENT_VOTE",
      uuid: uuid
    });

    axios
      .post("/api/comments/vote/" + uuid + "/" + type + ".json")
      .then(response => {
        dispatch({
          type: "COMMENT_VOTE_FULFILLED",
          payload: response.data,
          uuid: uuid
        });
      })
      .catch(function(error) {
        dispatch({
          type: "COMMENT_VOTE_REJECTED",
          payload: error,
          uuid: uuid
        });
      });
  };
}
