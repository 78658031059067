import React from "react";
import { Route, Switch, withRouter, matchPath } from "react-router-dom";

/*

import PageHeader from "./components/PageHeader";
import Footer from "./components/Footer";
 */

import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import TagPage from "./pages/TagPage";
import ProfilePage from "./pages/ProfilePage";
import PostPage from "./pages/PostPage";
import NotFoundPage from "./pages/NotFoundPage";
import TestimonialsPage from "./pages/TestimonialsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import PortfolioPage from "./pages/PortfolioPage";
import ContactPage from "./pages/ContactPage";
import EguideSpreadsheetsPage from "./pages/EguideSpreadsheetsPage";
import BLPCaseStudyPage from "./pages/BLPCaseStudyPage";
import ServicesPage from "./pages/ServicesPage";
import PortfolioIndexPage from "./pages/PortfolioIndexPage";
import PDFExtractorTool from "./pages/PDFExtractorTool";
/*
import StaticPage from "./pages/StaticPage/StaticPage";
import NewPost from "./pages/NewPost/NewPost";
import Profile from "./pages/Profile/Profile";
import AdminUsers from "./pages/Admin/Users/AdminUsers";
import AdminUserEdit from "./pages/Admin/Users/EditUser";
import AdminNewsletter from "./pages/Admin/Newsletter/NewsletterSubscriptions";
*/

const MainRoutesComponent = (props) => {
  const previousMainComponentLocation = props.location;

  /**
     *
     * @param nextProps
     * @param nextState
     * @returns {boolean}

    shouldComponentUpdate(nextProps, nextState) {


        if (this.props.location.pathname !== nextProps.location.pathname) {

            // if next location is one of the modals with static path
            const modalLinkIndex = this.props.modalLinks.findIndex((modalLink) => {
                const match = matchPath(nextProps.location.pathname, {
                    path: modalLink,
                    exact: true,
                    strict: false
                });
                return match !== null;
            });

            if (modalLinkIndex >= 0) {
                return false;
            }
            else if (this.previousMainComponentLocation.pathname === nextProps.location.pathname) {
                return false;
            }
            else {
                this.previousMainComponentLocation = nextProps.location;
                return true;
            }
        }
        else {
            return false;
        }
    }
     */

  return (
    <Switch location={previousMainComponentLocation}>
      <Route exact path="/" component={HomePage} />
      {/*<Route exact path="/about" component={AboutPage} />*/}
      {/*<Route exact path="/team" component={AboutPage} />*/}
      {/*<Route exact path="/our-story" component={AboutPage} />*/}

      <Route exact path="/portfolio" component={PortfolioIndexPage} />
      <Route
        exact
        path="/portfolio/solutions-we-have-built"
        component={PortfolioIndexPage}
      />
      <Route exact path="/portfolio/clients" component={PortfolioIndexPage} />
      <Route
        exact
        path="/portfolio/testimonials"
        component={PortfolioIndexPage}
      />
      <Route exact path="/portfolio/clutch" component={PortfolioIndexPage} />
      <Route exact path="/portfolio/:slug" component={PortfolioPage} />

      <Route exact path="/services" component={ServicesPage} />
      {/*<Route
        exact
        path="/services/how-we-can-help-you"
        component={ServicesPage}
      />*/}
      {/*<Route exact path="/services/what-we-do" component={ServicesPage} />
      <Route exact path="/services/custom-software" component={ServicesPage} />
      <Route
        exact
        path="/services/system-integrations"
        component={ServicesPage}
      />*/}
{/*
      <Route exact path="/services/technologies" component={ServicesPage} />
*/}

      {/*<Route
          exact
          path="/services/software-development"
          component={SoftwareDevelopmentPage}
        />
        <Route
          exact
          path="/services/business-applications"
          component={BusinessAppsPage}
        />
        <Route
          exact
          path="/services/custom-crm-erp-pm"
          component={CustomCRMPage}
        />
        <Route
          exact
          path="/services/api-integration"
          component={SystemIntegrationsPage}
        />*/}
      <Route exact path="/testimonials" component={TestimonialsPage} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />

      <Route path="/blog/:tagSlug?" component={TagPage} />
      <Route path="/profile/:userSlug" component={ProfilePage} />
      <Route path="/post/:postSlug" component={PostPage} />

      {/*<Route
        exact
        path="/e-guides/6+plus+1+ways+to+optimise+your+business+operation+spreadsheets"
        component={EguideSpreadsheetsPage}
      />*/}
      {/*<Route
        exact
        path="/case-studies/unique-tracking-solution-for-big-lazy-panda"
        component={BLPCaseStudyPage}
      />*/}
{/*

      <Route
        exact
        path="/tools/pdf-bank-statement-data-extractor"
        component={PDFExtractorTool}
      />
*/}

      {/*<Route path="/static/:postSlug" component={StaticPage}/>*/}
      {/*<ScrollToTopRoute path="/new-static-page" exact component={NewStaticPageComponent}/>

        <ScrollToTopRoute path="/static/:slug" exact component={StaticPageComponent}/>
        <ScrollToTopRoute path="/admin/users" exact component={AdminUsersComponent}/>
        <ScrollToTopRoute path="/admin/user/:slug" exact component={AdminUserEditComponent}/>
        <ScrollToTopRoute path="/admin/newsletter-subscriptions" exact component={AdminNewsletterComponent}/>*/}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default withRouter(MainRoutesComponent);
