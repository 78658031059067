/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/02/2020.
 * eneurs -
 */

import React, {useEffect} from "react";
import { acceptCookies } from "../actions/app";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";


const CookiePopup = (props) => {
  /**
   *
   */
  const onAcceptCookie = () => {

    props.acceptCookies();



  };

  useEffect(() => {
    if (props.app.cookieAccepted === 1) {
      const script = document.createElement("script");
      script.src = "/js/autotrack.js";
      script.async = true;
      script.id = "autotrack";

      document.body.appendChild(script);


      const gascript = document.createElement("script");
      gascript.innerHTML = "  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n" +
        "      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n" +
        "      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n" +
        "    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\n" +
        "\n" +
        "      ga('create', 'UA-56974116-1', 'auto');\n" +
        "      ga('send', 'pageview');";
      gascript.async = true;
      gascript.id = "ga-autotrack";

      document.body.appendChild(gascript);
    }
  }, [props.app.cookieAccepted])

  return (
    <div
      id="swUpdateToast"
      className={
        "page__update-notification-wrap" +
        (props.app.cookieAccepted !== 1? " show" : "")
      }
    >
      <p>
        Our website uses cookies. By using our website you agree to our{" "}
        <Link to="/privacy-policy" rel="nofollow" target="_blank">
          privacy policy
        </Link>
        .
        <br/>
        <br/>
      </p>
      <button
        type="button"
        className="btn default-button"
        id="swPageReload"
        onClick={onAcceptCookie}
      >
        I Accept
      </button>
    </div>
  );
};

const mapStateToProps = (store) => ({
  app: store.app,
});

const mapDispatchToProps = (dispatch) => ({
  acceptCookies: () => dispatch(acceptCookies()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CookiePopup));
