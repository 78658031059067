import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param slug
 */
export function fetchUser(slug) {
  return function(dispatch) {
    dispatch({ type: "USER_FETCH" });

    axios
      .get(
        AxiosConfig.getEndpointAddress() + "/api/users/" + slug + ".json",
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then(response => {
        dispatch({
          type: "USER_FETCH_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "USER_FETCH_REJECTED",
          payload: error
        });
      });
  };
}
