/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/01/2021.
 */

import React, { useState } from "react";
import Slider from "react-slick";
import Section from "../Portfolio/Section";

const Proof = (props) => {
  const [state, setState] = useState({
    slidesToShow: 1,
  });

  const settings = {
    accessibility: false,
    arrows: true,
    autoplay: false,
    dots: false,
    infinite: true,
    slidesToShow: state.slidesToShow,
    slidesToScroll: 1,
  };

  const cards = props.data.map((i, idx) => {
    return (
      <div>
        <div className="container list-grid">
          <div className="grid">
            <div className="layers-masonry-column span-6 column ">
              <div className="media image-top medium">
                <div className="media-image ">
                  <img
                    src={i.image.src}
                    className="attachment-layers-landscape-medium size-layers-landscape-medium"
                    alt={i.image.alt}
                  />
                </div>
              </div>
            </div>

            <div className="layers-masonry-column span-5 column has-image">
              <div className="media image-top large">
                <div className="media-body text-left">
                  <h2>{i.title}</h2>
                  {i.body.map((i, idx) => {
                    return <p key={"hpap-" + idx}>{i}</p>;
                  })}
                  {/*<Link to={HOME.about.button.url} className={"button"}>
                  {HOME.about.button.label}
                </Link>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <Section
      title={"Getting results"}
      lead={""}
      items={[]}
      body={null}
      bgDark={false}
      callout={null}
      id={"clients"}
    >
      <div className={"slider-container proof"}>
        <Slider {...settings}>{cards}</Slider>
      </div>
    </Section>
  );
};

export default Proof;
