/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/02/2019.
 */

import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PortfolioMasonryItem from "../Portfolio/PortfolioIMasonrytem";
const NO_OF_ITEMS_TO_SHOW = 6;

const Portfolio = (props) => {
  let imagesLoaded = 0;

  /**
   *
   * @param e
   */
  const preventDefault = (e) => {
    e.preventDefault();
  };
  /**
   *
   */
  const onImageLoad = () => {
    imagesLoaded++;

    if (imagesLoaded == props.projects.slice(0, NO_OF_ITEMS_TO_SHOW).length - 1) {
      window.updateMasonry();
    }
  };

  const projects = props.projects.slice(0, NO_OF_ITEMS_TO_SHOW).map((i, idx) => {
    return (
      <PortfolioMasonryItem
        title={i.title}
        lead={i.lead}
        slug={i.slug}
        kind={i.kind}
        slugPrefix={props.slugPrefix}
        onImageLoad={onImageLoad}
        featuredImage={i.featured}
      />
    );
  });

  return (
    <div id={props.id}>
      <section
        className="widget row content-vertical-massive futr-portfolio-widget bgdark "
        id={"layers-widget-portfolio-widget-" + props.id}
      >
        <div className="container clearfix">
          <div className="section-title medium text-center clearfix">
            <h3 className="heading">{props.title}</h3>
            <p className="excerpt">{props.subtitle}</p>
          </div>
        </div>

        <div className="row container masonry-layout">{projects}</div>
        <div className="row container">
          <div className="section-title medium text-center clearfix">
            <Link to={"/portfolio"} className={"button"}>
              View all
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

Portfolio.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  filters: PropTypes.array,
  projects: PropTypes.array,
};

export default Portfolio;
