/**
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { clearStructuredHead, jsonToHead } from "./Schema";
import {createOrganizationSchema} from './Organization';
import {getOrigin} from '../SeoBlock';
/**
 *
 * @param org
 * @param socials
 * @returns {{legalName: *, foundingDate: string, address: {'@type': string}, contactPoint: {'@type': string}[], '@type': string, name: *, logo: *, alternateName: *, '@context': string, url: string, sameAs: *}}
 */
export const createArticleSchema = (
  article,
  slugPrefix,
  org,
  socials,
  lastModified
) => {
  return {
    "@context": "http://schema.org",
    "@type": "Article",
    name: article.title + ", " + article.platform + " Case Study",
    headline: (article.lead).slice(0, 110),
    url: getOrigin() + "/" + slugPrefix + "/" + article.slug,
    articleBody: article.p.join(" "),
    wordCount: article.p.join("").split(" ").length,
    author: createOrganizationSchema(org, socials),
    publisher: createOrganizationSchema(org, socials),
    datePublished: article.publishedDate,
    dateModified: lastModified,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": getOrigin() + "/" + slugPrefix + "/" + article.slug,
    },
    keywords: article.tags.join(", "),
    timeRequired: "P5M",
    image: {
      "@type": "ImageObject",
      caption: article.featured.title,
      url: getOrigin() + article.featured.src,
      width: 1000,
      height: 667,
    },
  };
};
const Article = (props) => {
  useEffect(() => {
    if (props.clear) {
      clearStructuredHead();
    }
    jsonToHead(
      createArticleSchema(
        props.article,
        props.slugPrefix,
        props.org,
        props.socials,
        props.lastModified
      )
    );
  }, []);

  return null;
};

Article.propTypes = {
  org: PropTypes.object.isRequired,
  socials: PropTypes.array.isRequired,
  article: PropTypes.object.isRequired,
  slugPrefix: PropTypes.string.isRequired,
};


export default Article;