/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/02/2019.
 */

import React from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";
import Section from "./Services/Section";
import SeoBlock from "./components/SeoBlock";
import { withRouter } from "react-router-dom";
import Services from "./components/Schemas/Services";

const ServicesPage = (props) => {
  const { SERVICES, ORG, SOCIALS } = props.staticContent.model;

  const sections = [];
  let i = 0;

  for (const x in SERVICES) {
    const service = SERVICES[x];
    const bgDarg = i++ % 2 == 0;

    sections.push(
      <Section
        key={x}
        title={service.title}
        lead={service.lead}
        items={service.items}
        body={service.body}
        bgDark={bgDarg}
        callout={service.callout}
        id={x}
      />
    );
  }
  const currentSection = window.location.pathname
    .substr(1)
    .replace("services/", "");
  const servicesIndex = currentSection == "services" ? "/" : currentSection;

  const seo = SERVICES[servicesIndex].SEO;
  const structuredData = SERVICES[servicesIndex].structured_data;
  const services = structuredData ? (
    <Services
      org={ORG}
      socials={SOCIALS}
      services={structuredData.items}
      servicesDescriptions={structuredData.info}
    />
  ) : null;

  return (
    <PageTemplate>
      <SeoBlock
        title={seo.page_title}
        description={seo.description}
        keywords={seo.keywords}
      />
      {services}

      {sections}
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  staticContent: state.staticContent,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ServicesPage));
