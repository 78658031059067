/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/11/2019.
 */

import React from "react";
import { connect } from "react-redux";
import { history } from "../configureStore";
import PageTemplate from "./components/PageTemplate";
import Section from "./Services/Section";
import SeoBlock from "./components/SeoBlock";
import FullOrganization from "./components/Schemas/FullOrganization";
import classnames from "classnames";
import { withRouter } from "react-router-dom";

class TestimonialsPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.app.cookieAccepted=== 1) {
      try {
        const clutch = window.CLUTCHCO || null;

        if (clutch && clutch.Init instanceof Function) {
          clutch.Init();
        }
      } catch (e) {
        //...
        console.log(e);
      }
    }
  }

  /**
   *
   */
  onCTAClick = (e) => {
    e.preventDefault();
    history.push("/contact");

    return false;
  };

  /**
   *
   * @returns {*}
   */
  render() {

    const { ORG, SOCIALS, MGMT, STORY, ABOUT } = this.props.staticContent.model;
    return (
      <PageTemplate>
        <FullOrganization org={ORG} socials={SOCIALS} founders={MGMT} />

        <SeoBlock
          title="About The Beaverhead"
          description="The Beaverhead creates custom-made software for business. We harness reliable technologies to drive well-organized projects."
          keywords={[
            "custom made software",
            "software agency",
            "technologies",
            "mobile apps",
            "web apps",
            "start up",
          ]}
        />
        <div
          className={classnames(
            "widget layers-content-widget content-vertical-massive ",
            { bgdark: true }
          )}
        >
          <div className="container clearfix">
            <div className="section-title clearfix medium text-center ">
              <h3 className="heading">What our clients say about us</h3>
              <div className="layers-masonry-column span-8 offset-left-2 column ">
                <div className="section-title clearfix medium text-center ">
                  <p>&nbsp;</p>
                  {this.props.app.cookieAccepted === 1? (
                    <iframe
                      width="100%"
                      height="380"
                      src="https://www.youtube.com/embed/sWdo5JqRFMk?controls=0"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : null}
                </div>

                <button
                  aria-label="Send message to The Beaverhead"
                  type="button"
                  className="button"
                  value="Contact us!"
                  onClick={this.onCTAClick}
                >
                  Contact us!
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames(
            "widget layers-content-widget content-vertical-massive ",
            { bgdark: false }
          )}
        >
          <div className="container clearfix">
            <div className="section-title clearfix medium text-center ">
              <h3 className="heading" />
              <div className="row clutch-block">
                {this.props.app.cookieAccepted === 1? (
                  <div
                    className="clutch-widget"
                    data-url="https://widget.clutch.co"
                    data-widget-type="4"
                    data-expandifr="true"
                    data-height="auto"
                    data-snippets="true"
                    data-clutchcompany-id="725750"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  staticContent: state.staticContent,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TestimonialsPage));
