/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2020.
 */

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const AppMenuItem = (props) => {
  return (
    <li className="menu-item">
      <Link to={props.url} alt={props.name}>
        {props.name}
      </Link>
    </li>
  );
};

AppMenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
export default AppMenuItem;
