import React, { useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const ClientsCarousel = (props) => {
  const [state, setState] = useState({
    slidesToShow: 3,
  });

  const timeout = useRef(0);

  useEffect(() => {
    const onWindowResize = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(
        () =>
          setState({
            ...state,
            slidesToShow: window.innerWidth <= 740 ? 2 : 3,
          }),
        300
      );
    };

    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  const settings = {
    accessibility: false,
    autoplaySpeed: 2500,
    arrows: true,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: state.slidesToShow,
    slidesToScroll: 1,
  };

  const items = props.clients.map((i, idx) => {
    return (
      <div key={"csl-" + idx}>
        <Link to={i.url} title={i.name}>
          <img
            src={i.logo}
            className="attachment-medium size-medium logotype"
            alt={i.name}
          />
        </Link>
      </div>
    );
  });

  return (
    <div className={"slider-container"}>
      <Slider {...settings}>{items}</Slider>
    </div>
  );
};

export default ClientsCarousel;
