/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React from "react";
import { Link } from "react-router-dom";
import AxiosConfig from "./../AxiosConfig";
import PropTypes from "prop-types";

const PostContainer = (props) => {
  const post = props.post.model;

  const postLink = "/post/" + post.slug;

  const postImage =
    post.images && post.images.length ? (
      <img
        src={AxiosConfig.getEndpointAddress() + post.images[0].thumb_xs_path}
        className="attachment-large size-large"
        alt={post.images[0].filename}
        onLoad={props.onPreviewImageLoad}
        onError={props.onPreviewImageLoad}
      />
    ) : null;

  return (
    <article
      className={
        "column-flush span-4 layers-masonry-column thumbnail portfolio-list wow fadeInUp "
      }
    >
      <div className="thumbnail-media">
        <Link to={postLink}>{postImage}</Link>
      </div>
      <div className="portfolio-overlay">
        <Link to={postLink}>
          <div className="thumbnail-body">
            <div className="overlay">
              <header className="article-title portoliolist-header">
                <h4 className="heading">{post.title}</h4>
              </header>
              <p className="excerpt">{post.abstract}</p>
            </div>
          </div>
        </Link>
      </div>
    </article>
  );
};

PostContainer.propTypes = {
  post: PropTypes.shape({
    model: PropTypes.shape({
      title: PropTypes.string,
      abstract: PropTypes.string,
      images: PropTypes.array,
    }),
  }),
  onPreviewImageLoad: PropTypes.func.isRequired,
};

export default PostContainer;
