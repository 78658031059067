/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React from "react";
import URL from "url-parse";
import { Link } from "react-router-dom";

export default class LinkBlock extends React.PureComponent {
  /**
   *
   * @returns {XML}
   */
  render() {
    let url = new URL(this.props.href, true);

    const link =
      url.query && url.query.internal ? (
        <Link rel="" to={url.pathname}>
          {this.props.children[0]}
        </Link>
      ) : (
        <a
          href={this.props.href}
          target="_blank"
          title={this.props.title}
          rel="noopener noreferrer"
        >
          {this.props.children[0]}
        </a>
      );

    return <span>{link}</span>;
  }
}
