/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2020.
 */

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";
import Organization from "./components/Schemas/Organization";

import SeoBlock from "./components/SeoBlock";
import { withRouter } from "react-router-dom";
import { newsletterSignUp } from "../actions/newsletter";
import LeadSignup from "./components/LeadSignup";

const BLPCaseStudyPage = (props) => {

  const signupEmailRef = useRef("");

  const { ORG, SOCIALS } = props.staticContent.model;

  const onFocusEmail = () => {
    signupEmailRef.current.focus();
  };

  return (
    <PageTemplate>
      <Organization socials={SOCIALS} org={ORG} />

      <SeoBlock
        title="Case Study: How The Beaverhead created a unique tracking solution for Big Lazy Panda | The Beaverhead"
        description="From this case study you will learn about What it's like to run an visual effects company in LA and Why running your business on spreadsheets is not a good idea."
        keywords={[
          "case study",
          "Big Lazy Panda",
          "Custom CRM",
          "Custom tracking software",
          "spreadsheet in company",
          "operational software",
        ]}
        image="/img/casestudy/blp/cover.jpg"
      />

      <div className="widget layers-content-widget content-vertical-massive bgdark home ">
        <div className="container clearfix">
          <div className="section-title clearfix medium text-center ">
            <h3 className="heading">
              Case Study: How The Beaverhead created a unique tracking solution for Big Lazy
              Panda
            </h3>
          </div>
        </div>
        {/*<div
          className="container "
          dangerouslySetInnerHTML={{ __html: HOME.desc.body }}
        />*/}
      </div>

      <div className="widget layers-content-widget content-vertical-massive">
        <div className="container list-grid">
          <div className="grid">
            <div className="layers-masonry-column span-6  first  column ">
              <div className="media image-top medium">
                <div className="media-image ">
                  <img
                    src="/img/casestudy/blp/cover.jpg"
                    className="attachment-layers-landscape-medium size-layers-landscape-medium"
                    alt=""
                  />
                </div>
              </div>
            </div>



            <div className="layers-masonry-column span-5    column  has-image">
              <div className="media image-top large">
                <div className=" text-left">
                  <p>
                    Months ago Big Lazy Panda were grappling with their project
                    management.
                  </p>
                  <p>
                    They were using spreadsheets to run their business and had
                    no way of tracking their success or spotting issues early.
                    Founder Brad Powell was struggling to find the right
                    solution.
                  </p>
                  <p>
                    Download this case study to find out more from Brad himself
                    about Big Lazy Panda, the challenges the company faced, and
                    how they were resolved through custom-built software.
                    <br />
                    <br />
                  </p>
                  <LeadSignup

                    GAParams={[
                      "send",
                      "event",
                      "spreadsheets-improvements-eguide",
                      "download",
                      "Download now",
                    ]}
                    mailchimpTags={["casestudy-blp"]}
                    submitErrorMessage={"We could not send you the case-study, please try again."}
                    submitSuccessMessage={"Please check your mailbox! We've sent the case-study to "}
                    signupEmailRef={signupEmailRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive bgdark home ">
        <div className="container ">
          <div className="story portfolio">
            <div className="container list-grid">
              <div className="grid">
                <div className="layers-masonry-column span-12 column">
                  <h3>From this case study you will learn about:</h3>
                  <ul>
                    <li>
                      What it's like to{" "}
                      <strong>run an visual effects company</strong> in LA
                    </li>
                    <li>
                      Why <strong>running your business on spreadsheets</strong>{" "}
                      is not a good idea
                    </li>
                    <li>
                      How <strong>custom software is helping</strong> Big Lazy
                      Panda
                    </li>
                    <li>
                      How long it takes to{" "}
                      <strong>build custom software</strong>
                    </li>
                    <li>
                      What the <strong>process</strong> is behind getting custom
                      software built
                    </li>
                  </ul>
                </div>

                <div className="layers-masonry-column span-2"></div>
                <div
                  className="layers-masonry-column span-8   column eguide-cta-container"
                  onClick={onFocusEmail}
                >
                  To read about all of the above, download the case study now!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive home ">
        <div className="container ">
          <div className="story">
            <div className="container list-grid">
              <div className="grid">
                <div className="layers-masonry-column span-12 column">
                  <p>
                    Big Lazy Panda founder Brad Powell was struggling to keep
                    track of his business performance and task list.
                    Spreadsheets, invoicing software, and team management
                    software were not working together and data was getting
                    lost. He needed a way to see all important information in
                    one place and, ideally, be reminded of deadlines and tasks.
                    That’s where The Beaverhead came in.
                  </p>
                </div>

                <div className="layers-masonry-column span-2"></div>
                <div
                  className="layers-masonry-column span-8   column eguide-cta-container"
                  onClick={onFocusEmail}
                >
                  Find out how The Beaverhead helped Big Lazy Panda solve all
                  their operational problems with one software solution
                </div>
                <div className="layers-masonry-column span-2"></div>
                <div className="layers-masonry-column span-2"></div>
                <div className="futr_portfolio">
                  <div className={"story portfolio"}>
                    <div className="layers-masonry-column span-12 column">
                      <blockquote>
                        <p>
                          "It was easy, it was “comfortable”, I think, is the
                          best word to describe it. I never felt at risk. As a
                          small company you want to know [your money] is going
                          to be well spent. With The Beaverhead’s method of
                          working to milestones and paying to milestones, it was
                          easy. It was simple, it was how you would hope it
                          would be." - Brad Powell, founder, Big Lazy Panda
                        </p>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  staticContent: state.staticContent,
  newsletter: state.newsletter,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({
  newsletterSignUp: async (data) => await dispatch(newsletterSignUp(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BLPCaseStudyPage));
