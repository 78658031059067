const initialState = {
  error: null,
  loading: false,

  login: false,
  loginError: null,
  loginSuccess: false,

  register: false,
  registerError: null,
  registerSuccess: false,

  checkSession: false,
  checkSessionSuccess: false,
  checkSessionError: null,

  model: null,
  token: null
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "USER_ADMIN_DELETE_FULFILLED":
      return {
        model: null,
        postState: {
          ...initialState
        }
      };

    /***USER_LOGIN***/
    case "USER_LOGIN":
      return {
        ...state,
        login: true,
        loginError: null,
        loginSuccess: false
      };

    case "USER_LOGIN_REJECTED":
      return {
        ...state,
        loginError: action.payload,
        login: false
      };

    case "USER_LOGIN_FULFILLED":
      return {
        ...state,
        model: action.payload.user,
        token: action.payload.token,
        login: false,
        loginSuccess: true
      };
    /***END OF USER_LOGIN***/

    /***USER_REGISTER***/
    case "USER_REGISTER":
      return {
        ...state,
        register: true,
        registerError: null,
        registerSuccess: false
      };

    case "USER_REGISTER_REJECTED":
      return {
        ...state,
        register: false,
        registerError: action.payload
      };

    case "USER_REGISTER_FULFILLED":
      return {
        ...state,
        model: action.payload.user,
        token: action.payload.token,
        register: false,
        registerSuccess: true
      };

    case "USER_LOGOUT":
      return {
        ...initialState
      };

    case "CLEAR_ERRORS": {
      return {
        ...state,
        loginError: null,
        registerError: null,
        checkSessionError: null
      };
    }

    case "CHECK_SESSION_FULFILLED":
      return {
        ...state,
        checkSession: false,
        checkSessionSuccess: true,
      }


    case "USER_EDIT_FULFILLED":

      if (action.payload.id === state.model.id) {
        return {
          ...state,
          model: action.payload,

        };
      }
      return state;


    default:
      return state;
  }
}
