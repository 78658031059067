const initialState = {
  error: null,
  loading: false,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  deleteLinkSent: false,

  avatarUpload: false,
  avatarUploadError: null,
  avatarUploadSuccess: false,

  model: null
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    /***USER_FETCH***/
    case "USER_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
        model: null
      };

    case "USER_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchError: action.payload
      };

    case "USER_FETCH_FULFILLED":
      return {
        model: action.payload,
        fetch: false,
        fetchSuccess: true
      };
    /***END OF USER_FETCH***/

    case "USER_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null
      };
    case "USER_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload
      };

    case "USER_EDIT_FULFILLED":
      return {
        ...state,
        model: action.payload,
        edit: false,
        editSuccess: true
      };
    /***END OF USER_EDIT***/

    /***USER_AVATAR_EDIT***/
    case "USER_AVATAR_EDIT":
    case "USER_AVATAR_DELETE":
      return {
        ...state,
        avatarUpload: true,
        avatarUploadSuccess: false,
        avatarUploadError: null
      };

    case "USER_AVATAR_EDIT_REJECTED":
    case "USER_AVATAR_DELETE_REJECTED":
      return {
        ...state,
        avatarUpload: false,
        avatarUploadError: action.payload
      };

    case "USER_AVATAR_EDIT_FULFILLED":
    case "USER_AVATAR_DELETE_FULFILLED":
      return {
        ...state,
        model: action.payload,
        avatarUpload: false,
        avatarUploadSuccess: true
      };
    /***END OF USER_AVATAR_EDIT***/

    /***USER_DELETE***/

    case "USER_ADMIN_DELETE":
    case "USER_DELETE":
      return {
        ...state,
        delete: true,
        deleteLinkSent: false,
        deleteError: null
      };

    case "USER_ADMIN_DELETE_REJECTED":
    case "USER_DELETE_REJECTED":
      return {
        ...state,

        delete: false,
        deleteError: action.payload
      };

    case "USER_ADMIN_DELETE_FULFILLED":
      return {
        ...state,

        delete: false,
        deleteSuccess: true
      };

    case "USER_DELETE_FULFILLED":
      return {
        ...state,

        delete: false,
        deleteLinkSent: true
      };
    /***END OF USER_DELETE***/

    default:
      return state;

  }

}
