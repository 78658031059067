/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/06/2020.
 */

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";
import { formatError } from "./../tools";

import emailValidator from "email-validator";

import Indicator from "./../components/Indicator";
import Organization from "./components/Schemas/Organization";

import SeoBlock from "./components/SeoBlock";
import { withRouter } from "react-router-dom";
import { newsletterSignUp } from "../actions/newsletter";
import LeadSignup from "./components/LeadSignup";

const EguideSpreadsheetsPage = (props) => {
  const signupEmailRef = useRef("");

  const onFocusEmail = () => {
    signupEmailRef.current.focus();
  };

  const { ORG, SOCIALS } = props.staticContent.model;

  return (
    <PageTemplate>
      <Organization socials={SOCIALS} org={ORG} />

      <SeoBlock
        title="Optimise your business operation spreadsheets | The Beaverhead"
        description="Using the simple methods outlined in this guide, you will be abale to Automate your spreadsheets so they update automatically, intuitive way, create a clear view of your business operations, build dashboards to help you overview all your information."
        keywords={[
          "spreadsheets",
          "google sheets",
          "optimise",
          "spreadsheet template",
          "spreadsheet in company",
          "operational spreadsheet",
        ]}
        image="/img/eguide/spreadsheets/spreadsheets-eguide.jpg"
      />

      <div className="widget layers-content-widget content-vertical-massive bgdark home ">
        <div className="container clearfix">
          <div className="section-title clearfix medium text-center ">
            <h3 className="heading">
              6+1 ways to optimise your business operation spreadsheets
            </h3>
          </div>
        </div>
        {/*<div
          className="container "
          dangerouslySetInnerHTML={{ __html: HOME.desc.body }}
        />*/}
      </div>

      <div className="widget layers-content-widget content-vertical-massive">
        <div className="container list-grid">
          <div className="grid">
            <div className="layers-masonry-column span-5  first  column ">
              <div className="media image-top medium">
                <div className="media-image ">
                  <img
                    src="/img/eguide/spreadsheets/spreadsheets-eguide.jpg"
                    className="attachment-layers-landscape-medium size-layers-landscape-medium"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="layers-masonry-column span-1  last  column"></div>

            <div className="layers-masonry-column span-5  last  column  has-image">
              <div className="media image-top large">
                <div className="media-body text-left">
                  <p>
                    A lot of startups and small businesses use Google
                    spreadsheets, Excel or LibreOffice to manage their
                    operations; however, spreadsheets can be unreliable,
                    confusing and time-consuming.
                  </p>
                  <p>
                    This thorough and comprehensive e-guide will help you
                    optimise the way you use spreadsheets to operate your
                    business, providing valuable tips to improve your day to day
                    efficiency.{" "}
                  </p>
                  <p>
                    Enter your details below to claim your copy!
                    <br />
                    <br />
                  </p>

                  <LeadSignup
                    GAParams={[
                      "send",
                      "event",
                      "spreadsheets-improvements-eguide",
                      "download",
                      "Download now",
                    ]}
                    mailchimpTags={["eguide-spreadsheets"]}
                    submitErrorMessage={
                      "We could not send you the e-guide, please try again."
                    }
                    submitSuccessMessage={
                      "Please check your mailbox! We've sent the e-guide to "
                    }
                    signupEmailRef={signupEmailRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive bgdark home ">
        <div className="container ">
          <div className="story portfolio">
            <div className="container list-grid">
              <div className="grid">
                <div className="layers-masonry-column span-12 column">
                  <h3>
                    Using the simple methods outlined in this guide, you will be
                    abale to
                  </h3>
                  <ul>
                    <li>
                      <strong>Organise</strong> and order your data in a clear,
                      intuitive way
                    </li>
                    <li>
                      <strong>Create</strong> a clear view of your business
                      operations
                    </li>
                    <li>
                      <strong>Build</strong> dashboards to help you overview all
                      your information
                    </li>
                    <li>
                      <strong>Automate</strong> your spreadsheets so they update
                      automatically
                    </li>
                    <li>
                      <strong>Visualise</strong> your data so you're no longer
                      facing a wall of numbers
                    </li>
                  </ul>
                </div>

                <div className="layers-masonry-column span-2"></div>
                <div
                  className="layers-masonry-column span-8   column eguide-cta-container"
                  onClick={onFocusEmail}
                >
                  To learn to do all of this - and more - download our free
                  e-guide now!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive home ">
        <div className="container ">
          <div className="story">
            <div className="container list-grid">
              <div className="grid">
                <div className="layers-masonry-column span-12 column">
                  <p>
                    Cheap and easy to use, spreadsheets provide a solution to
                    organise data and format it in a way to analyse and grow
                    your business.
                  </p>
                </div>
              </div>
              <div className="grid">
                <div className="layers-masonry-column span-122 column eguide--images-wrapper ">
                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/business_plans.png"
                    />
                    <h3>Business plans</h3>
                  </div>

                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/contact_lists.png"
                    />
                    <h3>Contact lists</h3>
                  </div>

                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/checklists.png"
                    />
                    <h3>Checklists</h3>
                  </div>

                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/accounting.png"
                    />
                    <h3>Accounting</h3>
                  </div>

                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/data_visualisation.png"
                    />
                    <h3>Data visualisation</h3>
                  </div>

                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/time_logs.png"
                    />
                    <h3>Time logs</h3>
                  </div>

                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/inventory_tracking.png"
                    />
                    <h3>Inventory tracking</h3>
                  </div>

                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/hr_management.png"
                    />
                    <h3>HR management</h3>
                  </div>

                  <div className="enguide--images-container">
                    <img
                      className="eguide-cta__image"
                      src="/img/eguide/spreadsheets/schedules.png"
                    />
                    <h3>Schedules</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive bgdark home ">
        <div className="container ">
          <div className="story portfolio">
            <div className="container list-grid">
              <div className="grid">
                <div className="layers-masonry-column span-12 column">
                  <p>
                    However, there are large setbacks and risks as well:
                    <ul>
                      <li>
                        Lack of control over your data and how it's handled
                      </li>
                      <li>
                        Little flexibility on formatting and visualisation
                      </li>
                      <li>Unreliability of human error</li>
                      <li>Lack of capacity of the out-of-the-box solutions</li>
                      <li>Large amounts of data become overwhelming</li>
                      <li>Wasting time to navigate through your data</li>
                      <li>Extremely difficult to find any mistakes</li>
                    </ul>
                  </p>
                </div>

                <div className="layers-masonry-column span-2 column "></div>
                <div
                  className="layers-masonry-column span-8 column eguide-cta-container"
                  onClick={onFocusEmail}
                >
                  Have you been facing some of these issues? Download our free
                  e-guide to get some help, instantly!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive home ">
        <div className="container ">
          <div className="story portfolio">
            <div className="container list-grid">
              <div className="grid">
                <div className="layers-masonry-column span-12 column eguide--shorts">
                  <p>
                    <strong>90%</strong> of spreadsheets contain at least one
                    error
                  </p>
                  <p>
                    <strong>All</strong> spreadsheets have an error in{" "}
                    <b>1% of all formula cells</b>
                  </p>
                  <p>
                    <strong>Most</strong> mistakes are due to human error
                  </p>
                </div>
                <div className="layers-masonry-column span-12 column ">
                  <p>
                    Still, spreadsheets are often the only option for many
                    businesses, the alternative being customised software.
                    <br />
                    <br />
                    <strong>So how do you make them work?</strong>
                    <br />
                    <br />
                    At The Beaverhead we discovered that a lot of our clients
                    can make their lives much easier by optimising their
                    spreadsheets, using tips and tricks that don't require too
                    much time or expertise.
                  </p>
                </div>
                <div className="layers-masonry-column span-2 column "></div>
                <div
                  className="layers-masonry-column span-8 column eguide-cta-container"
                  onClick={onFocusEmail}
                >
                  Download our easy to follow guide today and start implementing
                  simple methods to optimise your operations!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  staticContent: state.staticContent,
  newsletter: state.newsletter,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({
  newsletterSignUp: async (data) => await dispatch(newsletterSignUp(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EguideSpreadsheetsPage));
