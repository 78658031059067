import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';

const PortfolioMasonryItem = props => {


  return (
    <article
      className={
        "column-flush span-4 layers-masonry-column thumbnail with-overlay portfolio-list wow fadeInUp " +
        props.kind.join(" ")
      }
      data-cols="3"
    >
      <div className="thumbnail-media">
        <Link to={props.slugPrefix + props.slug}>
          <img
            src={props.featuredImage.src}
            className="attachment-large size-large"
            alt=""
            onLoad={props.onImageLoad}
          />
        </Link>
      </div>
      <div className="portfolio-overlay">
        <Link to={props.slugPrefix + props.slug}>
          <div className="thumbnail-body">
            <div className="overlay">
              <header className="article-title portoliolist-header">
                <h4 className="heading">{props.title}</h4>
              </header>
              <p className="excerpt">{props.lead}</p>
            </div>
          </div>
        </Link>
      </div>
    </article>
  )
};

PortfolioMasonryItem.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  kind: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
  slugPrefix: PropTypes.string.isRequired,
  onImageLoad: PropTypes.func,
  featuredImage: PropTypes.object.isRequired,

}

export default PortfolioMasonryItem;