import React from 'react';
import PropTypes from 'prop-types';

const Quote = props => {

  return <blockquote>
    <p>"{props.cite}"<br/>{props.signature}</p>
  </blockquote>
}

Quote.propTypes =  {
  cite: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
}

export default Quote;