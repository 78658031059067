/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/08/2017.
 */

import React from "react";
import PropTypes from "prop-types";

class Wysiwyg extends React.Component {
  static defaultProps = {
    markdownPlaceholder: "Use markdown to format your article."
  };


  /**
   *
   */
  componentDidMount() {}

  /**
   *
   */
  addPhotoUrl(contentId, image) {
    let txtarea = document.getElementById(contentId);
    if (!txtarea) {
      return;
    }

    let strPos = 0;
/*
    let br =
      txtarea.selectionStart || txtarea.selectionStart == "0"
        ? "ff"
        : document.selection
        ? "ie"
        : false;
*/

    strPos = txtarea.selectionStart;

    let initialPosition = strPos;

    let front = txtarea.value.substring(0, strPos);
    let back = txtarea.value.substring(strPos, txtarea.value.length);

    let changeData = {
      front: front,
      back: back,
      cursorPosition: initialPosition
    };

    this.props.uploadImage(
      image,
      this.props.resource,
      changeData,
      this.props.uuid
    );
  }

  /**
   *
   */
  onInsertPhoto = () => {
    document.querySelector("#" + this.props.addPhotoBtnId).value = null;
    document.querySelector("#" + this.props.addPhotoBtnId).click();
  };

  /**
   *
   * @param e
   */
  onChangePhotoFile = e => {
    if (e.target.files[0]) {
      this.addPhotoUrl(this.props.contentId, e.target.files[0]);
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    return (
      <div className="wysiwyg-container">
        <button
          type="button"
          className="btn btn-default btn-wysiwyg"
          title="Insert photo"
          disabled={this.props.loading}
          onClick={this.onInsertPhoto}
        >
          <i className="fa fa-upload"/>
          {" "}<span>Insert photo</span>
        </button>
        <input
          id={this.props.addPhotoBtnId}
          type="file"
          className="hidden"
          accept="image/*"
          onChange={this.onChangePhotoFile}
        />
        <span className="wysiwyg__markdown-info">
          {this.props.markdownPlaceholder}
        </span>
        <div className="clearfix" />
      </div>
    );
  }
}

Wysiwyg.propTypes = {
  markdownPlaceholder: PropTypes.string,
  addPhotoBtnId: PropTypes.string.isRequired,
  uploadImage: PropTypes.func.isRequired,
  uuid: PropTypes.string,
  loading: PropTypes.bool,
  resource: PropTypes.string.isRequired
};

export default Wysiwyg;
