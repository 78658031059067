/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2019.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param data
 */
export const fetchStore = (data) => {

  return async (dispatch) => {

    dispatch({
      type: "APP_FETCH_STORE",
    });

    await axios.get( "/n/content/store.json")
    .then((response) => {

      dispatch({
        type: "APP_FETCH_STORE_FULFILLED",
        payload: {
          data: response.data,
          lastModified: response.headers['last-modified']
        },
      });

    })
    .catch(function (error) {

      dispatch({
        type: "APP_FETCH_STORE_REJECTED",
        payload: error
      })
    });
  }
}