/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/06/2019.
 */

import React from "react";

import PropTypes from "prop-types";
import classnames from "classnames";

const StoryRow = (props) => {
  return (
    <div
      className={classnames("layers-masonry-column column span-" + props.size)}
    >
      <div className="media image-top medium">
        <div className="media-body text-left">
          <h5 className="heading">{props.title}</h5>
          <div className="excerpt">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

StoryRow.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number,
};

StoryRow.defaultProps = {
  size: 6,
};

export default StoryRow;
