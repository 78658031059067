/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/02/2019.
 */

import React from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   *
   * @returns {*}
   */
  render() {

    return (
      <PageTemplate>

          <div className="post content-main clearfix container">
            <div className="grid">


              <article className="column span-12">
                <header className="section-title large">
                  <h1 className="heading">Not found</h1>
                </header>
                <div className="story">
                  <p>The requested URL: {window.location.pathname} was not found.</p>

                </div>
              </article>

            </div>
          </div>


          <div id="back-to-top">
            <a href="#top">Back to top</a>
          </div>

      </PageTemplate>
    );
  }
}

export default NotFoundPage;