/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/02/2019.
 */

import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Portfolio = (props) => {
  let imagesLoaded = 0;

  /**
   *
   * @param e
   */
  const preventDefault = (e) => {
    e.preventDefault();
  };
  /**
   *
   */
  const onImageLoad = () => {
    imagesLoaded++;

    if (imagesLoaded == props.projects.length - 1) {
      window.updateMasonry();
    }
  };

  const kinds = [].concat
    .apply(
      [],
      props.projects.map((i) => i.kind)
    )
    .filter((i, idx, self) => self.indexOf(i) == idx);

  const filters = kinds.map((i, idx) => {
    return (
      <li key={props.id + "-pfo-" + idx}>
        <a
          href="#"
          data-filter={"." + i}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {i.charAt(0).toUpperCase() + i.slice(1)}
        </a>
      </li>
    );
  });

  const projects = props.projects.map((i, idx) => {
    return (
      <article
        key={props.id + "-cp-" + idx}
        className={
          "column-flush span-4 layers-masonry-column thumbnail with-overlay portfolio-list wow fadeInUp " +
          i.kind.join(" ")
        }
        data-cols="3"
      >
        <div className="thumbnail-media">
          <Link to={props.slugPrefix + i.slug}>
            <img
              src={i.featured.src}
              className="attachment-large size-large"
              alt=""
              onLoad={onImageLoad}
            />
          </Link>
        </div>
        <div className="portfolio-overlay">
          <Link to={props.slugPrefix + i.slug}>
            <div className="thumbnail-body">
              <div className="overlay">
                <header className="article-title portoliolist-header">
                  <h4 className="heading">{i.title}</h4>
                </header>
                <p className="excerpt">{i.lead}</p>
              </div>
            </div>
          </Link>
        </div>
      </article>
    );
  });

  return (
    <div id={props.id}>
      <section
        className="widget row content-vertical-massive futr-portfolio-widget black-background "
        id={"layers-widget-portfolio-widget-portfolio"}
      >
        <div className="container clearfix">
          <div className="section-title medium text-center clearfix">
            <h3 className="heading">{props.title}</h3>
            <p className="excerpt">{props.subtitle}</p>
          </div>
        </div>
        <div className="container text-center clearfix">
          <ul
            className="nav futr-isotope-filter filters"
            data-isotope-container={
              "layers-widget-portfolio-widget-" + props.id
            }
          >
            <li>
              <a
                href="#"
                data-filter={""}
                className="active"
                onClick={preventDefault}
              >
                All
              </a>
            </li>
            {filters}
          </ul>
        </div>

        <div className="row container masonry-layout">{projects}</div>
      </section>
    </div>
  );
};

Portfolio.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  filters: PropTypes.array,
  projects: PropTypes.array,
};

export default Portfolio;
