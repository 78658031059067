/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/06/2019.
 */

import React from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";

import axios from "axios";
import {withRouter} from 'react-router-dom';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      policy: null
    };
  }

  componentDidMount() {
    console.log("I'm mounted");

    axios
      .get("/n/content/privacy_policy.html")
      .then(response => {
        this.setState({ policy: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const policy = this.state.policy ? (
      <div dangerouslySetInnerHTML={{ __html: this.state.policy }} />
    ) : null;
    return (
      <PageTemplate>
        <section className="content-main clearfix with-sidebar post-236 futr_portfolio type-futr_portfolio status-publish has-post-thumbnail hentry futr-portfolio-categories-identity container">
          <div className="row">
            <article className={"privacy-policy"}>
              <header className="section-title large">
                <h1 className="heading">Privacy Policy</h1>
              </header>
              {policy}
            </article>
          </div>
        </section>
      </PageTemplate>
    );
  }
}




const mapStateToProps = state => ({
  staticContent: state.staticContent,
  navigation: state.navigation
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivacyPolicy));