/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/12/2019.
 */

import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";

import PageTemplate from "./components/PageTemplate";
import { cleanupPostsCollection, fetchPosts } from "../actions/posts";
import { withRouter } from "react-router-dom";
import PostsContainer from "../components/PostsContainer";

const TagPage = (props) => {
  const posts = useSelector((store) => store.posts);

  /**
   *
   */
  const loadMore = async (page) => {
    const tagSlug = props.match.params.tagSlug;

    const data = { page };

    if (tagSlug) {
      data.tag = tagSlug;
    }

    await props.fetchPosts(data);

  };

  useEffect(() => {
    props.cleanupPostsCollection();
    loadMore(1);

    return props.cleanupPostsCollection;
  }, []);

  const tagSlug = props.match.params.tagSlug || null;
  //const posts = props.posts;

  let tagLabel =
    tagSlug && !posts.fetchLoading && posts.collection.length
      ? posts.collection[0].tags.find((i) => i.slug === tagSlug)
      : null;

  if (tagLabel) {
    tagLabel = "#" + tagLabel.label;
  } else {
    tagLabel = tagSlug ? "#" + tagSlug : null;
  }

  return (
    <PageTemplate>
      <section className="welcome container">
        <div className={"row"}>
          <div className={"col-xs-12"}>
            <div className={"welcome__title--tag"}>
              <h1>{tagLabel}</h1>
            </div>
          </div>
          <div className={"col-xs-12"}>
            <PostsContainer
              postsActions={props.postsActions}
              posts={posts}
              tagSlug={tagSlug}
              loadMore={loadMore}
            />
          </div>
        </div>
      </section>
    </PageTemplate>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchPosts: async (slug) => {
    await dispatch(fetchPosts(slug));
  },
  cleanupPostsCollection: () => dispatch(cleanupPostsCollection()),
});

export default connect(null, mapDispatchToProps)(withRouter(TagPage));
