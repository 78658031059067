/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param data
 */
export const login = data => {
  return async dispatch => {
    // console.log(data);

    dispatch({ type: "USER_LOGIN" });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/users/login.json",
        {
          email: data.email,
          password: data.password,
          provider: data.provider,
          token: data.responseToken
        },
        AxiosConfig.getConfig()
      )
      .then(response => {
        AxiosConfig.setAuthToken(response.data.token);

        dispatch({
          type: "USER_LOGIN_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "USER_LOGIN_REJECTED",
          payload: error.response.data
        });
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const logout = () => {
  return function(dispatch) {
    dispatch({ type: "USER_LOGOUT" });
  };
};

/**
 *
 * @param data
 */
export const register = data => {
  return async dispatch => {
    dispatch({ type: "USER_REGISTER" });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/users.json",
        {
          name: data.nickname,
          email: data.email,
          password: data.password
        },
        AxiosConfig.getConfig()
      )
      .then(response => {
        dispatch({
          type: "USER_REGISTER_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "USER_REGISTER_REJECTED",
          payload: error
        });
      });
  };
};

export const checkSession = isLogged => {
  return async dispatch => {
    dispatch({ type: "CHECK_SESSION" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/users/check_session.json";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then(response => {
        dispatch({ type: "CHECK_SESSION_FULFILLED", data: response.data });
      })
      .catch(function(error) {
        dispatch({ type: "CHECK_SESSION_REJECTED", data: error });
      });
  };
};

/**
 *
 * @param slug, data
 */
export const updateUser = (slug, data, baseUrl = "/profile/") => {
  return async dispatch => {
    dispatch({ type: "USER_EDIT", slug: slug });

    await axios
      .put(
        AxiosConfig.getEndpointAddress() + "/api/users/" + slug + ".json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "USER_EDIT_FULFILLED",
          payload: response.data
        });

        //history.replace(baseUrl + response.data.slug);
      })
      .catch(function(error) {
        dispatch({
          type: "USER_EDIT_REJECTED",
          payload: error,
          slug: slug
        });
      });
  };
};

/**
 *
 * @param slug, image
 */
export const uploadAvatar = (slug, image) => {
  return async dispatch => {
    dispatch({ type: "USER_AVATAR_EDIT" });

    let data = new FormData();
    data.append("avatar", image);
    data.append("slug", slug);

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/users/avatar_upload.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "USER_AVATAR_EDIT_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "USER_AVATAR_EDIT_REJECTED",
          payload: error
        });
      });
  };
};

/**
 *
 * @param slug, image
 */
export function deleteAvatar(slug) {
  return function(dispatch) {
    dispatch({ type: "USER_AVATAR_DELETE" });

    let data = {
      slug: slug
    };
    //data.append("slug", slug);

    axios
      .delete("/api/users/avatar_remove.json", {
        data: data,
        headers: {
          Accept: "application/json, text/javascript, /; q=0.01"
        }
      })
      .then(response => {
        dispatch({
          type: "USER_AVATAR_DELETE_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "USER_AVATAR_DELETE_REJECTED",
          payload: error
        });
      });
  };
}



/**
 *
 *
 */
export const deleteUser = slug => {
  return async dispatch => {
    dispatch({ type: "USER_DELETE" });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() + "/api/users/" + slug + ".json",
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "USER_DELETE_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "USER_DELETE_REJECTED",
          payload: error
        });
      });
  };
};

/**
 *
 *
 */
export const adminDeleteUser = email => {
  return async dispatch => {
    dispatch({ type: "USER_ADMIN_DELETE" });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() +
        "/api/users/admin_delete/" +
        email +
        ".json",
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "USER_ADMIN_DELETE_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "USER_ADMIN_DELETE_REJECTED",
          payload: error
        });
      });
  };
};
