/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {Function}
 */
export const fetchPost = slug => {
  return async dispatch => {
    dispatch({ type: "POST_FETCH" });

    await axios
      .get(
        AxiosConfig.getEndpointAddress() + "/api/posts/" + slug + ".json",
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then(response => {
        dispatch({
          type: "POST_FETCH_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "POST_FETCH_REJECTED",
          payload: error
        });
      });
  };
};

/**
 *
 * @param slug
 * @returns {Function}
 */
export const deletePost = slug => {
  return async dispatch => {
    dispatch({ type: "POST_DELETE" });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() + "/api/posts/" + slug + ".json",
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "POST_DELETE_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "POST_DELETE_REJECTED",
          payload: error
        });
      });
  };
};


export const newPostSubmit = data => {
  return async dispatch => {
    dispatch({ type: "POST_EDIT" });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/posts.json",
        { ...data },
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "POST_EDIT_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "POST_EDIT_REJECTED",
          payload: error
        });
      });
  };
};



/**
 *
 * @param slug
 * @param data
 * @returns {Function}
 */
export const updatePost = (slug, data, is_static) => {
  return async dispatch => {
    dispatch({ type: "POST_EDIT" });

    await axios
      .put(
        AxiosConfig.getEndpointAddress() + "/api/posts/" + slug + ".json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "POST_EDIT_FULFILLED",
          payload: response.data
        });

        /*     if (!is_static) {
          history.replace("/post/" + response.data.slug);
        } else {
          history.replace("/static/" + response.data.slug);
        }*/
      })
      .catch(function(error) {
        dispatch({
          type: "POST_EDIT_REJECTED",
          payload: error,
          slug: slug
        });
      });
  };
};

/**
 *
 * @param file
 * @param resource
 * @param changeData
 * @param uuid
 * @returns {Function}
 */
export const uploadPostImage = (file, resource, changeData, uuid = null) => {
  return async dispatch => {
    let data = new FormData();
    data.append("file", file);
    data.append("resource", resource);

    if (uuid) {
      data.append("uuid", uuid);
    }

    dispatch({ type: "POST_IMAGE_UPLOAD" });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/images.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "POST_IMAGE_UPLOAD_FULFILLED",
          payload: response.data,
          changeData: changeData
        });
      })
      .catch(function(error) {
        dispatch({
          type: "POST_IMAGE_UPLOAD_REJECTED",
          payload: error
        });
      });
  };
};

/**
 *
 * @param slug
 * @param type
 * @returns {Function}
 */
export const votePost = (slug, type) => {
  return async dispatch => {
    dispatch({ type: "POST_VOTE" });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() +
          "/api/posts/vote/" +
          slug +
          "/" +
          type +
          ".json",
        AxiosConfig.getAuthConfig()
      )
      .then(response => {
        dispatch({
          type: "POST_VOTE_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "POST_VOTE_REJECTED",
          payload: error
        });
      });
  };
};
