/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2020.
 */

import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const AppMenuDropdown = (props) => {
  return (
    <li className="menu-item menu-item-has-children">
      <Link to={props.url} rel="nofollow">
          {props.name}
      </Link>
      <ul className="sub-menu">
          {props.children}
      </ul>
    </li>
  );
};

AppMenuDropdown.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
export default AppMenuDropdown;
