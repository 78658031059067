/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/07/2017.
 */

import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/lib/integration/react';
//import * as serviceWorker from "./serviceWorker";
import {
  configureStore,
  persistor
} from "./configureStore";
import RouterClass from "./router";
require('./sass/style.scss');




function launchApp(swObject) {


  const app = document.getElementById("root");

  ReactDOM.render(
    <Provider store={configureStore}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterClass swObject={swObject} />
      </PersistGate>
    </Provider>,
    app
  );
}

/*if ('serviceWorker' in navigator) {

  registerServiceWorker()
  .then(() => {
    launchApp();
  })
  .catch((error) => {
    console.log(error);
  });
} else {*/
  launchApp();
//}