/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/02/2018.
 * tbh -
 */

const initialState = {
  cookieAccepted: false,
  newsletterSignedUp: false
};

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {

    case "APP_SET_COOKIE_ACCEPTED":
      return {
        ...state,
        cookieAccepted: 1
      };

    case "NEWSLETTER_SIGNUP_FULFILLED":
      return {
        ...state,
        newsletterSignedUp: true
      };


    case "CLEAR_ERRORS":
      return {
        ...state,
        fetchError: null,
        fetch: false,
        fetchSuccess: false,
      };

    default:
      return state;

  }

  return state;
}
