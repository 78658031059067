/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React from "react";
import AxiosConfig from "../AxiosConfig";

export default class ImageBlock extends React.PureComponent {
  /**
   *
   * @returns {XML}
   */
  render() {
    return (
      <span className="content-image-block">
        <img
          src={AxiosConfig.getEndpointAddress() + this.props.src}
          alt={this.props.alt}
        />
        <span>{this.props.title}</span>
      </span>
    );
  }
}
