/**
 *
 */

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {clearStructuredHead, jsonToHead} from "./Schema";
import {createOrganizationSchema} from "./Organization";
import  {getOrigin} from "../SeoBlock";
import moment from "moment";

const createArticleSchema =  (article, slugPrefix, org, socials, lastModified) => {

  const isRestricted = article.is_restricted * 1 == 1;
  const body = isRestricted ? article.restricted_body : article.body;

  const image =
    article.images && article.images.length
      ? {
        "@type": "ImageObject",
        caption: article.images[0].filename,
        url: getOrigin() + article.images[0].thumb_md_path,
        width: 1000,
        height: 667
      }
      : null;

  return {
    "@context": "http://schema.org",
    "@type": "Article",
    name: article.title + " - " + article.platform,
    headline: article.abstract.slice(0, 110),
    url: getOrigin() + "/" + slugPrefix + "/" + article.slug,
    articleBody: body,
    wordCount: body.split(" ").length,
    author: article.user.name,
    publisher: createOrganizationSchema(org, socials),
    datePublished: moment(article.publish_date * 1000).format("YYYY-MM-DD"),
    dateModified: moment(
      (article.modified || article.publish_date) * 1000
    ).format("YYYY-MM-DD"),
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": getOrigin() + "/" + slugPrefix + "/" + article.slug
    },
    keywords: article.tags.join(", "),
    timeRequired: "P7M",
    image
  };
}
const PostArticle = (props) => {
  useEffect(() => {
    if (props.clear) {
      clearStructuredHead();
    }
    jsonToHead(
      createArticleSchema(
        props.article,
        props.slugPrefix,
        props.org,
        props.socials,
        props.lastModified
      )
    );
  }, []);
  
  return null;
};

PostArticle.propTypes = {
  org: PropTypes.object.isRequired,
  socials: PropTypes.array.isRequired,
  article: PropTypes.object.isRequired,
  slugPrefix: PropTypes.string.isRequired,
};


export default PostArticle;