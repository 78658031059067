/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/02/2020.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param data
 */
export const newsletterSignUp = data => {
  return async dispatch => {
    dispatch({
      type: "NEWSLETTER_SIGNUP",
    });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/newsletters.json",
        data,
        AxiosConfig.getConfig()
      )
      .then(response => {
        dispatch({
          type: "NEWSLETTER_SIGNUP_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: "NEWSLETTER_SIGNUP_REJECTED",
          payload: error
        });
      });
  };
};
