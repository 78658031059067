/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/10/2020.
 */

import React from "react";

import PropTypes from "prop-types";
import StoryRow from "../components/StoryRow";
import AboutParagraph from "./AboutParagraph";

const AboutParagraphBlock = (props) => {
  return (
    <div className="grid">
      <StoryRow title={props.block.title} size={8}>
        <AboutParagraph body={props.block.paragraphs} />
      </StoryRow>
      <StoryRow size={4}>
        {props.block.media ? (
          <React.Fragment>
            <img
              src={props.block.media.image.src}
              alt={props.block.media.caption}
              title={props.block.media.caption}
            />
            <span className="picture-desc">{props.block.media.caption}</span>
          </React.Fragment>
        ) : null}
      </StoryRow>
    </div>
  );
};

AboutParagraphBlock.propTypes = {
  block: PropTypes.object.isRequired,
};

export default AboutParagraphBlock;
