/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/02/2019.
 */

import React, { useEffect } from "react";

import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import classnames from "classnames";
import { SOCIALS } from "../../consts";
import MobileMenu from "./MobileMenu";
import AppNavigation from "./AppNavigation";
import CookiePopup from "../../components/CookiePopup";

const PageTemplate = (props) => {
  /**
   *
   */
  useEffect(() => {
    setTimeout(window.updateThemeScripts, 100);
  }, []);
  /*
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);*/

  /**
   *
   */
  const updateNavigation = (nextProps) => {
    const id = "section-" + window.location.pathname.substr(1);
    const el = document.getElementById(id);

    if (el) {
      setTimeout(() => {
        //

        window.jQuery("html, body").animate(
          {
            scrollTop: el.offsetTop + 20,
          },
          500
        );
      }, 250);
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    updateNavigation();
  }, [props.history.location.key]);

  return (
    <React.Fragment>
      <MobileMenu />
      <div className={classnames("wrapper-site", props.className)}>
        <section
          className="header-site header-sticky header-left layers-parallax layers-logo-"
          style={{ backgroundPosition: "50% 1557px" }}
        >
          <div className="container header-block">
            <div className="logo">
              <a
                href="https://thebeaverhead.com/"
                className="custom-logo-link"
                rel="home"
                itemProp="url"
              >
                <img
                  width="2000"
                  height="600"
                  src="/img/logo-tbh-red.svg"
                  className="custom-logo"
                  alt=""
                  itemProp="logo"
                  sizes="(max-width: 2000px) 100vw, 2000px"
                />
              </a>
              <div className="site-description">
                <h3 className="sitename sitetitle">
                  <a href="http://futr.themepioneers.nl/">
                    Experts in optimising business operations
                  </a>
                </h3>
                <p className="tagline" />
              </div>
            </div>

            <AppNavigation kind={'horizontal'}/>
          </div>
        </section>

        <section className="wrapper-content">{props.children}</section>

        <section id="footer" className="footer-site">
          <div className="container content clearfix">
            <div className="grid" />

            <div className="grid copyright">
              <div className="column span-6">
                <p className="site-text">
                  All pictures (unless otherwise specified) were taken by{" "}
                  <a
                    href="http://sebastianrzepiel.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sebastian Rzepiel
                  </a>
                  <br />
                  Experts in optimising business operations © The Beaverhead{" "}
                  {moment().format("YYYY")} |{" "}
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </p>
              </div>

              <div className="column span-3 ">
                <aside id="text-3" className="widget widget_text">
                  <h5 className="section-nav-title">Address</h5>
                  <div className="textwidget">
                    5 The Mall <br />
                    W5 2PJ, London <br />
                    United Kingdom <br />
                  </div>
                </aside>
              </div>

              <div className="column span-3 last">
                <aside id="text-2" className="widget widget_text">
                  <h5 className="section-nav-title">Social</h5>
                  <div className="textwidget">
                    Follow our social channels! <br /> <br />
                    {SOCIALS.map((i, idx) => {
                      return (
                        <a
                          key={"fsi-" + idx}
                          href={i.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className={classnames("fa", i.icon)} />
                        </a>
                      );
                    })}
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
      <CookiePopup/>
    </React.Fragment>
  );
};

export default withRouter(PageTemplate);
