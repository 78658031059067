const initialState = {

  fetchError: null,
  fetchLoading: false,
  fetchSuccess: false,
  hasMore: true,

};

export default function reducer(
  state = {
    collection: [],
    postState: {
      ...initialState
    },
  },
  action) {

  let newCollection = [];

  switch (action.type) {

    case "USERS_FETCH":
      return {
        ...state,
        postState: {
          ...initialState,
          fetchLoading: true,
        }
      };

    case "USERS_FETCH_REJECTED":
      return {
        ...state,

        postState: {
          ...initialState,
          hasMore: false,
          fetchError: action.payload
        }
      };

    case "USERS_FETCH_FULFILLED":

      newCollection = action.payload.map((item) => {
        return {
          model: item
        };
      });

      return {
        ...state,

        collection: state.collection.concat(newCollection),
        postState: {
          ...initialState,
          fetchSuccess: true,
        }
      };

    case "USERS_CLEANUP_COLLECTION":
      return {
        collection: [],

        postState: {
          ...initialState,
        },
      }

    default:
      return state;
  }

}