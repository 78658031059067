/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param data
 */
export const contactFormSubmit = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "CONTACTFORM_SUBMIT",
      //text: data.email
    });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/contact.json",
        data,
        AxiosConfig.getConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTFORM_SUBMIT_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTFORM_SUBMIT_REJECTED",
          payload: error,
        });
      });
  };
};
