/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2020.
 */

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import PageTemplate from "./components/PageTemplate";
import Organization from "./components/Schemas/Organization";

import SeoBlock from "./components/SeoBlock";
import { withRouter } from "react-router-dom";
import { newsletterSignUp } from "../actions/newsletter";
import LeadSignup from "./components/LeadSignup";

const PDFExtractorTool = (props) => {
  const signupEmailRef = useRef("");

  const { ORG, SOCIALS } = props.staticContent.model;

  /**
   *
   * @param url
   * @returns {(function(): void)|*}
   */
  const appendScript = (url, id) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.id = id;

    document.body.appendChild(script);

    return script;
  };

  useEffect(() => {
    const dataExtractorScript = appendScript(
      "https://extractor.thebeaverhead.com/demo/js/bundle.min.js",
      // "http://localhost:11181/demo/js/bundle.min.js",
      "data-etractor"
    );
    const calendlyScript = appendScript(
      "https://assets.calendly.com/assets/external/widget.js",
      "calendly"
    );

    return () => {
      document.body.removeChild(dataExtractorScript);
      document.body.removeChild(calendlyScript);
    };
  }, []);

  const onClickCTA = (e) => {
    e.preventDefault();

    props.history.push("/contact");
    return true;
  };

  return (
    <PageTemplate>
      <Organization socials={SOCIALS} org={ORG} />

      <SeoBlock
        title="Extract data from bank statements in seconds | The Beaverhead"
        description="This convenient data extraction tool will allow you to process pages and pages of bank statements in only seconds! "
        keywords={[
          "pdf",
          "bank statement",
          "standing orders",
          "direct debits",
          "extract",
        ]}
        image="/img/tools/bank_statement.png"
      />

      <div className="widget layers-content-widget content-vertical-massive bgdark home ">
        <div className="container clearfix">
          <div className="section-title clearfix medium text-center ">
            <h3 className="heading">
              Extract data from bank statements in seconds!
            </h3>
            <p className="excerpt">Only for Barclays* bank statement files.</p>
          </div>
        </div>
        {/*<div
          className="container "
          dangerouslySetInnerHTML={{ __html: HOME.desc.body }}
        />*/}
      </div>

      <div className="widget layers-content-widget content-vertical-massive">
        <div className="container list-grid">
          <div className="grid">
            <div className="layers-masonry-column span-12  first  column ">
              <div id="extractor-app-root"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive bgdark home ">
        <div className="container ">
          <div className="story portfolio">
            <div className="container list-grid">
              <div className="grid grid--extractor">
                <div className="layers-masonry-column span-2 column">
                  <p>
                    Select a PDF bank statement and drag it into the box above
                  </p>
                </div>
                <div className="layers-masonry-column span-1 column extractor-arrow">
                  <i className={"fa fa-arrow-right"} />
                  <i className={"fa fa-arrow-down"} />
                </div>
                <div className="layers-masonry-column span-2 column">
                  <p>Our algorithm will extract data from the file</p>
                </div>
                <div className="layers-masonry-column span-1 column extractor-arrow">
                  <i className={"fa fa-arrow-right"} />
                  <i className={"fa fa-arrow-down"} />
                </div>
                <div className="layers-masonry-column span-2 column">
                  <p>The data will be displayed in a simple table</p>
                </div>
                <div className="layers-masonry-column span-1 column extractor-arrow">
                  <i className={"fa fa-arrow-right"} />
                  <i className={"fa fa-arrow-down"} />
                </div>
                <div className="layers-masonry-column span-3 column">
                  <p>
                    You will also get totals and a summary of the Direct Debits
                    and Standing Orders
                  </p>
                </div>
              </div>

              <div className="grid grid--extractor">
                <div className="layers-masonry-column span-5 column">
                  <img src="/img/tools/bank_statement.png" />
                </div>
                <div className="layers-masonry-column span-2 column extractor-arrow">
                  <h3>
                    <i className={"fa fa-arrow-right"} />
                    <i className={"fa fa-arrow-down"} />
                  </h3>
                </div>
                <div className="layers-masonry-column span-5 column">
                  <img src="/img/tools/bank_statement_processed.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical-massive home ">
        <div className="container ">
          <div className="story">
            <div className="container list-grid">
              <div className="grid">
                <div className="layers-masonry-column span-12 column">
                  <p>
                    This convenient data extraction tool will allow you to
                    process pages and pages of bank statements in only seconds!
                    Simply upload your PDF, and the tool will extract the data,
                    organise it in a neat table, and even total your Direct
                    Debits and Standing Orders.
                  </p>
                  <p>
                    Your documents are safe -{" "}
                    <strong>we do not keep the uploaded files!</strong>
                    They are removed immediately after the data extraction is
                    finished. We do not store the extracted data. We store
                    metadata about the file itself (its size, mime type,
                    filename) for our internal statistical analysis.
                  </p>
                  <p>
                    This tool only works with Barclays<sup>*</sup> bank
                    statements (for now!).{" "}
                    <strong>
                      No other banks or document types are supported.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget content-vertical bgdark home ">
        <div className="container ">
          <div className="story portfolio">
            <div className="container list-grid">
              <div className="grid">
                <div className="layers-masonry-column span-12 column text-center">
                  <p>
                    Do you need to process statements for other banks? Would
                    your business benefit from more tools like this?
                  </p>
                  <p>
                    Find out how we can help you save time and money through
                    automation - pick a time in the calendar below and book a
                    meeting with us!
                  </p>

                  <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/piotr-pozniak/bank-statement-data-extraction"
                    style={{ minWidth: "320px", height: "650px" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget layers-content-widget ">
        <div className="container ">
          <p>
            <small>
              *Barclays is a registered trademark of Barclays PLC. We are not
              affiliated in any way with this entity.
            </small>
          </p>
        </div>{" "}
      </div>
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  staticContent: state.staticContent,
  newsletter: state.newsletter,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) => ({
  newsletterSignUp: async (data) => await dispatch(newsletterSignUp(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PDFExtractorTool));
