/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React from "react";
import hljs from "highlight.js";

export default class CodeBlock extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.ref = null;
  }

  componentDidMount() {
    this.highlightCode();
  }

  highlightCode() {
    if (this.ref) {
      hljs.highlightBlock(this.refs.code);
    }
  }

  setRef = ref => {
    this.ref = ref;
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    return (
      <pre>
        <code ref={this.setRef} className={this.props.language}>
          {this.props.value}
        </code>
      </pre>
    );
  }
}
