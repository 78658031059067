/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/10/2020.
 */

import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import StoryRow from "../components/StoryRow";
import AboutParagraph from "./AboutParagraph";

const AboutGrid3 = (props) => {
  return (
    <div className="grid">
      <StoryRow title={props.block.title} size={12} />
      {props.block.grid_3.map((i, idx) => {
        return (
          <div
            key={"ag3c-" + idx}
            className={classnames("layers-masonry-column column span-4 grid3")}>
            <div className="media image-top medium">
              <div className="media-body text-center">
                <i className={classnames(" fa", i.icon)} />
                <div>{i.title}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

AboutGrid3.propTypes = {
  block: PropTypes.object.isRequired,
};

export default AboutGrid3;
