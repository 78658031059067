/**
 *
 */

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

/**
 *
 */
export const getOrigin = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? window.location.origin
    : "https://thebeaverhead.com";
};

const SeoBlock = (props) => {
  /*****
   *
   * Title
   */

  const ogTitle = <meta property="og:title" content={props.title} />;

  /*****
   *
   * URL
   */

  const link = props.link ? (
    <link rel="canonical" href={props.link} />
  ) : (
    <link rel="canonical" href={window.location.href} />
  );

  const ampLink = props.ampLink ? (
    <link rel="amphtml" href={props.ampLink} />
  ) : null;

  const ogLink = props.link ? (
    <meta property="og:url" content={props.link} />
  ) : (
    <meta property="og:url" content={window.location.href} />
  );

  /*****
   *
   * Image
   */

  const icon = props.icon ? (
    <link
      rel="icon"
      type="image/png"
      href={getOrigin() + props.icon}
    />
  ) : (
    <link
      rel="icon"
      type="image/png"
      href={getOrigin() + "/img/icon/favicon-96x96.png"}
    />
  );

  const ogImage = props.image ? (
    <meta
      property="og:image"
      content={getOrigin() + props.image}
    />
  ) : (
    <meta
      property="og:image"
      content={getOrigin() + "/n/content/og_preview.png"}
    />
  );

  /*****
   *
   * Description
   */

  const description = (
    <meta name="description" content={props.description} />
  );

  const ogDescription = props.description ? (
    <meta property="og:description" content={props.description} />
  ) : null;

  /*****
   *
   * Author & keywords
   */

  const author = props.author ? (
    <meta name="author" content={props.author} />
  ) : (
    <meta name="author" content="hello@thebeaverhead.com" />
  );

  const keywords = (
    <meta name="keywords" content={props.keywords.join(", ")} />
  );

  /*****
   *
   * Other OG tags
   */

  const ogLocale = props.locale ? (
    <meta property="og:locale" content={props.locale} />
  ) : (
    <meta property="og:locale" content="en_UK" />
  );

  const ogType = props.type ? (
    <meta property="og:type" content={props.type} />
  ) : (
    <meta property="og:type" content="website" />
  );

  const ogSite = <meta property="og:site_name" content={props.site} />;

  const html = props.htmlClass ? (
    <html lang="en-UK" className={props.htmlClass} />
  ) : (
    <html lang="en-UK" />
  );

  return (
    <Helmet>
      <title>{props.title}</title>
      {link}
      {ampLink}
      {icon}
      {description}
      {author}
      {keywords}

      {ogTitle}
      {ogLink}
      {ogImage}
      {ogDescription}
      {ogLocale}
      {ogType}
      {ogSite}

      {html}
    </Helmet>
  );
};

SeoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  locale: PropTypes.string,
  type: PropTypes.string,
  site: PropTypes.string,
  htmlClass: PropTypes.string,
  ampLink: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

SeoBlock.defaultProps = {
  title: "Custom-made CRM, ERP, Project Managemet tools | The Beaverhead",
  description: "Put your company processes and procedures under control. Reduce risk and repetitive tasks.",
  site: "The Beaverhead Home Page",
  keywords: [
    "bespoke software",
    "custom-made crm",
    "business processes",
    "operational risk reduction",
    "project management tools",
    "spreadsheet to software",
    "minimum viable product",
    "software house",
    "software development company",
  ],
};


export default SeoBlock;