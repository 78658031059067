import React from "react";

class RestrictedBlock extends React.Component {


  /**
   *
   *
   * @returns {null|*}
   */
  render() {
    if (!this.props.isRestricted) {
      return null;
    }

    return (
      <div className="post__content col-xs-12 col-md-10">
        <div className="shadowed-content">
          <div className={"shadowed-content--shadow"} />
          <h4>Want to read more?</h4>
          <p>
            Please sign up or login.
            <br /> This will give you access to all extras and allow you to
            comment the posts.
          </p>
          <button
            type="button"
            className="btn default-button"
            disabled={false}
            onClick={this.props.onLogin}
          >
            Get access
          </button>
        </div>
      </div>
    );
  }
}

export default RestrictedBlock;
