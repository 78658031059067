/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/12/2019.
 */

import React from "react";
import ReactModalLogin from "react-modal-login";
import { facebookConfig, googleConfig } from "../consts/social-config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { login, register } from "../actions/user";
import { history } from "../configureStore";
import { clearErrors } from "../actions/app";

class LoginModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      modalVisible: false
    };
  }

  componentDidMount() {
    this.setState({ modalVisible: true });
  }

  onLoginForm = e => {
    this.onLogin(null, null);
  }
  /**
   *
   */
  onLogin = async (provider, response) => {
    const email = provider ? null : document.querySelector("#email").value;
    const password = provider
      ? null
      : document.querySelector("#password").value;

    let responseToken = null;

    if (provider === "facebook") {
      responseToken = response.authResponse.accessToken;
    } else if (provider === "google") {
      responseToken = response.id_token;
    }

    const data = {
      email: email,
      password: password,
      provider: provider,
      responseToken: responseToken
    };

    this.setState({ isLoading: true });
    await this.props.login(data);
    this.setState({ isLoading: false });

    if (this.props.user.loginSuccess) {
      history.goBack();
    }
  };

  /**
   *
   */
  onRegister = async () => {
    const nickname = document.querySelector("#login").value;
    const email = document.querySelector("#email").value;
    const password = document.querySelector("#password").value;

    const data = {
      nickname: nickname,
      email: email,
      password: password
    };

    this.setState({ isLoading: true });
    await this.props.register(data);
    this.setState({ isLoading: false });

    if (this.props.user.registerSuccess) {
      history.push("/");
    }
  };

  /**
   *
   * @param isLoading
   */
  setLoading = isLoading => () => {
    this.setState({ isLoading });
  };

  /**
   *
   * @param e
   */
  setSocialLoginError = (e, a) => {
    this.setState({ isLoading: false, socialError: e });
  };

  /**
   *
   * @param e
   */
  onTabChange = e => {
    console.log(e);
    this.props.clearErrors();
  };

  /**
   *
   */
  onClose = () => {
    this.props.clearErrors();
    window.history.back();
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.state.isLoading;

    const initialTab = this.props.match.url === "/login" ? "login" : "register";

    return (
      <ReactModalLogin
        visible={this.state.modalVisible}
        onCloseModal={this.onClose}
        startLoading={this.setLoading(true)}
        finishLoading={this.setLoading(false)}
        loading={isLoading}
        initialTab={initialTab}
        error={this.props.user.loginError || this.props.user.registerError}
        loginError={{
          containerClass: "RML-login-modal-error--login",
          label: "Login failed. Please try again."
        }}
        registerError={{
          label: "Registration failed. Please try again."
        }}
        tabs={{
          afterChange: this.onTabChange,
          loginLabel: "Sign in",
          registerLabel: "Sign up"
        }}
        form={{
          onLogin: this.onLoginForm,
          onRegister: this.onRegister,
          loginBtn: {
            label: "Sign in"
          },
          registerBtn: {
            label: "Sign up"
          },
          loginInputs: [
            {
              label: "Email",
              type: "email",
              id: "email",
              name: "email",
              placeholder: "Email"
            },
            {
              label: "Password",
              type: "password",
              id: "password",
              name: "password",
              placeholder: "Password"
            }
          ],
          registerInputs: [
            {
              label: "Name",
              type: "text",
              id: "login",
              name: "login",
              placeholder: "Your name"
            },
            {
              label: "Email",
              type: "email",
              id: "email",
              name: "email",
              placeholder: "Email"
            },
            {
              label: "Password",
              type: "password",
              id: "password",
              name: "password",
              placeholder: "Password"
            }
          ]
        }}
        separator={{
          label: "or"
        }}
        providers={{
          facebook: {
            config: facebookConfig,
            onLoginSuccess: this.onLogin,
            onLoginFail: this.setSocialLoginError,
            inactive: isLoading,
            label: "Continue with Facebook"
          },
          google: {
            config: googleConfig,
            onLoginSuccess: this.onLogin,
            onLoginFail: this.setSocialLoginError,
            inactive: isLoading,
            label: "Continue with Google"
          }
        }}
      />
    );
  }
}

const mapStateToProps = store => ({
  loginModal: store.loginModal,
  user: store.user
});

const mapDispatchToProps = dispatch => ({
  login: async data => await dispatch(login(data)),
  register: async data => await dispatch(register(data)),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginModal));
