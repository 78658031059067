const initialState = {
  error: null,
  loading: false,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  vote: false,
  voteSuccess: false,
  voteError: null,

  imageUpload: false,
  imageUploadSuccess: false,
  imageUploadError: null,

  changeBody: null,

  model: null
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "POST_FETCH":
      return {
        model: null,
        fetch: true,
        fetchSuccess: false,
        fetchError: null
      };

    case "POST_FETCH_FULFILLED":
      return {
        model: action.payload,
        fetch: false,
        fetchSuccess: true
      };

    case "POST_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchError: action.payload
      };

    case "POST_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null
      };

    case "POST_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload
      };

    case "POST_DELETE_FULFILLED":
      return {
        ...state,
        delete: false,
        deleteSuccess: true
      };

    case "POSTS_CLEANUP_COLLECTION":
      return {
        ...state,

        deleteSuccess: false
      };

    case "POST_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null
      };

    case "POST_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload
      };

    case "POST_EDIT_FULFILLED":
      return {
        model: action.payload,
        edit: false,
        editSuccess: true
      };

    case "POST_IMAGE_UPLOAD":
      return {
        ...state,
        imageUpload: true,
        imageUploadSuccess: false,
        imageUploadError: null
      };

    case "POST_IMAGE_UPLOAD_FULFILLED":
      return {
        ...state,
        imageUpload: false,
        imageUploadSuccess: true,
        changeBody: {
          text:
            action.changeData.front +
            "![" +
            action.payload.filename +
            "](" +
            action.payload.thumb_md_path +
            ")" +
            action.changeData.back,
          cursorPosition:
            action.changeData.cursorPosition +
            action.payload.filename.length +
            5 +
            action.payload.thumb_md_path.length
        }
      };

    case "POST_IMAGE_UPLOAD_REJECTED":
      return {
        ...state,
        imageUpload: false,
        imageUploadError: action.payload
      };

    case "POST_VOTE":
      return {
        ...state,

        postState: {
          ...initialState,
          voteLoading: true
        }
      };

    case "POST_VOTE_REJECTED":
      return {
        ...state,

        postState: {
          ...initialState,
          voteError: action.payload
        }
      };

    case "POST_VOTE_FULFILLED":
      return {
        model: action.payload,

        postState: {
          ...initialState,
          voteSuccess: true
        }
      };

    case "COMMENT_EDIT_FULFILLED":
    case "COMMENT_ADD_FULFILLED":
    case "COMMENT_DELETE_FULFILLED":
      return {
        ...state,
        model: action.payload
      };

    default:
      return state;

  }

}
