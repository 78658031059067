import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter, matchPath } from "react-router-dom";
import { history } from "./configureStore";
import LoginModal from "./modals/LoginModal";
import PostEditModal from "./modals/PostEditModal";

const ModalRoutesComponent = (props) => {
  const [modalClosePath, setModalClosePath] = useState(null);

  /**
   *
   */
  const updateModalClosePath = () => {
    const modalLinkIndex = props.modalLinks.findIndex((modalLink) => {
      const match = matchPath(props.location.pathname, {
        path: modalLink,
        exact: true,
        strict: false,
      });
      return match !== null;
    });

    if (modalLinkIndex >= 0) {
      setModalClosePath(Object.values(props.prevModalLocation[modalLinkIndex])[0]);
    }
  };

  useEffect(() => {
    updateModalClosePath();
  }, []);

  useEffect(() => {
    setModalClosePath(null);
  }, [props.location.pathname]);

  /**
   *
   */
  const onCloseAction = () => {
    if (modalClosePath) {
      history.push(modalClosePath);
    } else {
      history.goBack();
    }
  };

  return (
    <Switch>
      <Route exact path="/login" component={LoginModal} />
      <Route exact path="/signup" component={LoginModal} />
      <Route path="/edit-post/:slug/:static?" component={PostEditModal} />
    </Switch>
  );
};

export default withRouter(ModalRoutesComponent);
