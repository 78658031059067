/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/12/2019.
 */

import React from "react";
import {history} from '../configureStore';

class ModalTemplate extends React.Component {


  onClickClose = () => {
    history.goBack();
  }


  render() {
    return (
      <div className={"modal show"}>
        <div className="modal-content">

          <span className="close" onClick={this.onClickClose}>&times;</span>
          <div>
            {this.props.children}

          </div>
        </div>
      </div>
    );
  }
}

export default ModalTemplate;
