/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/12/2019.
 */

import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

const AboutParagraph = (props) => {
  const paragraphs =
    props.body && props.body.length
      ? props.body.map((i, idx) => {
          if (i instanceof Array && i.length) {
            const listObjects = i.map((j, jdx) => {
              return <li key={"abp-ul-" + jdx}>{j}</li>;
            });

            return <ul>{listObjects}</ul>;
          }

          return (
            <p key={"abp-p-" + idx} dangerouslySetInnerHTML={{ __html: i }}></p>
          );
        })
      : null;

  return paragraphs;
};

AboutParagraph.propTypes = {
  body: PropTypes.array,
};

export default AboutParagraph;
