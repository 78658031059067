/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/12/2019.
 */

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Wysiwyg from "../components/Wysiwyg";
import { updateCaretPositionAfterPhotoUpload } from "../helpers/tools";
import { clearErrors } from "../actions/app";
import { withRouter } from "react-router-dom";
import ModalTemplate from "./ModalTemplate";
import {
  fetchPost,
  updatePost,
  uploadPostImage,
  newPostSubmit,
} from "../actions/post";
import Indicator from "../components/Indicator";
import moment from "moment";

class PostEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.isStatic = this.props.match.params.static == "static";

    this.state = {
      is_published: false,
    };
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    const {
      match: {
        params: { slug },
      },
    } = this.props;

    const model = this.props.post.model;

    if (model === null && slug !== "new" && slug) {
      await this.props.fetchPost(slug);
      if (this.props.post.fetchSuccess) {
        this.handleUpdateForm();
      }
    } else {
      this.handleUpdateForm();
    }
  }

  /**
   *
   *
   */
  componentDidUpdate() {
    if (this.props.post.changeBody) {
      updateCaretPositionAfterPhotoUpload.call(
        this,
        "#newPostText",
        this.props.post.changeBody
      );
    }
  }

  /**
   *
   */
  handleUpdateForm = () => {
    if (!this.props.post.model) {
      return false;
    }
    const {
      title,
      video_url,
      publish_date,
      body,
      meta_title,
      meta_description,
      meta_keywords,
      tags,
      abstract,
      is_static,
      is_published,
      is_restricted,
      restricted_body,
    } = this.props.post.model;

    if (!this.isStatic) {
      document.querySelector("#newPostMetaTitle").value = meta_title || "";
      document.querySelector("#newPostMetaDescription").value =
        meta_description || "";
      document.querySelector("#newPostMetaKeywords").value =
        meta_keywords || "";
      document.querySelector("#newPostAbstract").value = abstract || "";
      document.querySelector("#newPostTags").value = tags
        ? tags.map((i) => i.label).join(", ")
        : "";
    }
    document.querySelector("#newPostTitle").value = title || "";
    document.querySelector("#newPostPublishDate").value = publish_date
      ? moment(publish_date * 1000).format("YYYY-MM-DD")
      : "";
    document.querySelector("#newPostText").value = body || "";

    document.querySelector("#newPostIsStatic").checked = is_static || false;
    /*document.querySelector("#newPostIsPublished").checked =
      is_published || false;*/

    this.setState({ is_published, is_restricted }, () => {
      if (is_restricted) {
        document.querySelector("#newPostRetrictedText").value =
          restricted_body || "";
      }
    });
  };

  /**
   *
   */
  onSubmit = async () => {
    let tags,
      abstract = "";

    const isNew = this.props.match.params.slug === "new";

    if (!this.isStatic) {
      tags = document.querySelector("#newPostTags").value.split(",");

      tags = tags.map((item) => {
        return item.trim();
      });

      tags = tags.filter((item) => item.length);

      abstract = document.querySelector("#newPostAbstract").value;
    }

    const data = {
      title: document.querySelector("#newPostTitle").value,

      publish_date: moment(
        document.querySelector("#newPostPublishDate").value
      ).unix(),
      body: document.querySelector("#newPostText").value,
      restricted_body: this.state.is_restricted
        ? document.querySelector("#newPostRetrictedText").value
        : "",
      meta_title: !this.isStatic
        ? document.querySelector("#newPostMetaTitle").value
        : "",
      meta_description: !this.isStatic
        ? document.querySelector("#newPostMetaDescription").value
        : "",
      meta_keywords: !this.isStatic
        ? document.querySelector("#newPostMetaKeywords").value
        : "",
      tags: tags,
      abstract: abstract,
      is_static: this.isStatic ? 1 : 0,
      is_published: this.state.is_published,
      is_restricted: this.state.is_restricted,
    };

    if (isNew) {
      await this.props.newPostSubmit(data);
    } else {
      await this.props.updatePost(this.props.post.model.slug, data, false);
    }

    if (this.props.post.editSuccess) {
      const prefix = this.isStatic ? "static" : "post";
      this.props.history.push("/" + prefix + "/" + this.props.post.model.slug);
    }
  };

  /**
   *
   *
   * @param field
   * @returns {function(...[*]=)}
   */
  onChangeFormCheckbox = (field) => (e) => {
    const data = {};

    data[field] = !this.state[field];

    this.setState(data);
  };

  render() {
    const user = this.props.user.model;
    const post = this.props.post;
    const hasPermission = user && ["admin", "editor"].indexOf(user.role) >= 0;

    const is_static = this.isStatic;

    const redirectToHome = !hasPermission ? <Redirect to={"/"} push /> : null;

    const isNew = this.props.match.params.slug === "new";
    const headerLabel = isNew ? "Add new" : "Edit";
    const controlsDisabled = post.edit || post.fetch || post.imageUpload;

    const loadingIndicator = post.fetch ? <Indicator size={24} /> : null;

    const pageTitle = (
      <div className="new-post__title col-sm-10 col-xs-12 col-sm-offset-1">
        <h2>
          {is_static ? headerLabel + " static page" : headerLabel + " post"}{" "}
          {loadingIndicator}
        </h2>
      </div>
    );

    const postTags = !is_static ? (
      <p className="">
        <label htmlFor="newPostMetaTitle">Tags (comma separated)</label>
        <span className="wpcf7-form-control-wrap ">
          <input
            className=""
            id="newPostTags"
            type="text"
            placeholder="Tags, comma separated"
            disabled={controlsDisabled}
          />
        </span>
      </p>
    ) : null;

    const postAbstract = !is_static ? (
      <p className="">
        <label htmlFor="newPostMetaTitle">Abstract</label>
        <span className="wpcf7-form-control-wrap ">
          <textarea
            id="newPostAbstract"
            className=""
            placeholder="Abstract"
            disabled={controlsDisabled}
          />
        </span>
      </p>
    ) : null;

    const postRestrictedBody =
      !is_static && this.state.is_restricted ? (
        <React.Fragment>
          <div className="new-post__post-text-label">
            <div className="wysiwyg-container">
              <span className="wysiwyg__markdown-info">
                USe Markdown to format the article.
              </span>
              <div className="clearfix" />
            </div>
            <div className="clearfix" />
          </div>

          <div className="new-post__text form-group">
            <label htmlFor="newPostRetrictedText">
              Article that will be displayed for not authorized users
            </label>
            <textarea
              id="newPostRetrictedText"
              className="form-control"
              placeholder="Article body that will be displayed for users that are not authorized"
            />
          </div>
        </React.Fragment>
      ) : null;

    const postMetaTitle = !is_static ? (
      <p className="">
        <label htmlFor="newPostMetaTitle">Meta title</label>
        <span className="wpcf7-form-control-wrap ">
          <input
            id="newPostMetaTitle"
            className=""
            placeholder="Meta Title"
            type="text"
            disabled={controlsDisabled}
          />
        </span>
      </p>
    ) : null;

    const postMetaDescription = !is_static ? (
      <p>
        <label htmlFor="newPostMetaTitle">Meta Description</label>
        <span className="wpcf7-form-control-wrap ">
          <textarea
            id="newPostMetaDescription"
            className=""
            placeholder="Meta Description"
            disabled={controlsDisabled}
          />
        </span>
      </p>
    ) : null;

    const postMetaKeywords = !is_static ? (
      <p>
        <label htmlFor="newPostMetaTitle">Meta keywords</label>
        <span className="wpcf7-form-control-wrap ">
          <input
            id="newPostMetaKeywords"
            className="form-control new-post__input"
            placeholder="Meta Keywords"
            type="text"
            disabled={controlsDisabled}
          />
        </span>
      </p>
    ) : null;

    const errorWrap = post.editError ? (
      <span className="new-post__error alert">
        Some error has occurred. Please try again.
      </span>
    ) : null;

    const submitIndicator =
      post.edit || post.imageUpload ? <Indicator size={18} /> : true;

    const postSubmitAndErrorWrap = (
      <div className="new-post__submit-and-error-wrap">
        <button
          type="button"
          className=""
          disabled={controlsDisabled}
          onClick={this.onSubmit}
        >
          Submit
        </button>
        {errorWrap}
        {submitIndicator}
        <div className="clearfix" />
      </div>
    );

    const staticCheckbox = (
      <div className="new-post__static-checkbox col-xs-12">
        <input
          className="default-checkbox"
          id="newPostIsStatic"
          type="checkbox"
          defaultChecked={this.isStatic}
          disabled
        />
        <span>Static</span>
      </div>
    );
    const postForm = (
      <div className="new-post__title col-sm-10 col-xs-12 col-sm-offset-1">
        <div
          role="form"
          className="wpcf7"
          id="wpcf7-f255-o1"
          lang="en-US"
          dir="ltr"
        >
          <div className="screen-reader-response" />
          <form
            action="/"
            method="post"
            className="wpcf7-form"
            noValidate="novalidate"
          >
            <p>
              <label htmlFor="newPostTitle">Title</label>
              <span className="wpcf7-form-control-wrap ">
                <input
                  className=""
                  id="newPostTitle"
                  type="text"
                  placeholder="Title"
                  disabled={controlsDisabled}
                />
              </span>
            </p>

            <p>
              <label htmlFor="newPostPublishDate">
                Publish Date DD/MM/YYYY
              </label>
              <span className="wpcf7-form-control-wrap ">
                <input
                  className=""
                  id="newPostPublishDate"
                  type="date"
                  placeholder="Publish Date DD/MM/YYYY"
                  disabled={controlsDisabled}
                />
              </span>
            </p>

            {postTags}
            {postAbstract}

            {postMetaTitle}
            {postMetaDescription}
            {postMetaKeywords}
            {staticCheckbox}

            <div className="new-post__static-checkbox col-xs-12">
              <input
                className="default-checkbox"
                type="checkbox"
                id="newPostIsPublished"
                checked={this.state.is_published}
                onChange={this.onChangeFormCheckbox("is_published")}
              />
              <label htmlFor="newPostIsPublished">Published</label>
            </div>

            <div className="new-post__static-checkbox col-xs-12">
              <input
                className="default-checkbox"
                type="checkbox"
                id="newPostIsRestricted"
                checked={this.state.is_restricted}
                onChange={this.onChangeFormCheckbox("is_restricted")}
              />
              <label htmlFor="newPostIsRestricted">
                Is Restricted to Authorized users
              </label>
            </div>

            <div className="new-post__post-text-label">
              <Wysiwyg
                contentId="newPostText"
                addPhotoBtnId="newPostPhoto"
                uploadImage={this.props.uploadPostImage}
                resource={"post"}
                loading={post.imageUpload}
                disabled={controlsDisabled}
              />
              <div className="clearfix" />
            </div>

            <div className="new-post__text form-group">
              <textarea
                id="newPostText"
                className="form-control"
                placeholder="Your article. Image added first is a cover image"
              />
            </div>

            {postRestrictedBody}

            {postSubmitAndErrorWrap}
          </form>
        </div>
      </div>
    );

    return (
      <ModalTemplate>
        <div className="new-post container">
          <div className="row">
            {redirectToHome}
            {pageTitle}
            {postForm}
          </div>
        </div>
      </ModalTemplate>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user,
  post: store.post,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrors()),
  newPostSubmit: async (data) => await dispatch(newPostSubmit(data)),
  updatePost: async (slug, data, isStatic) =>
    await dispatch(updatePost(slug, data, isStatic)),
  uploadPostImage: (file, resource, changeData, uuid) =>
    dispatch(uploadPostImage(file, resource, changeData, uuid)),
  fetchPost: async (slug) => await dispatch(fetchPost(slug)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PostEditModal));
