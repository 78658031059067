import React from "react";
import { Link } from "react-router-dom";

import Timestamp from "../../../components/Timestamp";
import classnames from "classnames";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../../../components/CodeBlock";
import LinkBlock from "../../../components/LinkBlock";
import ImageBlock from "../../../components/ImageBlock";
import AxiosConfig from "../../../AxiosConfig";
import CommentForm from "./CommentForm";

class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      voteLoading: false,
      delete: false,
      editing: false
    };
  }

  /**
   *
   */
  onDeleteComment = () => {
    const comment = this.props.comment;

    if (!this.state.deleting) {
      if (window.confirm("Do you really want to delete that comment?")) {
        this.setState({ delete: true }, async () => {
          await this.props.deleteComment(comment.id);

          this.setState({ delete: false });
        });
      }
    }
  };

  onEdit = start => () => {
    this.setState({ editing: start });
  };

  render() {
    const comment = this.props.comment;
    const user = this.props.user;
    const isOwner =
      user && (user.role === "admin" || user.slug === comment.user.slug);

    if (this.state.editing) {
      return (
        <div className="post__newComment col-xs-12">
          <CommentForm
            defaultValue={comment.body}
            onFinishEdit={this.onEdit(false)}
            commentId={comment.id}
          />
        </div>
      );
    }

    const canVote =
      user &&
      user.slug !== comment.user.slug &&
      !comment.user_vote &&
      !this.state.voteLoading;

    const editControls =
      isOwner && !this.state.voteLoading ? (
        <div className="comment__edit-controls-actions">
          <i className="material-icons" onClick={this.onEdit(true)}>
            mode_edit
          </i>
          <i className="material-icons" onClick={this.onDeleteComment}>
            delete
          </i>
        </div>
      ) : null;

    const voteControls = canVote ? (
      <div className="comment__edit-controls-voting">
        <i
          className="material-icons voting-plus"
          onClick={() => this.props.commentActions.voteComment(comment.id, 1)}
        >
          add
        </i>
        <i
          className="material-icons voting-minus"
          onClick={() => this.props.commentActions.voteComment(comment.id, -1)}
        >
          remove
        </i>
      </div>
    ) : null;

    const editControlsWrap = !this.state.editing ? (
      <div className="comment__edit-controls">
        {/*{voteControls}*/}
        {editControls}
      </div>
    ) : null;

    return (
      <div className="post__comment col-xs-12">
        <div
          className={classnames("post__comment-tile row", {
            deleting: this.state.delete
          })}
        >
          <div
            className="post__comment-image"
            style={{
              backgroundImage:
                "url(" +
                AxiosConfig.getEndpointAddress() +
                "/avatars/" +
                comment.user.avatar +
                ")"
            }}
          />

          <div className="post__comment-title">
            <Link to={"/profile/" + comment.user.slug}>
              {comment.user.name}
            </Link>

            <p>
              <Timestamp time={comment.created} commentDate />
            </p>
          </div>

          {editControlsWrap}

          <div className="post__comment-content">
            <ReactMarkdown
              source={comment.body}
              escapeHtml={true}
              className="markdown-content"
              renderers={{
                code: CodeBlock,
                link: LinkBlock,
                image: ImageBlock
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Comment;
