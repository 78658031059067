
/**
 * Created by piotr.pozniak@thebeaverhead.com on 29/01/2020.
 */

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Wysiwyg from "./../../../components/Wysiwyg";

import PropTypes from "prop-types";
import { clearErrors } from "../../../actions/app";
import {
  addComment,
  updateComment,
  uploadCommentImage
} from "../../../actions/comment";
import { updateCaretPositionAfterPhotoUpload } from "../../../tools";
import classnames from "classnames";
import Indicator from "../../../components/Indicator";

class CommentForm extends React.Component {
  static defaultProps = {
    defaultValue: "",
    onFinishEdit: null,
    commentId: null
  };

  /**
   *
   */
  onNewCommentSubmit = async () => {
    const commentSelector = "#comment-" + (this.props.commentId || "newComment");
    const data = {
      parent_slug: this.props.post.model.slug,
      body: document.querySelector(commentSelector).value
    };

    if (this.props.commentId) {
      await this.props.updateComment(this.props.commentId, data);
    } else {
      await this.props.addComment(data);
    }

    if (
      (this.props.comment.addSuccess || this.props.comment.editSuccess) &&
      document.querySelector(commentSelector)
    ) {
      document.querySelector(commentSelector).value = "";

      if (this.props.onFinishEdit) {
        this.props.onFinishEdit();
      } else {
        document
          .getElementsByClassName("comments-container")[0]
          .children[0].scrollIntoView();
      }
    }
  };

  /**
   *
   * @param image
   * @param resource
   * @param changeData
   * @param uuid
   * @returns {Promise<null|*>}
   */
  onUploadImage = async (image, resource, changeData, id) => {
    const commentSelector = "#comment-" + this.props.commentId;

    await this.props.uploadCommentImage(image, resource, changeData, id);

    if (this.props.comment.changeBody) {
      updateCaretPositionAfterPhotoUpload.call(
        this,
        commentSelector,
        this.props.comment.changeBody
      );
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const commentState = this.props.comment;
    const postState = this.props.post;
    const postModel = this.props.post.model;
    const commentSelector = "comment-" + (this.props.commentId || 'newComment' );

    const newCommentTextLabel =
      postModel && !postState.edit && !postState.fetch ? (
        <div className="post__newComment-label">
          <Wysiwyg
            contentId={commentSelector}
            addPhotoBtnId={commentSelector + "Photo"}
            uploadImage={this.onUploadImage}
            resource={"comment"}
            loading={commentState.add || commentState.imageUpload}
            markdownPlaceholder="Use markdown to format your comment."
            uuid={this.props.commentId
            }
          />
        </div>
      ) : null;

    const submitIndicator =
      commentState.add || commentState.edit || commentState.imageUpload ? (
        <React.Fragment>
          <Indicator size={24} /> Submitting...
        </React.Fragment>
      ) : null;

    return (
      <React.Fragment>
        {newCommentTextLabel}
        <textarea
          id={commentSelector}
          placeholder="Write your comment here..."
          defaultValue={this.props.defaultValue}
        />
        <div className="post__newComment-error-and-submit">
          <span
            className={classnames("post__newComment-error alert ", {
              hidden: !(
                commentState.addError ||
                commentState.editError ||
                commentState.imageUploadError
              )
            })}
          >
            Some error has occured, please try again.
          </span>
          {submitIndicator}
          <button
            type="button"
            className="btn default-button"
            onClick={this.onNewCommentSubmit}
            disabled={
              commentState.add || commentState.edit || commentState.imageUpload
            }
          >
            Submit
          </button>
          <div className="clearfix" />
        </div>
      </React.Fragment>
    );
  }
}

CommentForm.propTypes = {
  defaultValue: PropTypes.string,
  onFinishEdit: PropTypes.func,
  commentId: PropTypes.string
};

const mapStateToProps = store => ({
  user: store.user,
  comment: store.comment,
  post: store.post
});

const mapDispatchToProps = dispatch => ({
  clearErrors: () => dispatch(clearErrors()),

  addComment: async data => await dispatch(addComment(data)),
  updateComment: async (id, data) => await dispatch(updateComment(id, data)),
  uploadCommentImage: async (file, resource, changeData, uuid) =>
    await dispatch(uploadCommentImage(file, resource, changeData, uuid))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CommentForm));
