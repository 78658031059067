import React, { useEffect, useState } from "react";
import classnames from "classnames";
import ReactCSSTransitionReplace from "react-css-transition-replace";

const HeaderTransitions = (props) => {
  const [currentState, setCurrentState] = useState({
    currentItemIndex: 0,
    hasUnderline: true,
  });

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  /**
   *
   */
  const switchHeader = () => {
    const headersCount = props.headers.length;

    setCurrentState({
      currentItemIndex: (currentState.currentItemIndex + 1) % headersCount,
      hasUnderline: false,
    });
  };
/*

  useEffect(() => {
    if (currentState.hasUnderline) {
      setTimeout(switchHeader, 5250);
    } else {
      setTimeout(() => {
      setCurrentState({
        currentItemIndex: currentState.currentItemIndex,
        hasUnderline: true,
      });
      }, 150);
    }
  }, [currentState.hasUnderline]);
*/

  useEffect(() => {
    //const timer = setInterval(switchHeader, 5250);

    new Promise(() => {})
      .then(() => sleep(100))
      .then(() => {
        setCurrentState({
          currentItemIndex: currentState.currentItemIndex,
          hasUnderline: true,
        });
      })
      .then(() => sleep(5250))
      .then(() => switchHeader());

    //setTimeout(switchHeader, 5250);
    //return () => clearInterval(timer);
  }, []);

  const currentItem = props.headers[currentState.currentItemIndex];

  const content = (
    <div className={classnames("section-title large header-transition")}>
      <h1
        className={classnames("heading", { show: currentState.hasUnderline })}
        dangerouslySetInnerHTML={{__html: currentItem.header}}

      />
      <h2>{currentItem.subtitle}</h2>
      <h2>{currentItem.subtitle2}</h2>
    </div>
  );

  return (
    <ReactCSSTransitionReplace
      transitionName="cross-fade"
      transitionEnterTimeout={1000}
      transitionLeaveTimeout={1000}
    >
      {content}
    </ReactCSSTransitionReplace>
  );
};

export default HeaderTransitions;
